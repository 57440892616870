import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import "../componentCss/sidebar.css";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  getStaffListAction,
  getFormListWithPermissionAction,
  updateFormsPermissionAction,
  getPatientListAction,
  assignPatientAction,
  getAssignedPatientListAction,
  updateStaffStatusAction
} from "../../Action/admin.action";
import Swal from "sweetalert2";

import ReactToPrint, { useReactToPrint } from "react-to-print";
import { MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { BsPersonAdd } from "react-icons/bs";
import { IoPerson, IoPrintSharp } from "react-icons/io5";
import { decryptData } from './decrypt';
import { BsToggleOn, BsToggleOff } from "react-icons/bs";

const UserPermissions = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isAccessAllPatient, setIsAccessAllPatient] = useState(0);
  const [staffList, setStaffList] = useState([]);
  const [patientFilterData, setPatientFilterData] = useState({ 'name': '', 'location': '' });
  const [patientList, setPatientList] = useState([]);
  const [patientListByFilter, setPatientListByFilter] = useState([]);
  const [formList, setFormList] = useState([]);
  const [isModelOpen, setIsOpen] = useState(false);
  const [staffId, setStaffId] = useState(0);
  const [isLaoding, setisLaoding] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isModelView, setIsModelView] = useState(false);
  const [isModelAssignPatient, setIsModelAssignPatient] = useState(false);
  const [viewData, setViewData] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [details, setDetails] = useState({ fromDate: '', toDate: '', status: 'all', name: '' });
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const allDataRef = useRef();
  const singleRowRef = useRef();

  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
  };

  // const handleCheckboxChangeForAllPatient = (event) => {
  //   const value = event.target.value;
  //   setIsAccessAllPatient(isAccessAllPatient == 1 ? 0 : 1)
  // };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  };
  useEffect(() => {
    getStaffListAPI();
  }, []);

  useEffect(() => {
    setSelectedRow(null);
  }, [currentPage]);


  const handleOpenEditView = (id) => {
    setStaffId(id);
    getFormListAPI(id);
  };

  const handleViewClose = () => setIsModelView(false);
  const handleOpenView = (data) => {
    setViewData(data);
    setIsModelView(true);
    handleOpenEditView(data.id);
  };


  let getFormListAPI = async (userId) => {
    let res = await getFormListWithPermissionAction({ userId: userId });
    if (res.success) {
      console.log('decryptData(res.data)',decryptData(res.data))
      setFormList(decryptData(res.data));
    }
  };

  let getStaffListAPI = async () => {

    if (details.fromDate && !details.toDate) {
      toast.error('Please select to date');
      return
    }

    if (!details.fromDate && details.toDate) {
      toast.error('Please select from date');
      return
    }

    let res = await getStaffListAction(details);
    setIsLoading(false);
    if (res.success) {
      let originalText = []
      if (res.data != "") {
        originalText = decryptData(res.data)
      }
      setStaffList(originalText);
    }
  };

  let getStaffListAPIForFilter = async () => {
    window.location.reload();
    // setDetails({fromDate : '', toDate:'', status : 'all', name : ''})
    // let res = await getStaffListAction(details);

    // if (res.success) {
    //   setStaffList(res.data);
    // }
  };


  let handleChange = (permissionType, formId) => {
    setFormList((prevForms) => {
      return prevForms.map((form) => {
        if (form.id === formId) {
          if (permissionType == "isAccess") {
            return {
              ...form,
              isAccess: !form.isAccess,
            };
          } else if (permissionType == "isEdit") {
            return {
              ...form,
              isEdit: !form.isEdit,
            };
          } else if (permissionType == "isDelete") {
            return {
              ...form,
              isDelete: !form.isDelete,
            };
          }
        }
        return form;
      });
    });
  };

  
  let submitPermissionForm = async (e) => {
    e.preventDefault();
    setisLaoding(1);
    let res = await updateFormsPermissionAction({
      userId: staffId,
      formsPermission: formList,
    });
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        setisLaoding(0);
        setIsOpen(false);
      }, 2000);
    } else {
      toast.error(res.msg);
    }
  };

  
  const searchByNameHandler = (e) => {
    const { name, value } = e.target;
    setDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const [searchTerm, setSearchTerm] = useState('');

  const filteredFormList = formList.filter((data) =>
    (data.name && data.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (data.departmentName && data.departmentName.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  
  
  
  

  const handleClose = () => setIsOpen(false);
  const handleOpen = (id) => {
    setStaffId(id);
    getFormListAPI(id);
    setIsOpen(true);
  };

 

  const columns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return index + 1 + currentPage * rowsPerPage;
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      width: "15%"
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "30%"
    },
    {
        name: 'Roles',
        cell: (row) => (
          <>
            {row.roles && row.roles.map((role, index) => (
              <>
                {index+1}. {role} <br />
              </>
            ))}
  
          </>
        ),
        sortable: true,
        width: '30%',
      },
    {
      name: "Action",
      selector: (row) => (
        <>
       
          
          <Link onClick={() => handleOpenView(row)} title="View Details ">
            <FaEye fill='#434544' size={20} />

          </Link>
          &nbsp;
          &nbsp;
          <Link onClick={() => handleOpen(row.id)} title="Role">
            <IoPerson fill='#434544' size={20} />
          </Link>
           </>
      ),
      sortable: true,
      width: "20%"

    },
  ];

  


  return (
    <>
      {/* <Dashboardheader />
      <Toaster /> */}
      {/* <div className="wrapper">
        <Sidebar /> */}
      <div id="content">
        <Container fluid className="p-4">
          <Row>
            <Col lg={12} md={12} sm={12} className="">
              <div className="innerhead mb-4">
                <h5 className="headtitle">User Permission</h5>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12} className="mt-3">
              <div style={{ float: "right" }}>
                
              </div>
              <div className="">
                <div className="row">
                  <div className="col-md-2">
                    <label for="name">Search By:</label>&nbsp;
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      onChange={searchByNameHandler}
                    />
                  </div>

                  
                  <div className="col-md-2">
                    <label for="name">From:</label>&nbsp;
                    <input
                      type="date"
                      id="name"
                      name="fromDate"
                      className="form-control"
                      onChange={searchByNameHandler}
                    />
                  </div>

                  <div className="col-md-2">
                    <label for="name">To:</label>&nbsp;
                    <input
                      type="date"
                      id="name"
                      name="toDate"
                      className="form-control"
                      onChange={searchByNameHandler}
                    />
                  </div>

                  <div className="col-md-4">
                    <button
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                      onClick={getStaffListAPIForFilter}
                    >
                      Reset
                    </button>
                    &nbsp;
                    <button
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                      onClick={getStaffListAPI}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <br />
             
              <div className="maintable table-responsive">
                <h5 className="">Users List </h5>
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{ width: "40px" }}
                      src="assets/images/loading.gif"
                    />
                  </div>
                ) : (
                  <DataTable
                    columns={columns}
                    data={staffList}
                    pagination
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: 'Row per page:',
                      rangeSeparatorText: 'out of',
                    }}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                  />

                )}
              </div>
              {/*------------------------------- Section For Showing All Data For Print--------------------------------------------------- */}

             
              {/*------------------------------- Section For Showing Single Data For Print--------------------------------------------------- */}

          
            </Col>
          </Row>


         
          {/* Assign form to staff */}
          <Modal
            show={isModelView}
            onHide={() => handleViewClose(false)}
            dialogClassName="modal-90w modal-right modal-lg"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <b>Permission Details</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>

           
              <ul className="list-group" style={{ border: '1px solid #dcddf99e', padding: "10px", marginTop: "0px" }}>

                <Row className="mb-4">
                  <div className="col-md-3">
                    <Form.Label>
                      <b> Form Name </b>
                    </Form.Label>{" "}
                    <br />
                  </div>

                  <div className="col-md-3">
                    <Form.Label>
                      <b> Department Name </b>
                    </Form.Label>{" "}
                    <br />
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      <b>Access</b>
                    </Form.Label>
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      <b> Edit </b>
                    </Form.Label>
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      {" "}
                      <b> Delete </b>
                    </Form.Label>
                  </div>
                </Row>

                {formList.length > 0 ? (
                  formList.some(data => data.isAccess === 1 || data.isEdit === 1 || data.isDelete === 1) ? (
                    formList.map((data) => (
                      <Row className="mb-4" key={data.id} style={{ display: data.isAccess === 1 || data.isEdit === 1 || data.isDelete === 1 ? 'flex' : 'none' }}>
                        <div className="col-md-3">{data.name}</div>
                        <div className="col-md-3">{data.departmentName
}</div>

                        <div className="col-md-2">
                          <div>
                            {data.isAccess === 1 ? <p style={{ color: "green" }}>Yes</p> : <p style={{ color: "red" }}>No</p>}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div>
                            {data.isEdit === 1 ? <p style={{ color: "green" }}>Yes</p> : <p style={{ color: "red" }}>No</p>}
                          </div>
                        </div>

                        <div className="col-md-2">
                          <div>
                            {data.isDelete === 1 ? <p style={{ color: "green" }}>Yes</p> : <p style={{ color: "red" }}>No</p>}
                          </div>
                        </div>
                      </Row>
                    ))
                  ) : (
                    <Row className="mb-4">
                      <div className="col-md-12" style={{ textAlign: "center" }}>
                        No Data Found
                      </div>
                    </Row>
                  )
                ) : ""}
              </ul>

            </Modal.Body>
          </Modal>

          {/* Assign patient to staff */}
       
        </Container>
      </div>


      <Modal
            show={isModelOpen}
            onHide={() => handleClose(false)}
            dialogClassName="modal-90w modal-right"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <b>Permissions</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <Form>
                <Row className="mb-4">
                  <div className="col-md-3">
                    <Form.Label>
                      <b> Form Name </b>
                    </Form.Label>{" "}
                    <br />
                  </div>

                  <div className="col-md-3">
                    <Form.Label>
                      <b> Department Name </b>
                    </Form.Label>{" "}
                    <br />
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      <b>Access</b>
                    </Form.Label>
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      <b> Edit </b>
                    </Form.Label>
                  </div>

                  <div className="col-md-2">
                    <Form.Label>
                      {" "}
                      <b> Delete </b>
                    </Form.Label>
                  </div>
                </Row>
                <hr />
                <Row className="mb-4">
                  <div className="col-md-12">
                    <Form.Control
                      type="text"
                      placeholder="Search by form name or department name"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </Row>
                {filteredFormList.length > 0
                  ? filteredFormList.map((data) => (
                    <>
                      <Row className="mb-4">
                        <div className="col-md-3">{data.name}</div>
                        <div className="col-md-3">{data.departmentName
}</div>


                        <div className="col-md-2">
                          <input
                            type="checkbox"
                            name="isAccess"
                            onChange={() =>
                              handleChange("isAccess", data.id)
                            }
                            checked={data.isAccess == 1 ? "checked" : ""}
                          />
                        </div>

                        <div className="col-md-2">
                          <input
                            type="checkbox"
                            name="isEdit"
                            onChange={() => handleChange("isEdit", data.id)}
                            checked={data.isEdit == 1 ? "checked" : ""}
                          />
                        </div>

                        <div className="col-md-2">
                          <input
                            type="checkbox"
                            name="isDelete"
                            onChange={() =>
                              handleChange("isDelete", data.id)
                            }
                            checked={data.isDelete == 1 ? "checked" : ""}
                          />
                        </div>
                      </Row>
                      <hr />
                    </>
                  ))
                  : <p style={{ textAlign: "center" }}>No Data Found</p>}

                <Row className="mb-4">
                  <Form.Group as={Col}>
                    {isLaoding ? (
                      <>
                        <button
                          disabled
                          className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                        >
                          Loading{" "}
                          <img
                            height="20"
                            width="20px"
                            src="assets/images/loading.gif"
                          />
                        </button>
                      </>
                    ) : (
                      <button
                        type="submit"
                        onClick={submitPermissionForm}
                        className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                      >
                        Submit
                      </button>
                    )}
                  </Form.Group>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>


      {/* </div> */}
    </>
  );
};

export default UserPermissions;
