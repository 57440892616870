import { combineReducers } from '@reduxjs/toolkit';
import userSlices from './userSlices';
import notificationSlice from './notificationSlice';
import formDataSlice from './formDataSlice';
import reportsSlice from './reportsSlice';

const rootReducer = combineReducers({
    user: userSlices,
    notification: notificationSlice,
    formdata: formDataSlice,
    reports: reportsSlice
    
});

export default rootReducer;