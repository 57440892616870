import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Cookies from "js-cookie";
import toast from 'react-hot-toast';

const AddendumModal = ({ showModal, handleClose, handleSubmitInvalidAddendum, currentRawData, status, formFields }) => {
  const [addendumReason, setAddendumReason] = useState('');
  const [formId, setFormId] = useState(null);

  let loginData = !Cookies.get("loginSuccessNeurulaHealth")
    ? []
    : JSON.parse(Cookies.get("loginSuccessNeurulaHealth"));

  const onInputChange = (e) => {
    setAddendumReason(e.target.value);
  };

  const onSubmit = () => {
    if (!addendumReason) {
      toast.error('Please enter reason')
      return;
    }
    let obj = {
      reason: addendumReason,
      staffId: loginData.id,
      formId: currentRawData.formId,
      recordId: currentRawData.recordId,
      status: status
    }
    handleSubmitInvalidAddendum(obj);
    setAddendumReason(''); // Clear the input after submit
    setFormId(''); // Clear the input after submit

  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{status == 2 ? 'Invalid' : status == 3 ? 'Addendum' : 'Review/Acknowledgement'} Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {status == 3 ?
            <ul className="list-group">
              {formFields.map(data => (
                <li className="list-group-item">
                  <strong>{data.fieldName}:</strong> {currentRawData[data.id]}
                </li>
              ))}
            </ul>
          :''}
          <Form>
            <Form.Group controlId="reportTitle" className='mt-2'>
              <Form.Label><strong>{status == 2 ? 'Invalid' : status == 3 ? 'Addendum' : 'Review/Acknowledgement'} Reason</strong></Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={addendumReason}
                onChange={onInputChange}
              />

            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddendumModal;
