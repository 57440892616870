import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../componentCss/webform.css";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import {
  getDepartmentFormsAction,
} from "../../Action/admin.action";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import config from "../../config/config";
import DataTable from "react-data-table-component";
import toast, { Toaster } from 'react-hot-toast';
import { decryptData } from "./decrypt";

const StaffDepartmentFormsList = () => {
  let { formTypeId } = useParams();
  const [formList, setFormList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchBy, setSearchBy] = useState('');

  const handlePageChange = page => {
    setCurrentPage(page - 1);
  };

  useEffect(() => {
    getFormListAPI();
  }, []);

  useEffect(() => {
    getFormListAPI();
  }, [formTypeId]);  

  let getFormListAPI = async (searchBy = '') => {
    let MRNNumber = localStorage.getItem('mrnNumber');
    let res = await getDepartmentFormsAction({'searchBy' : searchBy, 'formTypeId' : formTypeId, 'MRNNumber' : MRNNumber});
    if (res.success) {
      let data = decryptData(res.data)    
      setFormList(data);
    }else{
      setFormList([]);
    }
  };

  const inputHandler = (e) => {
    const { value } = e.target;
    setSearchBy(value)
  };

  let resetFilter = async () => {
    setSearchBy('')
    getFormListAPI();
  };

  let filterSubmit = async () => {
    getFormListAPI(searchBy);
  };

  const columns = [
    {
      name: '#',
      cell: (row, index, column, id) => {
        return (index + 1) + (currentPage * 10);
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Form Name",
      selector: (row) => `${row.name}`,
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => `${row.departmentName}`,
      sortable: true,
    },
    {
      name: "Form Number",
      selector: (row) => `${row.formNumber}`,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {/* <Link to={`${config.baseUrl}form-submission/${row.id}/0`}>
            <Button
              variant="secondary-btn3"
              className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
            >
              Fill Form
            </Button>
          </Link> &nbsp; */}

          <Link to={`${config.baseUrl}viewFormReportList/${row.id}/${row.name}/isPatiendSearch`}>
            <Button
              variant="secondary-btn3"
              className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
            >
              View Records
            </Button>
          </Link>
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">Forms List</h5>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} className="">

                <div className="row">
                  <div className="col-md-4">
                    <label for="name">Search By:</label>&nbsp;
                    <input
                      type="text"
                      id="name"
                      name="searchBy"
                      className="form-control"
                      placeholder="Enter form name"
                      value={searchBy}
                      onChange={inputHandler}
                    />
                  </div>

                  <div className="col-md-4">
                    <button
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                      onClick={resetFilter}
                    >
                      Reset
                    </button>
                    &nbsp;
                    <button
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4"
                      onClick={filterSubmit}
                    >
                      submit
                    </button>
                  </div>
                </div>

                {formList.length > 0 ?
                  <div className="maintable table-responsive">
                    <DataTable columns={columns} data={formList} pagination onChangePage={handlePageChange} />
                  </div>
                  :
                  <p className='text-center mt-5'>
                    <img src='assets/images/no-image.png' />
                  </p>
                }
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default StaffDepartmentFormsList;
