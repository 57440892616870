import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import '../componentCss/webform.css';
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import { Formik } from "formik";
import '../componentCss/sidebar.css';
import { getCPTMasterListAction, addCPTMasterAction, deleteCPTMasterDataAction, importExcelFileAction } from '../../Action/admin.action';
// import { ToastContainer, toast } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { CPTMasterSchema } from "../../validations/masterDataSchema";
import Swal from "sweetalert2";

const CPTMaster = () => {
    const [cptMasterList, setCPTMasterList] = useState([])
    const [formData, setForm] = useState({ code: '', shortDescription: '', longDescription: '', fullDescription: '' })
    const [isLaoding, setisLaoding] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [isAddModel, setIsAddModel] = useState(false);
    const [isEdit, setIsEdit] = useState(0);
    const [file, setFile] = useState(null);
    const [isLoadingImportBtn, setIsLoadingImportBtn] = useState(false)

    const handleCloseAddModel = () => {
        setIsAddModel(false);
        setForm({})
    }

    const handleFileChange = e => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            toast.error('Please select excel file');
            return;
        }
        setIsLoadingImportBtn(true)
        let res = await importExcelFileAction({ 'dbTable': 'cptMaster', 'excelFile': file });
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } else {
            setIsLoadingImportBtn(false)
            toast.error(res.msg);
        }
    };

    const handleOpenModel = (type, data = null) => { // Type 0-add, 1-edit
        setIsEdit(type)
        if (type == 1) {
            setForm(data)
        }
        setIsAddModel(true);
    }

    useEffect(() => {
        getCPTMasterListAPI();
    }, []);

    let getCPTMasterListAPI = async () => {
        let res = await getCPTMasterListAction();
        setIsLoading(false)
        if (res.success) {
            setCPTMasterList(res.data);
        }
    }

    let handleSubmit = async (values) => {
        setisLaoding(1)
        values.submitType = isEdit // 0-Add, 1-Edit
        let res = await addCPTMasterAction(values);
        setisLaoding(0)
        if (res.success) {
            handleCloseAddModel();
            toast.success(res.msg);
            getCPTMasterListAPI()
        } else {
            toast.error(res.msg);
        }
    }

    const deleteForm = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this record.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes delete it",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await deleteCPTMasterDataAction({ 'id': id });
                if (res.success) {
                    getCPTMasterListAPI()
                    toast.success(res.msg);
                } else {
                    toast.error(res.msg);
                }
            }
        });
    }

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1
        },
        {
            name: 'Code',
            selector: row => `${row.code}`,
            sortable: true,
        },
        {
            name: 'Short Description',
            selector: row => row.shortDescription,
            sortable: true,
        },
        {
            name: 'Long Description',
            selector: row => row.longDescription,
            sortable: true,
        },
        {
            name: 'Full Description',
            selector: row => row.fullDescription,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <Button onClick={() => deleteForm(row.id)} variant="secondary-btn3" className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Delete</Button>

                    <Button onClick={() => handleOpenModel(1, row)} variant="secondary-btn3" className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Edit</Button>
                </>
            ),
            sortable: true,
        }
    ];

    return (
        <>
            <Dashboardheader />
            {/* <ToastContainer /> */}
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>CPT Master List</h5>
                                </div>
                            </Col>
                        </Row>

                        <div>
                            <label>Import Excel Sheet</label> <br />
                            <input type="file" onChange={handleFileChange} />
                            {isLoadingImportBtn ?
                                <>
                                    <button disabled className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Loading <img height="20" width="20px" src="assets/images/loading.gif" /></button>
                                </>
                                :
                                <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6' onClick={handleUpload}>Upload</button>
                            }
                        </div>

                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-3">
                                <div style={{ float: 'right' }}>
                                    <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6' onClick={() => handleOpenModel(0, '')}>Add+</button>
                                </div>

                                <div className='maintable table-responsive'>
                                    {isLoading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: '40px' }} src='assets/images/loading.gif' />
                                        </div>
                                        :
                                        <DataTable
                                            columns={columns}
                                            data={cptMasterList}
                                            pagination
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>

                        <Modal show={isAddModel} onHide={handleCloseAddModel} size="md" backdrop="static">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {isEdit == 1 ? 'Edit' : 'Add'}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pb-1">
                                <Row className="border-bottom border-grey pb-2 pt-3">
                                    <Col lg={1} className=""></Col>
                                    <Col lg={10} className="">
                                        <div className='formDiv'>
                                            <Formik
                                                // enableReinitialize={true}
                                                initialValues={formData}
                                                validationSchema={CPTMasterSchema}
                                                onSubmit={(values) => {
                                                    handleSubmit(values);
                                                }}
                                            >
                                                {({
                                                    errors,
                                                    handleSubmit,
                                                    handleBlur,
                                                    handleChange,
                                                    touched,
                                                    values,
                                                }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <Form.Group as={Col}>

                                                            <Row className="mb-2">
                                                                <Form.Label>Code</Form.Label>
                                                                <input className='form-control' type="text" name='code' onChange={handleChange} placeholder="Please enter code" onBlur={handleBlur} value={values.code} />

                                                                {errors.code && touched.code && (
                                                                    <div className="text-danger">
                                                                        {errors.code}
                                                                    </div>
                                                                )}
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <Form.Label>Short Description</Form.Label>
                                                                <input className='form-control' type="text" name='shortDescription' onChange={handleChange} placeholder="Please enter short description" onBlur={handleBlur} value={values.shortDescription} />

                                                                {errors.shortDescription && touched.shortDescription && (
                                                                    <div className="text-danger">
                                                                        {errors.shortDescription}
                                                                    </div>
                                                                )}
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <Form.Label>Long Description</Form.Label>
                                                                <input className='form-control' type="text" name='longDescription' onChange={handleChange} placeholder="Please enter long description" onBlur={handleBlur} value={values.longDescription} />

                                                                {errors.longDescription && touched.longDescription && (
                                                                    <div className="text-danger">
                                                                        {errors.longDescription}
                                                                    </div>
                                                                )}
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <Form.Label>Full Description</Form.Label>
                                                                <input className='form-control' type="text" name='fullDescription' onChange={handleChange} placeholder="Please enter full description" onBlur={handleBlur} value={values.fullDescription} />

                                                                {errors.fullDescription && touched.fullDescription && (
                                                                    <div className="text-danger">
                                                                        {errors.fullDescription}
                                                                    </div>
                                                                )}
                                                            </Row>

                                                            <Row className="mb-2">
                                                                {isLaoding ?
                                                                    <>
                                                                        <button disabled className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Loading <img height="20" width="20px" src="assets/images/loading.gif" /></button>
                                                                    </>
                                                                    :
                                                                    <button type='submit' className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>
                                                                        {isEdit == 1 ? 'Update' : 'Submit'}
                                                                    </button>
                                                                }
                                                            </Row>
                                                        </Form.Group>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Col>
                                    <Col lg={1} className=""></Col>
                                </Row>
                            </Modal.Body>
                        </Modal>

                    </Container>
                </div>
            </div >
        </>
    )
}

export default CPTMaster;
