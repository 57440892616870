import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from "react-bootstrap";
import '../componentCss/webform.css';
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
// import { ToastContainer, toast } from 'react-toastify';
import toast from 'react-hot-toast';

import 'react-toastify/dist/ReactToastify.css';
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from '../../config/config';
import { Link } from "react-router-dom";
import { profileSchema } from "../../validations/staffSchema";
import { updateStaffAction, getStaffDetailsAction } from '../../Action/admin.action';
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux';
import { getStaffDetails } from '../../redux/slices/userSlices';
let loginData = (!Cookies.get('loginSuccessNeurulaHealth')) ? [] : JSON.parse(Cookies.get('loginSuccessNeurulaHealth'));

const Profile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const getStaffData = useSelector((state) => state.user?.getStaffDetailsData)
    const [staffDetails, setStaffDetails] = useState({ firstName: '', lastName: '', email: '', mobileNumber: '', address: '', gender: 0 })
    const [isLaoding, setisLaoding] = useState(0)

    useEffect(() => {
        if (getStaffData && getStaffData.data) {
            setStaffDetails(getStaffData.data);
        } else {
            getStaffDetailsAPI();
        }
    }, [getStaffData]);

    const getStaffDetailsAPI = async () => {
        const res = await dispatch(getStaffDetails({ id: loginData?.id }));
        if (res.payload?.success) {
            setStaffDetails(res.payload.data);
        }
    };

    let handleSubmit = async (values) => {
        setisLaoding(1)
        values.id = staffDetails?.id;
        let res = await updateStaffAction(values);
        if (res.success) {
            setisLaoding(0)
            toast.success(res.msg);
        } else {
            setisLaoding(0)
            toast.error(res.msg);
        }
    }

    return (
        <>
            {/* <Dashboardheader /> */}
            {/* <div className="wrapper"> */}
                {/* <Sidebar /> */}
                {/* <div id="content"> */}
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Profile</h5>
                                </div>
                                <Link to={`${config.baseUrl}dashboard`} >
                                    <span style={{ float: 'right' }}>Back <BsArrowLeftCircleFill /></span>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className='formDiv'>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={staffDetails}
                                        validationSchema={profileSchema}
                                        onSubmit={(values) => {
                                            handleSubmit(values);
                                        }}
                                    >
                                        {({
                                            errors,
                                            handleSubmit,
                                            handleBlur,
                                            handleChange,
                                            touched,
                                            values,
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <Row className="mb-4">
                                                    <Form.Group as={Col}>
                                                        <Form.Label>First name</Form.Label>
                                                        <input className='form-control' type="text" name='firstName' onChange={handleChange} placeholder="Please enter first name" onBlur={handleBlur} value={values.firstName ? values.firstName : ''} />

                                                        {errors.firstName && touched.firstName && (
                                                            <div className="text-danger">
                                                                {errors.firstName}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group as={Col}>
                                                        <Form.Label>Last name</Form.Label>
                                                        <input className='form-control' type="text" name='lastName' onChange={handleChange} value={values.lastName} placeholder="Please enter last name" />

                                                        {errors.lastName && touched.lastName && (
                                                            <div className="text-danger">
                                                                {errors.lastName}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-4">

                                                    <Form.Group as={Col}>
                                                        <Form.Label>Mobile Number</Form.Label>
                                                        <input className='form-control' value={values.mobileNumber} type="text" name='mobileNumber' onChange={handleChange} placeholder="Ex. 91584758" onKeyPress={(event) => {
                                                            if (
                                                                !/^\d*[]?\d{0,1}$/.test(event.key)
                                                            ) {
                                                                event.preventDefault();
                                                            }
                                                        }} />

                                                        {errors.mobileNumber && touched.mobileNumber && (
                                                            <div className="text-danger">
                                                                {errors.mobileNumber}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group as={Col}>
                                                        <Form.Label>Address</Form.Label>
                                                        <input className='form-control' type="text" name='address' onChange={handleChange} value={values.address} placeholder="Please enter address" />

                                                        {errors.address && touched.address && (
                                                            <div className="text-danger">
                                                                {errors.address}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                </Row>

                                                <Row className="mb-4">
                                                
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Gender</Form.Label>
                                                        <select name='gender' value={values.gender} className='form-control' onChange={handleChange}>
                                                            <option value={''}>Select gender</option>
                                                            <option value={1}>Male</option>
                                                            <option value={2}>Female</option>
                                                            <option value={3}>Other</option>
                                                        </select>

                                                        {errors.gender && touched.gender && (
                                                            <div className="text-danger">
                                                                {errors.gender}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group as={Col}></Form.Group>
                                                </Row>

                                                <Row className="mb-4">
                                                    <Form.Group as={Col}>
                                                        {isLaoding ?
                                                            <>
                                                                <button disabled className='btn-sm btn btn-secondary-btn3'>Loading <img height="20" width="20px" src="assets/images/loading.gif" /></button>
                                                            </>
                                                            :
                                                            <button type='submit' className='btn-sm btn btn-secondary-btn3'>Update</button>
                                                        }
                                                    </Form.Group>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                {/* </div> */}
            {/* </div > */}

        </>
    )
}

export default Profile;
