import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../componentCss/webform.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import AssignedFormSidebar from "./assignedFormSidebar";
import config from '../../config/config';
import "../componentCss/sidebar.css";
import {
  getFormDetailsAction,
  insertFormDataAction,
  getStaffListAction,
  getFormDataListAction
} from "../../Action/admin.action";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import propertise from "../../config/properties";
import { decryptData } from './decrypt';

const CopyTemplate = () => {
  let { formId, recordId, name } = useParams();
  const navigate = useNavigate();
  const [formDetails, setFormDetails] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [subFormValues, setSubFormValues] = useState([]);
  const [subFormDetails, setSubFormDetails] = useState([]);
  const [formPreviewValues, setFormPreviewValues] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [subFormId, setSubFormId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [staff, setStaff] = useState([]);
  const [formList, setFormData] = useState({});
  useEffect(() => {
    getFormDetailsAPI();
    getStaffDetails();
    getFormDataListAPI();
  }, []);

  let getFormDetailsAPI = async () => {
    let res = await getFormDetailsAction({ id: formId });
    if (res.success) {
      setFormDetails(decryptData(res.data));
    }
  };
  let getStaffDetails = async () => {
    let res = await getStaffListAction();
    if (res.success) {
      setStaff(decryptData(res.data));
    }
  };
  let getFormDataListAPI = async () => {
    let res = await getFormDataListAction({ id: formId, recordId: recordId });
    if (res.success) {
      let data = decryptData(res.data?.records)
      console.log('ppppp', data[0]);
      setFormData(data[0]);
    }
  };
  const handleChange = async (e, item) => {
    const { name, value, type, checked, id } = e.target;
    let newValue = "";
    let newValue1 = "";
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = e.target.files[0];
    } else if (name == "assignTo") {
      const [id, ...firstNameParts] = e.target.value.split("_");
      const firstName = firstNameParts.join("_");
      newValue = id;
      newValue1 = firstName;
    } else {
      newValue = value;
    }

    if (type == "radio") {
      if (
        item.dropdownFormId > 0 &&
        (value.trim() == "Yes" ||
          value.trim() == "YES" ||
          value.trim() == "yes")
      ) {
        setSubFormId(item.dropdownFormId);
        let res = await getFormDetailsAction({ id: item.dropdownFormId });
        if (res.success) {
          setSubFormDetails(res.data);
        }
      } else {
        setSubFormDetails([]);
      }
    }
    setFormData({ ...formList, [name]: newValue });
    setFormPreviewValues({
      ...formPreviewValues,
      [id]: newValue1 !== "" ? newValue1 : newValue,
    });
  };

  const handleChangeForSubForm = async (e) => {
    const { name, value, type, checked, id } = e.target;
    let newValue = "";
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = e.target.files[0];
    } else {
      newValue = value;
    }
    setSubFormValues({ ...subFormValues, [name]: newValue });
    setFormPreviewValues({ ...formPreviewValues, [id]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    formList.formId = formId;
    formList.referenceFormId = 0;
    formList.submitType = 1
    formList.isEdit = 1;
    subFormValues.formId = subFormId;

    let res = await insertFormDataAction({
      formValues: formList,
      subFormValues: subFormValues,
    });

    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setIsLoading(false)
      toast.error(res.msg);
    }
  };

  const formPreview = (e) => {
    e.preventDefault();
    setShowPreview(true);
  };

  const handleCancel = () => {
    setShowPreview(false);
  };

  function FormPreview({ formData, onCancel, onSubmit }) {
    const [imageUrls, setImageUrls] = useState({});
    useEffect(() => {
      const newImageUrls = {};
      Object.entries(formData).forEach(([key, value]) => {
        if (value instanceof File) {
          newImageUrls[key] = URL.createObjectURL(value);
          return () => URL.revokeObjectURL(newImageUrls[key]);
        }
      });

      setImageUrls(newImageUrls);
    }, [formData]);

    const getDisplayValue = (key, value) => {
      if (value instanceof File) {
        return imageUrls[key] || "";
      }
      return value;
    };

    return (
      <div className="form-preview-container">
        <ul className="list-group">
          {formDetails.map((data) => (
            formData[data.id] &&
            <li key={data.id} className="list-group-item">
              <strong>{data.fieldName}:</strong>{" "}
              {(formData[data.id] instanceof File) ? (
                <div>
                  <img
                    src={getDisplayValue(data.id, formData[data.id])}
                    alt="Preview"
                    style={{ maxWidth: "200px", height: "200px" }}
                  />
                </div>
              ) : (
                getDisplayValue(data.id, formData[data.id])
              )}
            </li>
          ))}
        </ul>
        <div className="button-container">
          <button className="btn btn-secondary mt-3 me-2" onClick={onCancel}>
            Cancel
          </button>
          <button disabled={isLoading == true ? true : false} className="btn btn-primary mt-3" onClick={onSubmit}>
            {isLoading == true ? 'Loading...' : 'Submit'}
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <div id="content">
          <Container fluid className="p-4">

            <Row>
              <Col lg={3} md={3} sm={3} className=""><AssignedFormSidebar /></Col>
              <Col lg={9} md={9} sm={9} className="">
                <h5 className="headtitle">
                  Edit {formDetails.length > 0 ? formDetails[0].formName : ""}
                </h5>
                <Link to={`${config.baseUrl}viewRecord/${formId}/${recordId}/${name}`}>
                  <span style={{ float: 'right', marginTop: '-15px' }}>Back</span>
                </Link>
                <div className="formDiv">
                  {!showPreview ? (
                    <>
                      <Form>
                        <Row className="mb-4">
                          {formDetails.length > 0
                            ? formDetails.map((item) => (
                              <div className="col-md-6 mt-4">
                                <Form.Group
                                  as={Col}
                                  controlId="formGridEmail"
                                >
                                  <Form.Label style={{ textTransform: 'capitalize' }}>
                                    {item.fieldtype != "btn"
                                      ? item.fieldName
                                      : ""}
                                  </Form.Label>{" "}
                                  <br />
                                  {item.fieldtype == "select" ? (
                                    <select
                                      className="form-control"
                                      id={item.fieldName}
                                      name={item.id}
                                      onChange={handleChange}
                                    >
                                      <option value={0}>
                                        Select Department
                                      </option>
                                      {item.dropdownValue != null
                                        ? JSON.parse(item.dropdownValue).map(
                                          (itemDropdown) => (
                                            <>
                                              <option
                                                value={
                                                  itemDropdown.fieldValue
                                                }
                                              >
                                                {itemDropdown.fieldValue}
                                              </option>
                                            </>
                                          )
                                        )
                                        : ""}
                                    </select>
                                  ) : item.fieldtype == "radio" ||
                                    item.fieldtype == "checkbox" ? (
                                    item.radioCheckboxValue &&
                                    JSON.parse(item.radioCheckboxValue).map(
                                      (radioData) => (
                                        <>
                                          <input
                                            onChange={(e) =>
                                              handleChange(e, item)
                                            }
                                            value={radioData}
                                            type={item.fieldtype}
                                            checked={
                                              Array.isArray(formList[item.id])
                                                ? formList[item.id].includes(
                                                  radioData
                                                )
                                                : formList[item.id] ===
                                                radioData
                                            }
                                            name={item.id}
                                            id={item.fieldName}
                                          />{" "}
                                          {radioData} <br />
                                        </>
                                      )
                                    )
                                  ) : item.fieldtype == "btn" ? (
                                    ""
                                  ) : item.fieldtype == "file" ? (
                                    <img src={item.fieldValue} style={{ height: '100px', width: '100px' }} alt="" />
                                  ) :
                                    item.id == propertise.PatientUniqueId || item.id == propertise.PatientMrnNumber
                                      ?
                                      <Form.Control
                                        onChange={handleChange}
                                        required={
                                          item.isMandatory ? true : false
                                        }
                                        type={item.fieldtype}
                                        readOnly
                                        name={item.id}
                                        placeholder={`Enter ${item.fieldName}`}
                                        id={item.fieldName}
                                        value={formList[item.id]}
                                      />
                                      :
                                      <Form.Control
                                        onChange={handleChange}
                                        required={
                                          item.isMandatory ? true : false
                                        }
                                        type={item.fieldtype}
                                        name={item.id}
                                        placeholder={`Enter ${item.fieldName}`}
                                        id={item.fieldName}
                                        value={formList[item.id]}
                                      />
                                  }
                                </Form.Group>
                              </div>
                            ))
                            : ""}
                        
                        </Row>
                        {subFormDetails.length > 0 ? (
                          <div
                            style={{
                              border: "1px solid grey ",
                              padding: "15px",
                            }}
                          >
                            <Row className="mb-4">
                              {subFormDetails.length > 0
                                ? subFormDetails.map((item) => (
                                  <div className="col-md-6 mt-4">
                                    <Form.Group
                                      as={Col}
                                      controlId="formGridEmail"
                                    >
                                      <Form.Label>
                                        {item.fieldtype != "btn"
                                          ? item.fieldName
                                          : ""}
                                      </Form.Label>{" "}
                                      <br />
                                      {item.fieldtype == "select" ? (
                                        <select
                                          className="form-control"
                                          id={item.fieldName}
                                          name={item.id}
                                          onChange={handleChangeForSubForm}
                                        >
                                          <option value={0}>
                                            Select Department
                                          </option>
                                          {item.dropdownValue != null
                                            ? JSON.parse(
                                              item.dropdownValue
                                            ).map((itemDropdown) => (
                                              <>
                                                <option
                                                  value={
                                                    itemDropdown.fieldValue
                                                  }
                                                >
                                                  {itemDropdown.fieldValue}
                                                </option>
                                              </>
                                            ))
                                            : ""}
                                        </select>
                                      ) : item.fieldtype == "radio" ||
                                        item.fieldtype == "checkbox" ? (
                                        JSON.parse(
                                          item.radioCheckboxValue
                                        ).map((radioData) => (
                                          <>
                                            <input
                                              onChange={(e) =>
                                                handleChangeForSubForm(
                                                  e,
                                                  item
                                                )
                                              }
                                              value={radioData}
                                              type={item.fieldtype}
                                              name={item.id}
                                              id={item.fieldName}
                                            />{" "}
                                            {radioData} <br />
                                          </>
                                        ))
                                      ) : item.fieldtype == "btn" ? (
                                        ""
                                      ) : (
                                        <Form.Control
                                          onChange={handleChangeForSubForm}
                                          required={
                                            item.isMandatory ? true : false
                                          }
                                          type={item.fieldtype}
                                          name={item.id}
                                          placeholder={`Enter ${item.fieldName}`}
                                          id={item.fieldName}
                                          value={formList[item.id]}
                                        />
                                      )}
                                    </Form.Group>
                                  </div>
                                ))
                                : ""}
                            </Row>
                          </div>
                        ) : (
                          ""
                        )}

                        <br />
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={formPreview}
                          type="submit"
                        >
                          Preview
                        </button>
                      </Form>
                    </>
                  ) : (
                    <FormPreview
                      formData={formList}
                      onCancel={handleCancel}
                      onSubmit={handleSubmit}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default CopyTemplate;
