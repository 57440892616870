import React from 'react';
import Header from './directives/Header';
import { Container, Row, Col, Button, Form, Nav, Navbar, NavDropdown, Offcanvas, Accordion, Card } from 'react-bootstrap';
import "../component/componentCss/home.css";
import { GoArrowRight } from "react-icons/go";

const Home = () => {
    return (
        <>

            <Header />


            {/*---------------- Banner Section Start --------------- */}

            <section className='banner mb-5'>
                <Container fluid>
                    <Row className='align-items-center'>
                        <Col lg={6} md={6}>
                            <div className='bnrtext'>
                                <h1 className='mb-3'>Empowering care, one click at a time</h1>
                                <h5 className='mb-3'>Where Innovation Meets Compassionate Care</h5>
                                <Button variant="secondary-btn3" className='px-4 py-3 mt-3'>Schedule a demo</Button>
                            </div>
                        </Col>
                        <Col lg={6} md={6} className='bnrImgbox'>
                            <img src="assets/images/laptopImg.png" alt="laptopImg" className='laptopImg' />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/*---------------- Banner Section Exit --------------- */}



            {/*------------- Health Section Start -------------- */}


            <section className='health_sec py-5'>
                <div className=''>
                    <img src="assets/images/backshape1.png" alt="backshape1" className='backshape1' />
                </div>
                <Container>
                    <Row>
                        <Col lg={6} md={6} xs={12} className=''>
                            <div className='customhead'>
                                <h2>Neurula Health</h2>
                                <p>We're reshaping healthcare with innovation. Our vision is to create a seamless, personalized experience that empowers both providers and patients. Join us in transforming the future of care.</p>
                            </div>
                            {/* <div className="health_accordion">
                                <Accordion className=''>
                                    <Accordion.Item eventKey="0" className=''>
                                        <Accordion.Header className='py-3'>We're redefining healthcare with innovative solutions</Accordion.Header>
                                        <Accordion.Body className='pt-0'>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className=''>
                                        <Accordion.Header className='py-3'>Integrating cutting-edge technology for a seamless experience</Accordion.Header>
                                        <Accordion.Body className='pt-0'>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" className=''>
                                        <Accordion.Header className='py-3'>Balancing efficiency with a compassionate approach to care</Accordion.Header>
                                        <Accordion.Body className='pt-0'>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" className=''>
                                        <Accordion.Header className='py-3'>Creating a personalized and enriching healthcare ecosystem</Accordion.Header>
                                        <Accordion.Body className='pt-0'>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div> */}
                            <div className='gen_question pt-lg-5 pt-md-5'>
                                <ul>
                                    <li className='active'>
                                        <a href="#">
                                            <div className='addblock'>
                                                <div>
                                                    <img src="assets/icons/add.png" alt="add" className='plus_icon me-3' />
                                                </div>
                                                <div>
                                                    <span>We're redefining healthcare with innovative solutions</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className='addblock'>
                                                <div>
                                                    <img src="assets/icons/add.png" alt="add" className='plus_icon me-3' />
                                                </div>
                                                <div>
                                                    <span>Integrating cutting-edge technology for a seamless experience</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <div className='addblock'>
                                                <div>
                                                    <img src="assets/icons/add.png" alt="add" className='plus_icon me-3' />
                                                </div>
                                                <div>
                                                    <span>Balancing efficiency with a compassionate approach to care</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='mb-2'>
                                        <a href="#">
                                            <div className='addblock'>
                                                <div>
                                                    <img src="assets/icons/add.png" alt="add" className='plus_icon me-3' />
                                                </div>
                                                <div>
                                                    <span>Clients Related</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </Col>

                        <Col lg={6} md={6} xs={12} className=''>
                            <img src="assets/images/healthImg.png" alt="healthImg" className='healthImg' />
                        </Col>
                    </Row>
                </Container>
            </section>


            {/*------------- Health Section Exit -------------- */}



            {/*------------- Escape Section Start -------------- */}


            <section className='escape_sec py-5'>
                <div className=''>
                    <img src="assets/images/backshape2.png" alt="backshape2" className='backshape2' />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} md={12} xs={12} className='mx-auto text-center mb-5'>
                            <div className='customhead'>
                                <h2 className='mb-3'>Escape the pitfalls of outdated systems</h2>
                                <h5>Streamlined access, intuitive charting, robust security, and empowered insights for efficient healthcare management.</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} md={6} xs={12} className=''>
                            <div className='prescription'>
                                <ul>
                                    <li className='active'>
                                        <a href="#">
                                            E-prescriptions
                                            <GoArrowRight className="goarrow" />
                                        </a>
                                    </li>
                                    <li className=''>
                                        <a href="#">
                                            Role-Specific Access
                                            <GoArrowRight className="goarrow" />
                                        </a>
                                    </li>
                                    <li className=''>
                                        <a href="#">
                                            Intuitive Charting
                                            <GoArrowRight className="goarrow" />
                                        </a>
                                    </li>
                                    <li className=''>
                                        <a href="#">
                                            Notifications
                                            <GoArrowRight className="goarrow" />
                                        </a>
                                    </li>
                                    <li className=''>
                                        <a href="#">
                                            Medical Management
                                            <GoArrowRight className="goarrow" />
                                        </a>
                                    </li>

                                </ul>
                                <Button variant="secondary-btn3" className='px-4 py-3 '>View All Services</Button>

                            </div>
                        </Col>

                        <Col lg={9} md={6} xs={12} >
                            <div className='e-prescriptions_box'>
                                <Row>
                                    <Col lg={6} md={6} xs={12} className='d-flex align-items-center'>
                                        <div className='e-prescriptions_text'>
                                            <h3>E-prescriptions</h3>
                                            <p>Neurula Health introduces a groundbreaking E-Prescription feature, redefining the prescription process with efficiency and precision.</p>
                                            <p>With a user-friendly interface, prescribers can easily input medication details, dosage instructions, and pertinent patient information. </p>
                                            <div className='mt-4'>
                                                <a href="#" className='godashboard'>
                                                    Go to Dashboard <span><GoArrowRight className="goarrow" /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} xs={12} className='e-prescriptions-Img'>
                                        <img src="assets/images/prescription_back.png" alt="prescription_back" className='prescription_back' />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container >
            </section >



            {/*------------- Escape Section Exit -------------- */}



            {/*------------- Interactive Section Start -------------- */}


            <section className='interractive_sec pt-5 pb-3'>
                <div className=''>
                    <img src="assets/images/backshape2.png" alt="backshape2" className='backshape2' />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} md={12} xs={12} className='mx-auto text-center mb-5'>
                            <div className='customhead'>
                                <h2 className='mb-3'>Interactive Reporting Hub</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Overviewicon.png" alt="Overviewicon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>Overview Dashboard</h4>
                                    <p>A centralized dashboard offering a snapshot of key metrics. Quick access to essential statistics.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Medicalicon.png" alt="Medicalicon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>Patient Insights</h4>
                                    <p>All Patients, Non-Admitted, Current Active, Discharged. Graphical representation of patients.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Dialysisicon.png" alt="Dialysisicon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>Dialysis Reporting </h4>
                                    <p>Sessions - Month, Patient, Date, Staff, Billed/Unbilled. Visual breakdown of dialysis sessions.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Homeicon.png" alt="Homeicon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>Homecare Analytics </h4>
                                    <p>Month, Patient, Date, Level, Staff, Billed/Unbilled. Graphical representation of homecare service</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Chiropractoricon.png" alt="Chiropractoricon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>Rehabilitation Sessions</h4>
                                    <p>Visual representation of rehabilitation sessions for effective planning</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Financialicon.png" alt="Financialicon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>Financial Overview</h4>
                                    <p>Detailed financial reports for effective revenue management. Billing & financial performance metrics</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Kpicon.png" alt="Kpicon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>KPI Performance </h4>
                                    <p>Track and evaluate key performance indicators crucial for healthcare management.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Registrationicon.png" alt="Registrationicon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>Real-time Monitoring </h4>
                                    <p>Access logs with detailed information on user interactions. Real-time logs for staff login activity.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={6} xs={12} className='mt-5 text-center'>
                            <Button variant="secondary-btn3" className='px-5 py-3 '>View All </Button>
                        </Col>
                    </Row>
                </Container >
            </section >


            {/*------------- Interactive Section Exit -------------- */}



            {/*------------- Blog Section Start -------------- */}


            <section className='blog_sec py-5'>
                <div className=''>
                    <img src="assets/images/backshape2.png" alt="backshape2" className='backshape2' />
                </div>
                <Container>
                    <Row>
                        <Col lg={12} md={12} xs={12} className='mx-auto text-center mb-5'>
                            <div className='customhead'>
                                <h2 className='mb-3'>Latest News & Blogs</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={6} xs={12} className='mb-4'>
                            <Card className='blogcard border-0'>
                                <Card.Img variant="top" src="assets/images/blogimg1.png" alt="blogimg1" className='blogImg' />
                                <Card.Body className='p-0'>
                                    <div className='bloginr'>
                                        <Card.Title>
                                            <div className='datedetail'>
                                                <div className='d-flex '>
                                                    <img src="assets/icons/Pricetag.png" alt="Price tag" className='Pricetag' />
                                                    <span>Healthcare</span>
                                                </div>
                                                <div className='d-flex '>
                                                    <img src="assets/icons/Calendar.png" alt="Calendar" className='Calendar' />
                                                    <span>25 JAN, 2024</span>
                                                </div>
                                            </div>
                                        </Card.Title>
                                        <Card.Text className='my-4'>
                                            <h4>The best healthcare <br />systems around the world</h4>
                                        </Card.Text>
                                        <a href="#" className='readmore'>
                                            Read More <span> <img src="assets/icons/arrow-square-up-right.png" alt="arrow-square-up-right" className='circlearrow' /></span>
                                        </a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} xs={12} className='mb-4'>
                            <Card className='blogcard border-0'>
                                <Card.Img variant="top" src="assets/images/blogimg2.png" alt="blogimg2" className='blogImg' />
                                <Card.Body className='p-0'>
                                    <div className='bloginr'>
                                        <Card.Title>
                                            <div className='datedetail'>
                                                <div className='d-flex '>
                                                    <img src="assets/icons/Pricetag.png" alt="Price tag" className='Pricetag' />
                                                    <span>Healthcare</span>
                                                </div>
                                                <div className='d-flex '>
                                                    <img src="assets/icons/Calendar.png" alt="Calendar" className='Calendar' />
                                                    <span>25 JAN, 2024</span>
                                                </div>
                                            </div>
                                        </Card.Title>
                                        <Card.Text className='my-4'>
                                            <h4>How often should I get <br /> myself checked by doctor?</h4>
                                        </Card.Text>
                                        <a href="#" className='readmore'>
                                            Read More <span> <img src="assets/icons/arrow-square-up-right.png" alt="arrow-square-up-right" className='circlearrow' /></span>
                                        </a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} xs={12} className='mb-4'>
                            <Card className='blogcard border-0'>
                                <Card.Img variant="top" src="assets/images/blogimg3.png" alt="blogimg3" className='blogImg' />
                                <Card.Body className='p-0'>
                                    <div className='bloginr'>
                                        <Card.Title>
                                            <div className='datedetail'>
                                                <div className='d-flex '>
                                                    <img src="assets/icons/Pricetag.png" alt="Price tag" className='Pricetag' />
                                                    <span>Healthcare</span>
                                                </div>
                                                <div className='d-flex '>
                                                    <img src="assets/icons/Calendar.png" alt="Calendar" className='Calendar' />
                                                    <span>25 JAN, 2024</span>
                                                </div>
                                            </div>
                                        </Card.Title>
                                        <Card.Text className='my-4'>
                                            <h4>10 Steps to find the EHR <br /> that is best for you</h4>
                                        </Card.Text>
                                        <a href="#" className='readmore'>
                                            Read More <span> <img src="assets/icons/arrow-square-up-right.png" alt="arrow-square-up-right" className='circlearrow' /></span>
                                        </a>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Container >
            </section >


            {/*------------- Blog Section Exit -------------- */}




            {/*------------- Process Section Start -------------- */}


            <section className='process_sec py-5'>
                <Container>
                    <Row>
                        <Col lg={12} md={12} xs={12} className='mx-auto text-center mb-5'>
                            <div className='customhead'>
                                <h2 className='mb-3'>But The Process Must be Complicated?</h2>
                                <h5>Seamless scheduling, personalized treatment plans, real-time monitoring,<br /> and compassionate support, all within 5 steps!</h5>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col lg={2} md={6} xs={12} className='mb-4'>
                            <div className='ineractivebox'>
                                <div>
                                    <img src="assets/icons/Chiropractoricon.png" alt="Chiropractoricon" className='ineractiveicons mb-3' />
                                </div>
                                <div className='ineractiveText'>
                                    <h4>Rehabilitation Sessions</h4>
                                    <p>Visual representation of rehabilitation sessions for effective planning</p>
                                </div>
                            </div>
                        </Col>
                    </Row> */}

                    <Row>
                        <Col lg={12} md={6} xs={12} className='mt-5 text-center'>
                            <Button variant="secondary-btn3" className='px-5 py-3 '>Explore Now ! </Button>
                        </Col>
                    </Row>

                </Container >
            </section >



            {/*------------- Process Section Exit -------------- */}




        </>
    )
}

export default Home;
