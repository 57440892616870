import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import toast from "react-hot-toast";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { updateStaffAction, getStaffDetailsAction } from "../../Action/admin.action";
import { decryptData } from "./decrypt";

const EditStaffDuties = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // Time slots state initialized with empty time fields
  const [timeSlots, setTimeSlots] = useState({
    Sunday: { selected: false, timeSlots: [{ from: "", to: "" }] },
    Monday: { selected: false, timeSlots: [{ from: "", to: "" }] },
    Tuesday: { selected: false, timeSlots: [{ from: "", to: "" }] },
    Wednesday: { selected: false, timeSlots: [{ from: "", to: "" }] },
    Thursday: { selected: false, timeSlots: [{ from: "", to: "" }] },
    Friday: { selected: false, timeSlots: [{ from: "", to: "" }] },
    Saturday: { selected: false, timeSlots: [{ from: "", to: "" }] },
  });

  const [staffDetails, setStaffDetails] = useState({});

  const daysOfWeek = Object.keys(timeSlots);

  // Handle checkbox change for selecting the days
  const handleCheckboxChange = (day) => {
    setTimeSlots((prevState) => {
      const isSelected = !prevState[day].selected;
      return {
        ...prevState,
        [day]: {
          ...prevState[day],
          selected: isSelected,
        },
      };
    });
  };

  // Handle time change for the slots
  const handleTimeChange = (day, index, timeType, value) => {
    setTimeSlots((prevTimeSlots) => {
      const updatedTimeSlots = prevTimeSlots[day].timeSlots.map((slot, idx) => {
        if (idx === index) {
          return {
            ...slot,
            [timeType]: value,
          };
        }
        return slot;
      });
      return {
        ...prevTimeSlots,
        [day]: {
          ...prevTimeSlots[day],
          timeSlots: updatedTimeSlots,
        },
      };
    });
  };

  // Add a time slot
  const handleAddTimeSlot = (day) => {
    setTimeSlots((prevTimeSlots) => {
      return {
        ...prevTimeSlots,
        [day]: {
          ...prevTimeSlots[day],
          timeSlots: [
            ...prevTimeSlots[day].timeSlots,
            { from: "", to: "" },  // New slot with empty fields
          ],
        },
      };
    });
  };

  // Remove a time slot
  const handleRemoveTimeSlot = (day, index) => {
    setTimeSlots((prevTimeSlots) => {
      const updatedTimeSlots = [...prevTimeSlots[day].timeSlots];
      updatedTimeSlots.splice(index, 1);
      return {
        ...prevTimeSlots,
        [day]: {
          ...prevTimeSlots[day],
          timeSlots: updatedTimeSlots,
        },
      };
    });
  };

  // Validate time slots before submitting
  const validateTimeSlots = () => {
    for (const day in timeSlots) {
      const { selected, timeSlots: dayTimeSlots } = timeSlots[day];
      if (selected) {
        for (let i = 0; i < dayTimeSlots.length; i++) {
          const slot = dayTimeSlots[i];
          if (!slot.from || !slot.to) {
            toast.error(`${day}: Both "From Time" and "To Time" are required for time slot ${i + 1}.`);
            return false;
          }
        }
      }
    }
    return true;
  };

  // Map API response to the timeSlots state
  const mapApiResponseToTimeSlots = (apiResponse) => {
    const updatedTimeSlots = {};

    daysOfWeek.forEach((day) => {
      updatedTimeSlots[day] = {
        selected: false,
        timeSlots: [{ from: "", to: "" }], // Ensure at least one empty slot
      };
    });

    // Parsing slotTime as it may be a string
    apiResponse.forEach(({ day, slotTime }) => {
      let parsedSlotTime = [];

      if (typeof slotTime === "string") {
        try {
          parsedSlotTime = JSON.parse(slotTime);
        } catch (e) {
          console.error("Error parsing slotTime: ", e);
        }
      } else {
        parsedSlotTime = slotTime;
      }

      if (parsedSlotTime.length > 0 && updatedTimeSlots[day]) {
        updatedTimeSlots[day].selected = true;
        updatedTimeSlots[day].timeSlots = parsedSlotTime.map((slot) => ({
          from: slot.from || "",  // Default to empty if no value
          to: slot.to || "",      // Default to empty if no value
        }));
      }
    });

    return updatedTimeSlots;
  };

  const handleSubmit = async (values) => {
    if (!validateTimeSlots()) return;

    const selectedDaysWithTimes = daysOfWeek
      .filter((day) => timeSlots[day].selected && timeSlots[day].timeSlots.length > 0)
      .map((day) => ({
        day,
        slotTime: JSON.stringify(
          timeSlots[day].timeSlots
            .filter((slot) => slot.from && slot.to)
            .map((slot) => ({
              from: slot.from,
              to: slot.to,
            }))
        ),
      }));

    values = staffDetails;
    values.userRolesIds = JSON.stringify(values.userRoleId);
    values.selectedTimesData = JSON.stringify(selectedDaysWithTimes);

    let res = await updateStaffAction(values);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseUrl}duty-schedule`);
      }, 2000);
    } else {
      toast.error(res.msg);
    }
  };

  // Fetch staff details
  useEffect(() => {
    const getStaffDetailsAPI = async () => {
      let res = await getStaffDetailsAction({ id });
      if (res.success) {
        let data = decryptData(res.data);
        if (data.roleIds) {
          data.userRoleId = data.roleIds.map((item) => ({
            value: item.value,
            label: item.label,
          }));
        } else {
          data.userRoleId = [];
        }

        if (data.staffDutyIds && data.staffDutyIds.length > 0) {
          const preselectedTimeSlots = mapApiResponseToTimeSlots(data.staffDutyIds);
          setTimeSlots(preselectedTimeSlots);
        }

        setStaffDetails(data);
      }
    };

    getStaffDetailsAPI();
  }, [id]);

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12}>
                <h5>Edit User Duties</h5>
                <Link to={`${config.baseUrl}duty-schedule`}>
                  <BsArrowLeftCircleFill /> Back
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Formik
                  initialValues={staffDetails}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <div style={{ border: "1px solid #ddd", padding: "15px" }}>
                        <Row>
                          <Col xs={2}>
                            <div className="d-flex">
                              <span className="me-2">Slot</span>
                              <span>Day</span>
                            </div>
                          </Col>
                          <Col xs={2}>Slot From Time</Col>
                          <Col xs={2}>Slot To Time</Col>
                          <Col xs={2}>Action</Col>
                        </Row>

                        {daysOfWeek.map((day) => (
                          <div key={day}>
                            <Row>
                              <Col xs={2}>
                                <Form.Check
                                  type="checkbox"
                                  label={day}
                                  checked={timeSlots[day].selected}
                                  onChange={() => handleCheckboxChange(day)}
                                />
                              </Col>
                            {timeSlots[day].timeSlots.map((slot, index) => (
                              <Row className="mb-2" key={index}>
                                <Col xs={2}></Col>
                                <Col xs={2}>
                                  <input
                                    className="form-control"
                                    type="time"
                                    value={slot.from || ""}
                                    onChange={(e) =>
                                      handleTimeChange(day, index, "from", e.target.value)
                                    }
                                  />
                                </Col>
                                <Col xs={2}>
                                  <input
                                    className="form-control"
                                    type="time"
                                    value={slot.to || ""}
                                    onChange={(e) =>
                                      handleTimeChange(day, index, "to", e.target.value)
                                    }
                                  />
                                </Col>
                                <Col xs={2}>
                                  {timeSlots[day].timeSlots.length > 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => handleRemoveTimeSlot(day, index)}
                                    >
                                      Remove Slot
                                    </button>
                                  )}
                                </Col>
                              </Row>
                            ))}
                            </Row>
                            <Row>
                              <Col xs={2}></Col>
                              <Col xs={2}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => handleAddTimeSlot(day)}
                                >
                                  Add Slot
                                </button>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>

                      <button type="submit" className="btn btn-primary mt-3">
                        Submit
                      </button>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default EditStaffDuties;
