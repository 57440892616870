import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Dropdown,
  Modal,
  Card
} from "react-bootstrap";
import "../componentCss/webform.css";
import Dashboardheader from "./dashboardheader";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import {
  addDoctorAppointment,
  getDoctorList,
  getFormDataListByStaffAction,
  patientStatusUpdateByIdAction,
} from "../../Action/admin.action";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import config from "../../config/config";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import toast, { Toaster } from 'react-hot-toast';
import AppointmentScheduleModal from "./ModalComponents/AppointmentScheduleModal";
import { FaEye } from "react-icons/fa";
import { BsFileText, BsEye, BsCopy } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { GrSchedule } from "react-icons/gr";
import Webform from "./formDataSubmission";
import propertise from "../../config/properties";
import { decryptData } from './decrypt';
import { useDispatch, useSelector } from 'react-redux';
import AddendumModal from './ModalComponents/AddendumModal';
import { addReportsReason } from '../../redux/slices/reportsSlice';

const ViewFormData = () => {
  const dispatch = useDispatch()
  let { id, name } = useParams();
  const [formFields, setFormFields] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [uniqueId, setUniqueId] = useState(null);
  const [mrnNumber, setMrnNumber] = useState(null);
  const [doctorList, setDoctorList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [currentRawData, setCurrentRawData] = useState(null)
  const [status, setStatus] = useState(1)
  const [showModal, setShowModal] = useState(false);
  const [showModalAddendum, setShowModalAddendum] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const printContentRef = useRef();
  const navigate = useNavigate();
  const [formList, setFormData] = useState([]);
  const [searchTerms, setSearchTerms] = useState({
    FirstName: '',
    LastName: '',
    MrnNumber: '',
    MobileNumber: '',
    EmiratesIdNumber: '',
    PassportNumber: '',
    dropdownValue: ''
  });

  useEffect(() => {
    getFormDataListAPI();
    getDoctorListData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchTerms({
      ...searchTerms,
      [name]: value,
    });
  };

  let getFormDataListAPI = async () => {
    let MRNNumber = localStorage.getItem('mrnNumber');
    let res = await getFormDataListByStaffAction({ id: id, 'MRNNumber': MRNNumber });

    setIsLoading(false);
    if (res.success) {
      const filteredFields = decryptData(res.data?.fields)?.filter(field => field.fieldName !== 'Staff Name' && field.fieldName !== 'Middle Name' && field.fieldName !== 'Nick Name');
      setFormFields(filteredFields);
      // setFormFields(decryptData(res.data?.fields));
      setTotalRows(res.data.totalRows);
      setFormData(decryptData(res.data?.records));
      setFilteredData(decryptData(res.data?.records));
    }
  };
  // Git doctor list
  let getDoctorListData = async () => {
    let res = await getDoctorList();
    setIsLoading(false);
    if (res.success) {
      setDoctorList(decryptData(res.data));
    }
  };

  let handleSaveAppointment = async (values) => {
    values.mrnNumber = mrnNumber
    let res = await addDoctorAppointment(values);
    if (res.success) {
      setShow(!show);
      toast.success(res.msg);
    } else {
      toast.error(res.msg);
    }
  };
  const printAllData = useReactToPrint({
    content: () => printContentRef.current,
    pageStyle: `
            @media print {
                @page {
                    size: landscape;
                    margin: 0;
                }
                body {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                }
                #root {
                    transform: scale(0.8); /* Adjust scale as needed */
                    transform-origin: top left;
                    width: 100%;
                    height: 100%;
                    overflow: visible;
                }
            }
        `,
  });

  const handleShowModal = (e, uId, mrnNumber) => {
    setShow(!show);
    setUniqueId(uId);
    setMrnNumber(mrnNumber)
  };

  const statusMapping = {
    1: "Active",
    2: "Discharge",
    3: "Deceased",
    4: "re-admit"
  };
  const statusMappingForDropdown = {
    2: "Discharge",
    3: "Deceased",
    4: "re-admit"
  };
  const handleStatusChange = async (id, newStatus) => {
    if (newStatus == 2) {
      <Webform data={newStatus} />
      navigate(`${config.baseUrl}form-submission/4/0/${id}`)

    } else {
      let data = {
        recordId: id,
        status: newStatus
      }
      let res = await patientStatusUpdateByIdAction(data);
      if (res.success) {
        toast.success(res.msg);
        getFormDataListAPI()
      } else {
        toast.error(res.msg);
      }
    }
  };


  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };

  const handleFilter = () => {
    let tempFormList = [...formList]
    const filteredData = tempFormList.filter(entry => {
      return (!searchTerms.FirstName || entry[propertise.PatientFirstName]?.trim().toLowerCase().includes(searchTerms.FirstName.trim().toLowerCase())) &&
        (!searchTerms.LastName || entry[propertise.PatientLastName]?.trim().toLowerCase().includes(searchTerms.LastName.trim().toLowerCase())) &&
        (!searchTerms.MrnNumber || entry[propertise.PatientMrnNumber]?.trim().toLowerCase().includes(searchTerms.MrnNumber.trim().toLowerCase())) &&
        (!searchTerms.MobileNumber || entry[propertise.PatientMobileNumber]?.trim().toLowerCase().includes(searchTerms.MobileNumber.trim().toLowerCase())) &&
        (!searchTerms.EmiratesIdNumber || entry[propertise.emiratesIdNumber]?.trim().toLowerCase().includes(searchTerms.EmiratesIdNumber.trim().toLowerCase())) &&
        (!searchTerms.PassportNumber || entry[propertise.PatientPasportNumber]?.trim().toLowerCase().includes(searchTerms.PassportNumber.trim().toLowerCase())) &&
        (!searchTerms.dropdownValue || entry[propertise.PatientSatus]?.trim().toLowerCase().includes(searchTerms.dropdownValue.trim().toLowerCase()));
    });
    if (filteredData.length) {

    } else {
      toast.error('Data not found')
    }
    setFilteredData(filteredData);
  }

  const handleReset = () => {
    setSearchTerms({
      FirstName: '',
      LastName: '',
      MrnNumber: '',
      MobileNumber: '',
      EmiratesIdNumber: '',
      PassportNumber: '',
      dropdownValue: ''
    })
    getFormDataListAPI();
  }

  const handleUpdateStatus = async (e, rowData) => {
    if (e.target.value == 4) {
      navigate(`${config.baseUrl}viewTreatment/${id}/${rowData.recordId}/${name}`)
    } else if (e.target.value == 5) {
      navigate(`${config.baseUrl}copyTemplate/${id}/${rowData.recordId}/${name}`)
    } else if (rowData.status == 2) {
      toast.error('Record has been already invalidated.');
    } else if (rowData.status == 3) {
      toast.error('Record has been already addendum.');
    } else if (e.target.value == 6) {
      navigate(`${config.baseUrl}editForm-submission/${id}/${rowData.recordId}/${name}`)
    } else {

      if (rowData.remainingHour <= 0 && rowData.isLateEntry == 1) {
        toast.error('Not accessible.')
        return;
      }
      rowData.formId = id;
      setCurrentRawData(rowData)
      if (e.target.value == 3 || e.target.value == 2) {
        setShowModalAddendum(true)
      }
      setStatus(e.target.value)
    }
  }

  const handleClose = () => {
    setShowModalAddendum(false)
  }

  const handleSubmit = async (data) => {
    const res = await dispatch(addReportsReason(data))
    setShowModalAddendum(!showModalAddendum)
    if (res && res.payload && res.payload?.success) {
      toast.success(res.payload?.msg)
      getFormDataListAPI();
    }
  }

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Col lg={12} md={12} sm={12}>
              <div className="formBox me-3">
                <Row>
                  <Col lg={9} md={9} sm={9} className="">
                    <div className="innerhead mb-4">
                      <h5 className="headtitle">{name} List</h5>
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={3} className="pull-right">
                    <Link to={`${config.baseUrl}form-submission/${id}/0`}>
                      <button style={{ float: 'right' }} className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6">
                        Add+
                      </button>
                    </Link>
                    &nbsp;
                    <button style={{ float: 'right' }}
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mx-2 no-print"
                      onClick={printAllData}
                    >
                      Print
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>

            <Row>
              <Col lg={12} md={12} sm={12} className="mt-3">
                {/* Patient filter */}
                {id == 1 &&
                  <>
                    <Row>
                      {['FirstName', 'LastName', 'MrnNumber', 'MobileNumber', 'EmiratesIdNumber', 'PassportNumber'].map((field, index) => (
                        <Col key={index} md={4} sm={6} className="mb-3">
                          <Form.Group className="fieldbox h-auto px-2">
                            <Form.Label>{field.replace(/([A-Z])/g, ' $1')}</Form.Label>
                            <Form.Control
                              type={field === 'mobileNumber' ? 'number' : 'text'}
                              placeholder={`Search By ${field.replace(/([A-Z])/g, ' $1')}`}
                              className="form-control"
                              name={field}
                              value={searchTerms[field]}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      ))}
                      <Col md={12} sm={12} className="mb-3">
                        <Form.Group className="fieldbox h-auto px-2">
                          <Form.Label>Status</Form.Label>
                          <Form.Select
                            className="form-select"
                            value={searchTerms.dropdownValue}
                            onChange={handleChange}
                            name="dropdownValue"
                            aria-label="Default select example"
                          >
                            <option value="" disabled>Select Status</option>
                            <option value="1">Active</option>
                            <option value="2">Discharge</option>
                            <option value="3">Deceased</option>
                            <option value="4">Pre-admit</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex">
                        <div className="">
                          <Button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4" onClick={() => handleFilter()}>Search</Button>
                        </div>
                        &nbsp;
                        <div >
                          <Button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4" onClick={() => handleReset()}>Reset</Button>
                        </div>
                      </Col>
                    </Row>
                    <hr /></>
                }
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{ width: "40px" }}
                      src="assets/images/loading.gif"
                    />
                  </div>
                ) : totalRows.length > 0 ? (
                  <div
                    className="maintable table-responsive"
                    ref={printContentRef}
                  >
                    <Table hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          {formFields.map((field, i) => (
                            i < 4 &&
                              (field.id !== propertise.PatientUniqueId && field.id !== propertise.PatientMrnNumber) ? (
                              <th key={i}>{field.fieldName}</th>
                            ) : null
                          ))}
                          <th>Status</th>
                          <th></th>
                          {id != 3 || id != 4 || id != 5 &&
                            <th>Action</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((data, i) => (
                          <tr >
                            <td>{i + 1}</td>
                            {formFields.map((field, j) => (
                              j < 4 &&
                              <React.Fragment key={field.id}>
                                {field.id !== propertise.PatientUniqueId && field.id !== propertise.PatientMrnNumber ? (
                                  <td>
                                    {field.fieldType === 'file' && data[field.id] ? (
                                      field.id === propertise.PatientDiagramImage && (
                                        <img
                                          src={data[field.id]}
                                          style={{ height: '100px', width: '100px', cursor: 'pointer' }}
                                          alt=""
                                          onClick={() => handleImageClick(data[field.id])}
                                        />
                                      )
                                    ) : (
                                      field.id === propertise.PatientSatus ? statusMapping[data[field.id]] : data[field.id]
                                    )}
                                  </td>
                                ) : null}
                              </React.Fragment>
                            ))}
                            <td>
                              {data.status === 3 ? <div className='statusAddendum'>ADDENDUM</div> : data.status === 2 ? <div className='statusInvalid'>INVALIDATED</div> : <div className='statusActive'>ACTIVE</div>}
                            </td>
                            <td>
                              {/* {id == 3 && (
                                <>
                                  &nbsp;&nbsp;
                                  <Link
                                    to={`${config.baseUrl}PatientDetails/${data.recordId}/${data[propertise.EMRID]}`}
                                  >
                                    <span><BsFileText fill='#434544' title="Records" size={24} /></span>
                                  </Link>
                                </>)} */}
                              {id == propertise.adtForm && (
                                <>

                                  <Link
                                    to={`${config.baseUrl}form-submission/${propertise.dischargeForm}/0`}
                                  >
                                    <button style={{ float: 'right' }} className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6">
                                      Discharge
                                    </button>
                                  </Link>

                                  <Link
                                    to={`${config.baseUrl}form-submission/${propertise.patientTransfer}/0`}
                                  >
                                    <button style={{ float: 'right' }} className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6">
                                      Transfer
                                    </button>
                                  </Link>

                                  <Link
                                    to={`${config.baseUrl}editForm-submission/${id}/${data.recordId}/${name}`}>
                                    <button style={{ float: 'right' }} className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6">
                                      Edit
                                    </button>
                                  </Link>
                                </>)}

                              {id == 3 && (
                                <>
                                  &nbsp;&nbsp;
                                  <span onClick={(e) => handleShowModal(e, data[propertise.PatientUniqueId], data[propertise.PatientMrnNumber])}>
                                    <GrSchedule fill='#434544' title="Schedule" style={{ cursor: 'pointer' }} size={24} />
                                  </span>
                                  &nbsp;&nbsp;
                                </>
                              )}
                              <br />
                              <br />
                              {/* {id == 3 && (
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    {propertise.PatientSatus && data[propertise.PatientSatus] ? statusMapping[data[propertise.PatientSatus]] : 'Status'}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {Object.entries(statusMappingForDropdown).map(([value, label]) => (
                                      <Dropdown.Item
                                        key={value}
                                        onClick={() => handleStatusChange(data.recordId, value)}
                                      >
                                        {label}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )} */}
                            </td>
                            {id != 3 || id != 4 || id != 5 &&
                              <td>
                                <select className='form-control' style={{ width: 'auto' }} defaultValue={data.status} onChange={(e) => handleUpdateStatus(e, data)}>
                                  <option value={1}>Active</option>
                                  <option value={4}>View</option>
                                  <option value={5}>Copy Paste</option>
                                  <option value={2}>Invalid</option>
                                  <option value={3}>Addendum</option>
                                  <option value={6}>Edit</option>
                                </select>
                              </td>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <p className="text-center">
                    <img src="assets/images/no-image.png" />
                  </p>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {showModalAddendum && <AddendumModal showModal={showModalAddendum} handleClose={handleClose} handleSubmit={handleSubmit} currentRawData={currentRawData} status={status} formFields={formFields} />}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Modal Image" style={{ width: '100%' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <AppointmentScheduleModal
        show={show}
        setShow={setShow}
        uniqueId={uniqueId}
        doctorList={doctorList}
        handleSaveAppointment={handleSaveAppointment}
      />
    </>
  );
};

export default ViewFormData;
