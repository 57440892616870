import React, { useState } from "react";
import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ReportFilter from "./ReportFilter";
import { reportsFileds } from "../../../config/properties";

const ReportsTable = ({ selectedReport, formData, formFields, totalRows, setFormData }) => {
    const [months, setMonths] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
    const [tempFilterData, setTempFilterData] = useState(formData);

    const handleFilter = (filters, startDate, endDate) => {
        let filteredData = tempFilterData;

        if (filters.startMonth && filters.endMonth) {
            const startMonth = new Date(filters.startMonth);
            const endMonth = new Date(filters.endMonth);

            filteredData = formData.filter(item => {
                const itemDate = new Date(item.datetime);
                const itemMonthYear = new Date(itemDate.getFullYear(), itemDate.getMonth(), 1);

                return itemMonthYear >= new Date(startMonth.getFullYear(), startMonth.getMonth(), 1) &&
                       itemMonthYear <= new Date(endMonth.getFullYear(), endMonth.getMonth(), 1);
            });
        }
        if (filters.patient) {
            filteredData = filteredData.filter(item => item['1929'] === filters.patient);
        }
        if (filters.staffName) {
            filteredData = filteredData.filter(item => item.staffName && item.staffName.toLowerCase().includes(filters.staffName.toLowerCase()));
        }
        if (filters.billed) {
            filteredData = filteredData.filter(item => item.billed === filters.billed);
        }
        if (startDate && endDate) {
            filteredData = filteredData.filter(item => {
                const itemDate = new Date(item.datetime.split(' ')[0]);
                const newStartDate = new Date(startDate);
                const newEndDate = new Date(endDate);
                return itemDate >= newStartDate && itemDate <= newEndDate;
            });
        }

        setTempFilterData(filteredData);
    };

    const handleReset = () => {
        setTempFilterData(formData);
    };

    function filterFields(array, fields) {
        const values = Object.values(fields).map(String);
        return array.filter(item => values.includes(String(item.id)));
    }

    function filterFiledData(array, fields) {
        const keys = Object.values(fields).map(String);
        return array.map(obj => {
            const filteredObj = {};
            keys.forEach(key => {
                if (obj[key] !== undefined) {
                    filteredObj[key] = obj[key];
                }
            });
            return filteredObj;
        });
    }

    function filterFiledData(array, fields) {
        const keys = Object.values(fields).map(String);
        return array.map(obj => {
            const filteredObj = {};
            keys.forEach(key => {
                if (obj[key] !== undefined) {
                    filteredObj[key] = obj[key];
                }
            });
            return filteredObj;
        });
    }
    
    const filteredHeadinArray = filterFields(formFields, reportsFileds);
    const filteredFiledData = filterFiledData(tempFilterData, reportsFileds);

    const columns = filteredHeadinArray.map(field => ({
        name: field.fieldName,
        selector: row => row[field.id],
        sortable: true,
        cell: row => <div className="custom-header">{row[field.id]}</div>
    }));

    return (
        <div className="">
            <Form>
                {selectedReport && (
                    <ReportFilter
                        setFormData={setFormData}
                        selectedReport={selectedReport}
                        totalRows={totalRows}
                        formData={formData}
                        handleFilter={handleFilter}
                        months={months}
                        handleReset={handleReset}
                    />
                )}
            </Form>

            <DataTable
                columns={columns}
                data={filteredFiledData}
                pagination
                customStyles={{
                    headCells: {
                        style: {
                            backgroundColor: '#f5f5f5',
                            color: '#333',
                            fontWeight: 'bold',
                        },
                    },
                }}
            />
        </div>
    );
};

export default ReportsTable;
