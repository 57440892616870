import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import "../componentCss/webform.css";
import Dashboardheader from "./dashboardheader";
import { useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import { getFormDataListAction } from "../../Action/admin.action";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { decryptData } from './decrypt';

const ViewFormData = () => {
  let { id, name, recordId, subFormId } = useParams();
  const [formFields, setFormFields] = useState([]);
  const [formList, setFormData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [subformList, setSubFormData] = useState([]);
  const [subFromField, setSubFromField] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    getFormDataListAPI();
  }, []);

  let getFormDataListAPI = async () => {
    let res = await getFormDataListAction({
      id: id,
      recordId: recordId,
      subFormId: subFormId,
    });
   
    setIsLoading(false);
    if (res.success) {
      let formData = decryptData(res.data.records);
      let { subFormValue, ...filteredFormData } = formData[0];
      setFormData(filteredFormData);
      setFormFields(decryptData(res.data.fields));
      setSubFromField(decryptData(res.data.SubFormfields));
      setSubFormData(subFormValue);
    }
  };

  const fieldMap = formFields.reduce((acc, field) => {
    acc[field.id] = field.fieldName;
    return acc;
  }, {});
  const SubfieldMap = subFromField.reduce((acc, field) => {
    acc[field.id] = field.fieldName;
    return acc;
  }, {});

  const transformRecord = (record, fieldMap) => {
    const transformed = {};
    for (const key in record) {
      if (fieldMap[key]) {
        transformed[fieldMap[key]] = record[key];
      } else if (
        typeof record[key] === "object" &&
        record[key] !== null &&
        !Array.isArray(record[key])
      ) {
        transformed[key] = transformRecord(record[key], fieldMap);
      } else {
        transformed[key] = record[key];
      }
    }
    return transformed;
  };
  const subTransformRecord = (record, fieldMap) => {
   
    const subtransformed = {};
    for (const key in record) {
      if (fieldMap[key]) {
        subtransformed[fieldMap[key]] = record[key];
      } else if (
        typeof record[key] === "object" &&
        record[key] !== null &&
        !Array.isArray(record[key])
      ) {
        subtransformed[key] = transformRecord(record[key], fieldMap);
      } else {
        subtransformed[key] = record[key];
      }
    }

    return subtransformed;
  };

  const transformedRecord = transformRecord(formList, fieldMap);
  const subTransformedRecord = subTransformRecord(subformList, SubfieldMap);

  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={9} md={9} sm={9} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">{name} List</h5>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} className="mt-3">
           

                <ul className="list-group">
                  {isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{ width: "40px" }}
                        src="assets/images/loading.gif"
                      />
                    </div>
                  ) : (
                    <>
                      {Object.entries(transformedRecord).map(
                        ([key, value], index) => (
                          <li
                            key={index}
                            className="list-group-item d-flex flex-row"
                          >
                            <div className="w-50">
                              <strong className="staffView w-50">{key}:</strong>{" "}
                            </div>
                            {value}
                          </li>
                        )
                      )}
                      {Object.entries(subTransformedRecord).map(
                        ([key, value], index) => (
                          <li
                            key={index}
                            className="list-group-item d-flex flex-row"
                          >
                            <div className="w-50">
                              <strong className="staffView w-50">{key}:</strong>{" "}
                            </div>
                            {value}
                          </li>
                        )
                      )}
                    </>
                  )}
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ViewFormData;
