import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]?$/;
const nameRegExp = /^[A-Za-z]+$/;



export const addPatientSchema = Yup.object().shape({
  userName: Yup.string()
    .min(2, "Too Short Name!")
    .max(50, "Too Long Name!")
    .required("User name is required."),
  password: Yup.string()
    .required("Password is required.")
    .max(18, "Password is too long - should be maximum 20 chars.")
    .min(7, "Password is too short - should be 8 chars minimum.")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a special chars"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Password and confirm password does not match"
    )
    .required("Confirm password is required."),

  MRNno: Yup.string().required("MRN Number is required."),
});

export const editPatientSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "Too Short Name!")
    .max(50, "Too Long Name!")
    .required("User name is required."),
  

    password: Yup.string()
    .notRequired()
    .test(
      'is-valid-password',
      'Password must be between 8-18 characters long and include at least one number, one uppercase letter, one lowercase letter, and one special character.',
      function (value) {
        if (value && value.length > 0) {
          const schema = Yup.string()
            .min(8, "Password is too short - should be 8 chars minimum.")
            .max(18, "Password is too long - should be maximum 18 chars.")
            .matches(/[0-9]/, "Password requires a number")
            .matches(/[a-z]/, "Password requires a lowercase letter")
            .matches(/[A-Z]/, "Password requires an uppercase letter")
            .matches(/[^\w]/, "Password requires a special character");
          return schema.isValidSync(value);
        }
        return true; // No validation errors if password is not provided
      }
    ),

    // confirmPassword: Yup.string()
    // .required("Confirm password is required."),
  
    confirmPassword: Yup.string()
    .notRequired()
    .test(
      'passwords-match',
      'Password and confirm password do not match',
      function (value) {
        const { password } = this.parent;
        if (password && password.length > 0) {
          return value === password;
        }
        return true; // No validation errors if password is not provided
      }
    ),
  
  MRNno: Yup.string().required("MRN Number is required."),
});