import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangeModal = ({ show, setShow, handleSelectDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  console.log(startDate, endDate)

  const handlePrint = () => {
    if (startDate && endDate) {
      handleSelectDate({ startDate, endDate });
    }
    setShow(false);
  };

  return (
    <div >
      <Modal show={show} dialogClassName="custom-modal-width">
        <Modal.Header closeButton onClick={() => setShow(!show)}>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn-lg btn-secondary-btn3" onClick={handlePrint}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DateRangeModal;
