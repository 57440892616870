import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ReportsModal = ({ show, handleClose, handleSubmit, formList }) => {
  const [reportTitle, setReportTitle] = useState('');
  const [formId, setFormId] = useState(null);


  const onInputChange = (e) => {
    setReportTitle(e.target.value);
  };

  const handleChangeForm = (e) => {
    setFormId(e.target.value)
  }

  const onSubmit = () => {
    handleSubmit(reportTitle, formId);
    setReportTitle(''); // Clear the input after submit
    setFormId(''); // Clear the input after submit

  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="reportTitle">
              <Form.Label>Form</Form.Label>
              <select
                          className="form-control"
                          name="formId"
                          onChange={handleChangeForm}
                        >
                          <option value={0}>Select Form</option>
                          {formList.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        ))}
                        </select>
            </Form.Group>
            <Form.Group controlId="reportTitle">
              <Form.Label>Report Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter report title"
                value={reportTitle}
                onChange={onInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReportsModal;
