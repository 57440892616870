import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import '../componentCss/webform.css';
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
import '../componentCss/userGroup.css';
import { getUserGroupListAction, getlogHistoryDetailsAction } from '../../Action/admin.action';
// import { ToastContainer } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { decryptData } from './decrypt';

const LogHistory = () => {
    const [logHistory, setLogHistoryData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [searchRole, setSearchRole] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    
    const handlePageChange = (page) => {
        setCurrentPage(page - 1);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    useEffect(() => {
        getUserGroupListAPI();
    }, []);

    useEffect(() => {
        filterData();
    }, [searchText, searchRole, fromDate, toDate]);

    const getUserGroupListAPI = async () => {
        let res = await getlogHistoryDetailsAction();
        setIsLoading(false);
        if (res.success) {
            setLogHistoryData(decryptData(res.data));
            setFilteredData(decryptData(res.data));
        }
    };

    const filterData = () => {
        const lowercasedValue = searchText.toLowerCase();
        const lowercasedValue2 = searchRole.toLowerCase();
        const fromDateMoment = fromDate ? moment(fromDate).startOf('day') : null;
        const toDateMoment = toDate ? moment(toDate).endOf('day') : null;
        const filtered = logHistory.filter(item => {
            const staffName = item.staffName ? item.staffName.toLowerCase() : '';
            const userGroup = item.userGroup ? item.userGroup.toLowerCase() : '';
            const datetime = item.datetime ? moment(item.datetime) : null;
            const matchesStaffName = staffName.includes(lowercasedValue);
            const matchesUserGroup = userGroup.includes(lowercasedValue2);
            const matchesDateRange = datetime && (
                (!fromDateMoment || datetime.isSameOrAfter(fromDateMoment)) &&
                (!toDateMoment || datetime.isSameOrBefore(toDateMoment))
            );
            return matchesStaffName && matchesDateRange && matchesUserGroup;
        });

        setFilteredData(filtered);
    };
    const columns = [
        {
            name: '#',
            cell: (row, index) => {
                return index + 1 + currentPage * rowsPerPage;
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Activity Type',
            selector: row => row.activityType,
            sortable: true,
            width: "20%"
        },
        {
            name: 'Staff name',
            selector: row => row.staffName,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Role',
            selector: row => row.userGroup,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Stamp',
            selector: row => <img style={{ height: '100px', width: '100px' }} src={row.stampImage} alt="stamp Image" />,
            sortable: true,
            width: "10%"
        },
        {
            name: 'Browser history',
            selector: row => row.browserType,
            sortable: true,
            width: "60%"
        },
        {
            name: 'IP',
            selector: row => row.ip,
            sortable: true,
            width: "12%"
        },
        {
            name: 'Date',
            selector: row => moment(row.datetime).format('DD-MM-YYYY, H:MM:SS A'),
            sortable: true,
            width: "10%"
        },
    ];

    return (
        <>
            {/* <Dashboardheader /> */}
            {/* <ToastContainer /> */}
            {/* <div className="wrapper"> */}
                {/* <Sidebar /> */}
                {/* <div id="content"> */}
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Log History List</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-3">
                                <Form>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Group controlId="search">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search by staff name"
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="search">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search by staff role"
                                                    value={searchRole}
                                                    onChange={(e) => setSearchRole(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="fromDate">
                                                <DatePicker
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={fromDate}
                                                    onChange={(date) => setFromDate(date)}
                                                    placeholderText="Search From Date"
                                                    className="form-control"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group controlId="toDate">
                                                <DatePicker
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={toDate}
                                                    onChange={(date) => setToDate(date)}
                                                    placeholderText="Search To Date"
                                                    className="form-control"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className='maintable table-responsive'>
                                    <h5 className=''>History List</h5>
                                    {isLoading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: '40px' }} src='assets/images/loading.gif' alt='loading' />
                                        </div>
                                        :
                                        <DataTable
                                            columns={columns}
                                            data={filteredData}
                                            pagination
                                            paginationPerPage={rowsPerPage}
                                            paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                                            paginationComponentOptions={{
                                                rowsPerPageText: 'Row per page:',
                                                rangeSeparatorText: 'out of',
                                            }}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                {/* </div> */}
            {/* </div> */}
        </>
    );
};

export default LogHistory;
