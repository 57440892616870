import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required.'),
  password: Yup.string()
    .required('Password is required.')
    .matches(/\b([A-Z])/g, 'Password requires first letter Capital')
    .max(18, 'Password is too long - should be maximum 20 chars.')
    .min(7, 'Password is too short - should be 8 chars minimum.')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a special chars')
});
export const emailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required.'),
 
});
export const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .required('OTP is required.'),
});
export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required.')
    .max(18, 'Password is too long - should be maximum 20 chars.')
    .min(7, 'Password is too short - should be 8 chars minimum.')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a special chars'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password and confirm password does not match').required('Confirm password is required.'),
});