import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from "react-bootstrap";
import '../componentCss/webform.css';
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from '../../config/config';
import { Link } from "react-router-dom";
import { addDepartmentSchema } from "../../validations/staffSchema";
import { updateCategoryAction, getFormCategoryAction } from '../../Action/admin.action';
import { decryptData } from './decrypt';

const EditFormCategory = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [isLaoding, setisLaoding] = useState(0)
    const [category, setCategory] = useState({ name: '', status : 0 })

    useEffect(() => {
        getCategoryDetailsAPI()
    }, []);

    let getCategoryDetailsAPI = async () => {
        let res = await getFormCategoryAction({ 'id': id });
        if (res.success) {
            setCategory(decryptData(res.data))
        }
    }

    let handleSubmit = async (values) => {
        setisLaoding(1)
        values.id = id;
        let res = await updateCategoryAction(values);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                navigate(`${config.baseUrl}form-category-list`);
            }, 2000);
        } else {
            setisLaoding(0)
            toast.error(res.msg);
        }
    }

    return (
        <>
            <Dashboardheader />
            {/* <ToastContainer /> */}
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Edit Category</h5>
                                </div>
                                <Link to={`${config.baseUrl}form-category-list`} >
                                    <span style={{ float: 'right' }}>Back <BsArrowLeftCircleFill /></span>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className='formDiv'>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={category}
                                        validationSchema={addDepartmentSchema}
                                        onSubmit={(values) => {
                                            handleSubmit(values);
                                        }}
                                    >
                                        {({
                                            errors,
                                            handleSubmit,
                                            handleBlur,
                                            handleChange,
                                            touched,
                                            values,
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                <Row className="mb-4">
                                                    <Form.Group as={Col}>
                                                        <Form.Label>Name</Form.Label>
                                                        <input className='form-control' type="text" name='name' onChange={handleChange} placeholder="Please enter category name" onBlur={handleBlur} value={values.name} />

                                                        {errors.name && touched.name && (
                                                            <div className="text-danger">
                                                                {errors.name}
                                                            </div>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group as={Col}>
                                                        <Form.Label>Status</Form.Label>
                                                        <select name='status' value={values.status} className='form-control' onChange={handleChange}>
                                                            <option value={1}>Active</option>
                                                            <option value={0}>Inactive</option>
                                                        </select>
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-4">
                                                    <Form.Group as={Col}>
                                                        {isLaoding ?
                                                            <>
                                                                <button disabled className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Loading <img height="20" width="20px" src="assets/images/loading.gif" /></button>
                                                            </>
                                                            :
                                                            <button type='submit' className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Update</button>
                                                        }
                                                    </Form.Group>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div >

        </>
    )
}

export default EditFormCategory;
