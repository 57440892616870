import PatientData from "../component/admin/patientDetails";

const propertise =
// form Details table id 
{
    PatientSatus: 1932,
    PatientUniqueId: 1859,
    PatientMrnNumber: 1929,
    PatientPasportNumber:1558,
    PatientEmairatesID:1930,
    PatientMobileNumber:30,
    PatientFirstName:16,
    PatientLastName:18,
    PatientDiagramImage:1951,
    patientFormId: 1,
    EMRID : 1859,
    patientAdmit : 3,
    dischargeForm : 44,
    patientTransfer : 31,
    adtForm : 4
};


export const reportsFileds = {
    PatientUniqueId: 1859,
    PatientMrnNumber: 1929,
    staffName: 'staffName',
    DateOfAddmission: 792
}

export default propertise;