import logo from './logo.svg';
import './App.css';
import config from './config/config';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './component/home';
import Login from './component/admin/login';
import ForgotPassword from './component/admin/ForgotPassword';
import ProtectedRoute from './component/admin/protectedRoute';

import Dashboard from './component/admin/dashboard';
import StaffDashboard from './component/admin/staffDashboard';
import Profile from './component/admin/profile';
import ChangePassword from './component/admin/changePassword';
import StaffManagement from './component/admin/staffManagement';
import AddStaff from './component/admin/addStaff';
import EditStaff from './component/admin/editStaff';
import StaffDutyCalender from './component/admin/staffDutyCalender';

import UserGroup from './component/admin/userGroup';
import AddUserGroup from './component/admin/addUserGroup';
import EditUserGroup from './component/admin/editUserGroup';

import AccessForms from './component/admin/accessForms';
import StaffDepartmentFormsList from './component/admin/staffDepartmentFormsList';
import Webform from './component/admin/webform';
import FormDetails from './component/admin/formDetails';
import FormSubmission from './component/admin/formDataSubmission';
import CreateWebForm from './component/admin/createWebForm';
import ViewFormDataList from './component/admin/viewFormDataList';
import ViewFormReportList from './component/admin/viewFormReportList';
import ViewFormData from './component/admin/viewFormDataListInDetail';
import EditFormData from "./component/admin/editFormDataSubmission"
import CopyTemplate from "./component/admin/copyTemplate"
import ViewRecord from "./component/admin/viewRecord"
import ViewTreatment from "./component/admin/viewTreatment"
import ViewMasterDataList from './component/admin/viewMasterDataList';
import EditWebForm from './component/admin/editWebForm';

import DepartmentList from './component/admin/departmentList';
import AddDepartment from './component/admin/addDepartment';
import EditDepartment from './component/admin/editDepartment';

import FormCategoryList from './component/admin/formCategoryList';
import AddFormCategory from './component/admin/addFormCategory';
import EditFormCategory from './component/admin/editFormCategory';

import DepartmentFormsList from './component/admin/departmentFormsList';
import AddDepartmentForm from './component/admin/addDepartmentForm';
import EditDepartmentForms from './component/admin/editDepartmentForms';

import CPTMaster from './component/admin/CPTMaster';
import DrugMaster from './component/admin/drugMaster';
import HCPCSMaster from './component/admin/hcpcsMaster';
import ICD10Master from './component/admin/icd10Master';

import Reports from './component/admin/reports';
import Treatments from './component/admin/treatments';

import MasterData from './component/admin/masterList';
import AddMaster from './component/admin/addMaster';
import EditMaster from './component/admin/editMaster';
import EditTableData from "./component/admin/editTableData"
import Sketch from './component/admin/Sketch';
import LogHistory from './component/admin/logHistory';
import PatientData from './component/admin/patientDetails';
// import personalDetails from './component/admin/personaldetails'
import PersonalDetails from './component/admin/personaldetails';
import CalendarComponent from './component/admin/CalendarComponent';
import PageNotFound from './component/admin/NotFound';
import  {Toaster} from 'react-hot-toast';
import AdminLayout from './component/admin/LayoutsComponents/AdminLayout';
import CardReaderComponent from './component/admin/CardReaderComponent';
import NotificationComponent from './component/admin/NotificationComponent'; 
import ReportsList from './component/admin/Reports/index';

import AddPatientCredentials from "./component/admin/addPatientCredentials"
import PatientCredentials from "./component/admin/patientCredentials"
import EditPatientCredentials from "./component/admin/editPatientCredentials"
import UserPermissions from './component/admin/userPermissions';
import DutySchedule from './component/admin/dutySchedule';
import RolesPermission from './component/admin/rolesPermission';
import EditStaffDuties from './component/admin/editStaffDuties';
function App() {

  return (
    <>
 <Toaster position="top-right"/>
      <BrowserRouter>
        <Routes>
          <Route path={`${config.baseUrl}*`} element={<PageNotFound />} />
          <Route path={`${config.baseUrl}`} element={<Home />} />
          <Route path={`${config.baseUrl}login`} element={<Login />} />

          <Route path={`${config.baseUrl}dashboard`} element={<ProtectedRoute><AdminLayout><Dashboard /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}staffDashboard`} element={<ProtectedRoute><AdminLayout><StaffDashboard /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}profile`} element={<ProtectedRoute><AdminLayout><Profile /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}changePassword`} element={<ProtectedRoute><AdminLayout><ChangePassword /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}ForgotPassword`} element={<ForgotPassword />} />
          <Route path={`${config.baseUrl}UserPermissions`} element={<ProtectedRoute><AdminLayout><UserPermissions /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}duty-schedule`} element={<ProtectedRoute><AdminLayout><DutySchedule /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}rolesPermission`} element={<ProtectedRoute><AdminLayout><RolesPermission /></AdminLayout></ProtectedRoute>} />
          
          
          <Route path={`${config.baseUrl}department-list`} element={<ProtectedRoute><AdminLayout><DepartmentList /></AdminLayout></ProtectedRoute>} />

          <Route path={`${config.baseUrl}form-category-list`} element={<ProtectedRoute><AdminLayout><FormCategoryList /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}add-form-category`} element={<ProtectedRoute><AddFormCategory /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-form-category/:id`} element={<ProtectedRoute><EditFormCategory /></ProtectedRoute>} />

          <Route path={`${config.baseUrl}add-department`} element={<ProtectedRoute><AddDepartment /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-department/:id`} element={<ProtectedRoute><EditDepartment /></ProtectedRoute>} />

          <Route path={`${config.baseUrl}department-forms-list`} element={<ProtectedRoute><AdminLayout><DepartmentFormsList /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}add-department-form`} element={<ProtectedRoute><AddDepartmentForm /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-department-forms/:id`} element={<ProtectedRoute><EditDepartmentForms /></ProtectedRoute>} />          

          <Route path={`${config.baseUrl}staff-management`} element={<ProtectedRoute><AdminLayout><StaffManagement /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}add-staff`} element={<ProtectedRoute><AddStaff /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-staff/:id`} element={<ProtectedRoute><EditStaff /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-staff-duties/:id`} element={<ProtectedRoute><EditStaffDuties /></ProtectedRoute>} />

          <Route path={`${config.baseUrl}staff-duties/:id`} element={<ProtectedRoute><StaffDutyCalender /></ProtectedRoute>} />

          <Route path={`${config.baseUrl}add-patientCredentials`} element={<ProtectedRoute><AddPatientCredentials /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}patientCredentials`} element={<ProtectedRoute><PatientCredentials /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-patientCredentials/:id`} element={<ProtectedRoute><EditPatientCredentials /></ProtectedRoute>} />


          <Route path={`${config.baseUrl}user-group`} element={<ProtectedRoute><AdminLayout><UserGroup /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}add-user-group`} element={<ProtectedRoute><AddUserGroup /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-user-group/:id`} element={<ProtectedRoute><EditUserGroup /></ProtectedRoute>} />

          <Route path={`${config.baseUrl}cpt-master`} element={<ProtectedRoute><CPTMaster /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}drug-master`} element={<ProtectedRoute><DrugMaster /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}hcpcs-master`} element={<ProtectedRoute><HCPCSMaster /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}icd10-master`} element={<ProtectedRoute><ICD10Master /></ProtectedRoute>} />

          <Route path={`${config.baseUrl}master-data`} element={<ProtectedRoute><AdminLayout><MasterData /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}add-master`} element={<ProtectedRoute><AddMaster /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-master/:id`} element={<ProtectedRoute><EditMaster /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}edit-table/:id/:tableName`} element={<ProtectedRoute><EditTableData /></ProtectedRoute>} />

          <Route path={`${config.baseUrl}accessForms/:formCategoryId`} element={<ProtectedRoute><AccessForms /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}staffDepartmentFormsList/:formTypeId/:isPatiendSearch`} element={<ProtectedRoute><StaffDepartmentFormsList /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}webforms`} element={<ProtectedRoute><AdminLayout><Webform /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}createWebForm`} element={<ProtectedRoute><CreateWebForm /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}viewFormDataList/:id/:name`} element={<ProtectedRoute><ViewFormDataList /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}viewFormReportList/:id/:name/:isPatiendSearch`} element={<ProtectedRoute><ViewFormReportList /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}ViewFormData/:recordId/:id/:subFormId/:name`} element={<ProtectedRoute><ViewFormData /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}ViewMasterDataList/:id/:name`} element={<ProtectedRoute><AdminLayout><ViewMasterDataList /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}formDetails/:id`} element={<ProtectedRoute><FormDetails /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}form-submission/:formId/:id`} element={<ProtectedRoute><FormSubmission /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}editForm-submission/:formId/:recordId/:name`} element={<ProtectedRoute><EditFormData /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}copyTemplate/:formId/:recordId/:name`} element={<ProtectedRoute><CopyTemplate /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}viewRecord/:formId/:recordId/:name`} element={<ProtectedRoute><ViewRecord /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}viewRecord/:formId/:recordId/:name/:isPatiendSearch`} element={<ProtectedRoute><ViewRecord /></ProtectedRoute>} /> 
          <Route path={`${config.baseUrl}viewTreatment/:formId/:recordId/:formName`} element={<ProtectedRoute><ViewTreatment /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}editWebForm/:id`} element={<ProtectedRoute><EditWebForm /></ProtectedRoute>} />

          <Route path={`${config.baseUrl}treatments/:treatmentFormId/:formId/:referenceRecordId`} element={<ProtectedRoute><Treatments /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}reports/:id`} element={<ProtectedRoute><Reports /></ProtectedRoute>} />
          {/* For Sketch Draw */}
          <Route path={`${config.baseUrl}Sketch`} element={<ProtectedRoute><Sketch /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}logHistory`} element={<ProtectedRoute><AdminLayout><LogHistory /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}PatientDetails/:id/:patientId`} element={<ProtectedRoute><PatientData /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}personal-details/:id/:formId/:formName`} element={<ProtectedRoute><PersonalDetails /></ProtectedRoute>} />
          
          <Route path={`${config.baseUrl}calendar`} element={<ProtectedRoute><CalendarComponent /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}form-submission/:formId/:id/:patientId`} element={<ProtectedRoute><FormSubmission /></ProtectedRoute>} />
          <Route path={`${config.baseUrl}form-submission/:formId/:id/:patientId/:status`} element={<ProtectedRoute><FormSubmission /></ProtectedRoute>} />


          <Route path={`${config.baseUrl}cardReader`} element={<ProtectedRoute><AdminLayout><CardReaderComponent /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}notifications`} element={<ProtectedRoute><AdminLayout><NotificationComponent /></AdminLayout></ProtectedRoute>} />
          <Route path={`${config.baseUrl}reportslist`} element={<ProtectedRoute><AdminLayout><ReportsList /></AdminLayout></ProtectedRoute>} />

        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
