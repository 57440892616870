import React, { useEffect } from 'react';
import { Image as KonvaImage } from 'react-konva'; 
import useImage from 'use-image';

const URLImage = ({ src, onLoad, onError }) => {
    const [image, status] = useImage(src, 'anonymous');
    useEffect(() => {
        if (status === 'loaded') {
            onLoad();
        } else if (status === 'failed') {
            onError();
        }
    }, [status, onLoad, onError]);

    return status === 'loaded' ? <KonvaImage image={image} /> : null; 
};

export default URLImage;
