import React, { useState, useEffect, useRef } from 'react';
import '../componentCss/webform.css';
import '../componentCss/sidebar.css';
import '../componentCss/userGroup.css';
import { getUserGroupListAction, getGroupListWithPermissionAction, updateGroupsPermissionAction } from '../../Action/admin.action';
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css"; import { Link } from 'react-router-dom';
import config from '../../config/config';
import DataTable from 'react-data-table-component';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { MdEdit } from 'react-icons/md';
import { decryptData } from './decrypt'
import { IoPerson, IoPrintSharp } from "react-icons/io5";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

const RolesPermission = () => {
  const [userGroupList, setUserGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [staffId, setStaffId] = useState(0);
  const [formList, setFormList] = useState([]);
  const [isModelOpen, setIsOpen] = useState(false);
  const [isLaoding, setisLaoding] = useState(0);
  const [staffIdPerson, setStaffIdPerson] = useState(0);
  const [isModelOpenPerson, setIsOpenPerson] = useState(false);
  const allDataRef = useRef();
  const singleRowRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredFormList = formList.filter((data) =>
    (data.name && data.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (data.departmentName && data.departmentName.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  };
  const handleClosePerson = () => setIsOpenPerson(false);

  const handleOpen = (id) => {
    setStaffId(id);
    getFormListAPI(id);
    setIsOpen(true);
  };

  let submitPermissionForm = async (e) => {
    e.preventDefault();
    setisLaoding(1);
    let res = await updateGroupsPermissionAction({
      roleId: staffIdPerson,
      formsPermission: formList,
    });
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        setisLaoding(0);
        setIsOpenPerson(false);
      }, 2000);
    } else {
      toast.error(res.msg);
    }
  }


  let handleChange = (permissionType, formId) => {
    setFormList((prevForms) => {
      return prevForms.map((form) => {
        if (form.id === formId) {
          if (permissionType == "isAccess") {
            return {
              ...form,
              isAccess: !form.isAccess,
            };
          } else if (permissionType == "isEdit") {
            return {
              ...form,
              isEdit: !form.isEdit,
            };
          } else if (permissionType == "isDelete") {
            return {
              ...form,
              isDelete: !form.isDelete,
            };
          }
        }
        return form;
      });
    });
  };


  const handleClose = () => setIsOpen(false);


  let getFormListAPI = async (userId) => {
    let res = await getGroupListWithPermissionAction({ roleId: userId });
    if (res.success) {
      setFormList(decryptData(res.data));
    }
  };

  useEffect(() => {
    getUserGroupListAPI();
  }, []);

  useEffect(() => {
    setSelectedRow(null);
  }, [currentPage]);

  const getUserGroupListAPI = async () => {
    let res = await getUserGroupListAction();
    setIsLoading(false);
    if (res.success) {
      setUserGroupList(decryptData(res.data));
    }
  };

  const handleOpenPerson = (id) => {
    setStaffIdPerson(id);
    getFormListAPI(id);
    setIsOpenPerson(true);
  };

  console.log(currentPage * 15)
  const columns = [
    {
      name: '#',
      cell: (row, index, column, id) => {
        return index + 1 + currentPage * rowsPerPage;
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      width: "20%"
    },

    {
      name: 'Action',
      selector: row => (
        <>

          <Link onClick={() => handleOpen(row.id)} title="Role">
            <FaEye fill='#434544' size={20} />

          </Link> 
          &nbsp;
          <Link onClick={() => handleOpenPerson(row.id)} title="Role">
            <IoPerson fill='#434544' size={20} />
          </Link>
          {/* &nbsp;
                    &nbsp;
                    <span title='Print' style={{ cursor: 'pointer' }} onClick={() => handlePrint(row)}> <IoPrintSharp fill='#434544' size={25}/></span> */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "20%"

    }
  ];




  return (
    <>
      {/* <Dashboardheader /> */}
      {/* <ToastContainer /> */}
      {/* <div className="wrapper">
                <Sidebar /> */}
      {/* <div id="content"> */}
      <Container fluid className="p-4">
        <Row>
          <Col lg={12} md={12} sm={12} className="">
            <div className="innerhead mb-4">
              <h5 className='headtitle'>Roles Permission</h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} className="mt-3">


            <div className='maintable table-responsive'>
              <h5 className=''>Roles List</h5>
              {isLoading ?
                <div style={{ textAlign: 'center' }}>
                  <img style={{ width: '40px' }} src='assets/images/loading.gif' alt='loading' />
                </div>
                :
                <DataTable
                  columns={columns}
                  data={userGroupList}
                  pagination
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Row per page:',
                    rangeSeparatorText: 'out of',
                  }}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                />
              }
            </div>

            {/*------------------------------- Section For Showing Single Print--------------------------------------------------- */}
            <div style={{ display: 'none' }}>
              <div className="container mt-5" ref={singleRowRef}>
                {selectedRow && (
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h2 className="card-title text-center mb-4">Role Details</h2>
                      <div className="list-group">
                        <div className="list-group-item">
                          <strong>ID:</strong> {selectedRow.id}
                        </div>
                        <div className="list-group-item">
                          <strong>Name:</strong> {selectedRow.name}
                        </div>
                        <div className="list-group-item">
                          <strong>Description:</strong> {selectedRow.description}
                        </div>
                        <div className="list-group-item">
                          <strong>Code:</strong> {selectedRow.code}
                        </div>
                        <div className="list-group-item">
                          <strong>Status:</strong> {selectedRow.status == 1 ? 'Active' : 'Inactive'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/*------------------------------- Section For Showing All Data For Print--------------------------------------------------- */}


          </Col>
        </Row>
      </Container>


      <Modal
        show={isModelOpen}
        onHide={() => handleClose(false)}
        dialogClassName="modal-90w modal-right"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Permissions</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Form>
            <Row className="mb-4">
              <div className="col-md-3">
                <Form.Label>
                  <b> Form Name </b>
                </Form.Label>{" "}
                <br />
              </div>


              <div className="col-md-3">
                <Form.Label>
                  <b> Department Name </b>
                </Form.Label>{" "}
                <br />
              </div>

              <div className="col-md-2">
                <Form.Label>
                  <b>Access</b>
                </Form.Label>
              </div>

              <div className="col-md-2">
                <Form.Label>
                  <b> Edit </b>
                </Form.Label>
              </div>

              <div className="col-md-2">
                <Form.Label>
                  {" "}
                  <b> Delete </b>
                </Form.Label>
              </div>
            </Row>
            <hr />

            {formList.length > 0 ? (
              formList.some(data => data.isAccess === 1 || data.isEdit === 1 || data.isDelete === 1) ? (
                formList.map((data) => (
                  <Row className="mb-4" key={data.id} style={{ display: data.isAccess === 1 || data.isEdit === 1 || data.isDelete === 1 ? 'flex' : 'none' }}>
                    <div className="col-md-3">{data.name}</div>
                    <div className="col-md-3">{data.departmentName}</div>

                    <div className="col-md-2">
                      <div>
                        {data.isAccess === 1 ? <p style={{ color: "green" }}>Yes</p> : <p style={{ color: "red" }}>No</p>}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div>
                        {data.isEdit === 1 ? <p style={{ color: "green" }}>Yes</p> : <p style={{ color: "red" }}>No</p>}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div>
                        {data.isDelete === 1 ? <p style={{ color: "green" }}>Yes</p> : <p style={{ color: "red" }}>No</p>}
                      </div>
                    </div>
                  </Row>
                ))
              ) : (
                <Row className="mb-4">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    No Data Found
                  </div>
                </Row>
              )
            ) : ""}


          </Form>
        </Modal.Body>
      </Modal>



      <Modal
        show={isModelOpenPerson}
        onHide={() => handleClosePerson(false)}
        dialogClassName="modal-90w modal-right"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Permissions</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Form>
            <Row className="mb-4">
              <div className="col-md-3">
                <Form.Label>
                  <b> Form Name </b>
                </Form.Label>{" "}
                <br />
              </div>

              <div className="col-md-3">
                <Form.Label>
                  <b> Department Name </b>
                </Form.Label>{" "}
                <br />
              </div>

              <div className="col-md-2">
                <Form.Label>
                  <b>Access</b>
                </Form.Label>
              </div>

              <div className="col-md-2">
                <Form.Label>
                  <b> Edit </b>
                </Form.Label>
              </div>

              <div className="col-md-2">
                <Form.Label>
                  {" "}
                  <b> Delete </b>
                </Form.Label>
              </div>
            </Row>
            <hr />
            <Row className="mb-4">
              <div className="col-md-12">
                <Form.Control
                  type="text"
                  placeholder="Search Form Name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </Row>
            {filteredFormList.length > 0
              ? filteredFormList.map((data) => (
                <>
                  <Row className="mb-4">
                    <div className="col-md-3">{data.name}</div>
                    <div className="col-md-3">{data.departmentName}</div>

                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        name="isAccess"
                        onChange={() =>
                          handleChange("isAccess", data.id)
                        }
                        checked={data.isAccess == 1 ? "checked" : ""}
                      />
                    </div>

                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        name="isEdit"
                        onChange={() => handleChange("isEdit", data.id)}
                        checked={data.isEdit == 1 ? "checked" : ""}
                      />
                    </div>

                    <div className="col-md-2">
                      <input
                        type="checkbox"
                        name="isDelete"
                        onChange={() =>
                          handleChange("isDelete", data.id)
                        }
                        checked={data.isDelete == 1 ? "checked" : ""}
                      />
                    </div>
                  </Row>
                  <hr />
                </>
              ))
              : <p style={{ textAlign: "center" }}>No Data Found</p>}

            <Row className="mb-4">
              <Form.Group as={Col}>
                {isLaoding ? (
                  <>
                    <button
                      disabled
                      className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                    >
                      Loading{" "}
                      <img
                        height="20"
                        width="20px"
                        src="assets/images/loading.gif"
                      />
                    </button>
                  </>
                ) : (
                  <button
                    type="submit"
                    onClick={submitPermissionForm}
                    className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                  >
                    Submit
                  </button>
                )}
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* </div> */}
      {/* </div> */}
    </>
  );
}

export default RolesPermission;