import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import '../componentCss/webform.css';
import '../componentCss/sidebar.css';
import { getMasterListAction, getMasterTableDataAction } from '../../Action/admin.action';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import DataTable from 'react-data-table-component';
import ReactToPrint from 'react-to-print';
import { MdEdit } from 'react-icons/md';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {decryptData} from './decrypt'

const Masterdata = () => {
    const [masterList, setMasterData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(0);
    const allDataRef = useRef();

    const handlePageChange = page => {
        setCurrentPage(page - 1);
    };

    useEffect(() => {
        getMasterList();
    }, []);

    let getMasterList = async () => {
        let res = await getMasterListAction();
        setIsLoading(false)
        if (res.success) {
            setMasterData(decryptData(res.data));
        }
    }

    const downloadSampleFile = async (dbTable, id) => {
        let getTableFields = await getMasterTableDataAction({ 'id': id });
        if (getTableFields.success) {
            let fields = getTableFields.data.fieldsList;
            const fieldsObj = {};
            const transformed = [];
            fields.forEach((item) => {
                if (item.fieldName != 'id') {
                    fieldsObj[item.fieldName] = '';
                }
            });
            transformed.push(fieldsObj);
            const worksheet = XLSX.utils.json_to_sheet(transformed);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            saveAs(blob, `${dbTable}.xlsx`);
        }
    }

    const columns = [
        {
            name: '#',
            cell: (row, index, column, id) => {
                return (index + 1) + (currentPage * 10);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Table Name',
            selector: row => `${row.dbTable}`,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <Link to={`${config.baseUrl}edit-master/${row.id}`} >
                        <span ><MdEdit fill='#434544' size={25} /></span>
                    </Link> &nbsp;

                    <button onClick={() => downloadSampleFile(row.dbTable, row.id)} className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Download Sample Excel</button>
                </>
            ),
            sortable: true,
        }
    ];

    return (
        <>
            {/* <Dashboardheader /> */}
            {/* <ToastContainer /> */}
            {/* <div className="wrapper"> */}
            {/* <Sidebar /> */}
            {/* <div id="content"> */}
            <Container fluid className="p-4">
                <Row>
                    <Col lg={12} md={12} sm={12} className="">
                        <div className="innerhead mb-4">
                            <h5 className='headtitle'>Master Table List</h5>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg={12} md={12} sm={12} className="mt-3">
                        <div style={{ float: 'right' }}>
                            <Link to={`${config.baseUrl}add-master`} >
                                <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Add+</button>
                            </Link>
                        </div>
                        <ReactToPrint
                            trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Print</button>}
                            content={() => allDataRef.current}
                        />

                        <div className='maintable table-responsive' ref={allDataRef}>
                            <h5 className=''>Master Tables </h5>
                            {isLoading ?
                                <div style={{ textAlign: 'center' }}>
                                    <img style={{ width: '40px' }} src='assets/images/loading.gif' />
                                </div>
                                :
                                <DataTable
                                    columns={columns}
                                    data={masterList}
                                    pagination
                                    onChangePage={handlePageChange}
                                />
                            }
                        </div>
                    </Col>
                </Row>

            </Container>
            {/* </div> */}
            {/* </div > */}
        </>
    )
}

export default Masterdata;
