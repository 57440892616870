import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { commonService } from "../../services/common.services";
import { apiConstants } from "../../constants/api.constant";
// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import Cookies from "js-cookie";



// update Notification Details 

export const updateFormDataStatus = createAsyncThunk('user/updateFormDataStatus', async (data) => {
 
  const response = await commonService.putWithToken(apiConstants.UPDATE_FORMDATA_STATUS ,data); 
  if(response && response.data && response.data.success){
    
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
 
  return response.data; 
});


// Get log history

export const getFormLogHistory = createAsyncThunk('form/getFormLogHistory', async (data) => {
 
  const response = await commonService.withToken(apiConstants.GETFORMLOGHISTORY ,data); 
  if(response && response.data && response.data.success){
    
  }
  return response.data; 
});


const formDataSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    error: null,
    updateFormDataStatus: null,
    getFormLogHistroyData: null
  
  },
  reducers: {
    changeValue: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
     
      .addCase(updateFormDataStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFormDataStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.updateFormDataStatus = action.payload;
      })
      .addCase(updateFormDataStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFormLogHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFormLogHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.getFormLogHistroyData = action.payload;
      })
      .addCase(getFormLogHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});


export const { changeValue } = formDataSlice.actions;

export default formDataSlice.reducer 