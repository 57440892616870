import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAppointmentsByDocId } from '../../../Action/admin.action';

const ModalForm = ({ show, handleClose, handleSaveAppointment, setShow, uniqueId, doctorList }) => {
  const [doctorData, setDoctorData] = useState([]);
  const [appointmentError, setAppointmentError] = useState('');

  const validationSchema = Yup.object({
    doctorId: Yup.number().required('Doctor name is required'),
    date: Yup.date().required('Date is required'),
    time: Yup.string().required('Time is required'),
    description: Yup.string().required('Description is required'),
  });

  const formik = useFormik({
    initialValues: {
      doctorId: 0,
      date: '',
      time: '12:00',
      description: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Check for appointment conflict
      const conflict = doctorData.some(appointment =>
        appointment.date === values.date && appointment.time === values.time
      );

      if (conflict) {
        setAppointmentError('The selected date and time are already booked for this doctor.');
      } else {
        values.patientUniqueId = uniqueId;
        handleSaveAppointment(values);
      }
    },
  });

  useEffect(() => {
    if (formik.values.doctorId) {
      getAppointmentByDocId();
    }
  }, [formik.values.doctorId]);

  const getAppointmentByDocId = async () => {
    let obj = {
      doctorId: formik.values.doctorId,
    };

    let res = await getAppointmentsByDocId(obj);
    if (res.success) {
      setDoctorData(res.data);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton onClick={() => setShow(!show)}>
        <Modal.Title>Schedule Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="formDoctorName">
            <Form.Label>Doctor Name</Form.Label>
            <Form.Control
              as="select"
              name="doctorId"
              value={formik.values.doctorId}
              onChange={(e) => {
                formik.setFieldValue('doctorId', parseInt(e.target.value, 10));
              }}
              isInvalid={!!formik.errors.doctorId && formik.touched.doctorId}
            >
              <option value="">Select a doctor</option>
              {doctorList.map((doctor, index) => (
                <option key={index} value={parseInt(doctor.id)}>
                  {doctor.firstName + ' ' + doctor.lastName}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.doctorId}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={formik.values.date}
              onChange={formik.handleChange}
              isInvalid={!!formik.errors.date && formik.touched.date}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.date}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formTime">
            <Form.Label>Time</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="time"
                name="time"
                value={formik.values.time}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.time && formik.touched.time}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.time}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              isInvalid={!!formik.errors.description && formik.touched.description}
              placeholder="Enter a description"
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.description}
            </Form.Control.Feedback>
          </Form.Group>
          {appointmentError && <p className="text-danger">{appointmentError}</p>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className='btn-sm btn-secondary-btn3' type="submit" onClick={formik.handleSubmit}>
          Save Appointment
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalForm;
