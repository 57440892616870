import axios from 'axios'
import Cookies from 'js-cookie';
import config from './config';


// section For get Browser Details-----------------
const userAgent = navigator.userAgent;
// let browserName = 'Unknown';
// if (userAgent.indexOf('Firefox') > -1) {
//     browserName = 'Mozilla Firefox';
// } else if (userAgent.indexOf('SamsungBrowser') > -1) {
//     browserName = 'Samsung Internet';
// } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
//     browserName = 'Opera';
// } else if (userAgent.indexOf('Trident') > -1) {
//     browserName = 'Microsoft Internet Explorer';
// } else if (userAgent.indexOf('Edge') > -1) {
//     browserName = 'Microsoft Edge';
// } else if (userAgent.indexOf('Chrome') > -1) {
//     browserName = 'Google Chrome';
// } else if (userAgent.indexOf('Safari') > -1) {
//     browserName = 'Apple Safari';
// }
// Section End=---------------------

const serverPath = config.serverPath;

export const request = (path, data, method) => {
    const loginData = (!Cookies.get('loginSuccessNeurulaHealth')) ? [] : JSON.parse(Cookies.get('loginSuccessNeurulaHealth'));


    var options = {
        method: method,
        url: `${serverPath}/${path}`,
        headers: {
            'Content-Type': 'application/json'
        },
        dataType: 'json',
    };
    if (loginData) {
        options.headers['Authorization'] = loginData?.authToken
        options.headers['browserType'] = userAgent
    }
    if (method === 'GET') {
        options['params'] = data
    } else {
        options['data'] = data
    }
    let res = axios(options)
    res.then(res1 => { })
    return res
}

export const requestFormData = (path, data, method) => {
    const loginData = (!Cookies.get('loginSuccessNeurulaHealth')) ? [] : JSON.parse(Cookies.get('loginSuccessNeurulaHealth'));

    var form_data = new FormData();
   
    for (var key in data) {        
        form_data.append(key, data[key]);
    }
    console.log('form_data', form_data);
    var options = {
        method: method,
        url: `${serverPath}/${path}`,
        data: form_data,
        headers:
        {
            authorization: loginData?.authToken,
            browserType:userAgent
        },
    };
    let res = axios(options);
    res.then(res1 => { })
    return res
}

export const requestFormDataForImage = (path, data, method) => {
    const loginData = (!Cookies.get('loginSuccessNeurulaHealth')) ? [] : JSON.parse(Cookies.get('loginSuccessNeurulaHealth'));

    var form_data = new FormData();
    for (var key in data.formValues) {        
        form_data.append(key, data.formValues[key]);
    }

    var options = {
        method: method,
        url: `${serverPath}/${path}`,
        data: form_data,
        browserType:userAgent,
        headers: {
            authorization: loginData?.authToken,
            browserType:userAgent
        },   
    };
    let res = axios(options);
    res.then(res1 => { })
    return res
}

export const requestFormDataWithImage = (path, data, method) => {
    const loginData = (!Cookies.get('loginSuccessNeurulaHealth')) ? [] : JSON.parse(Cookies.get('loginSuccessNeurulaHealth'));


console.log(loginData,'<>')
    var options = {
        method: method,
        url: `${serverPath}/${path}`,
        data: data,
        headers:
        {
            authorization: loginData?.authToken,
            browserType:userAgent
        },
    };
    let res = axios(options);
    res.then(res1 => { })
    return res
}

export const postRequest = async (path, data) => await request(path, data, 'POST')
export const getRequest = async (path, data) => await request(path, data, 'GET')
export const putRequest = async (path, data) => await request(path, data, 'PUT')
export const deleteRequest = async (path, data) => await request(path, data, 'DELETE')
export const postRequestFormData = async (path, data) => await requestFormData(path, data, 'POST')
export const postrequestFormDataForImage = async (path, data) => await requestFormDataForImage(path, data, 'POST')
export const postRequestFormDataWithImage = async (path, data) => await requestFormDataWithImage(path, data, 'POST')
