import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { addPatientSchema } from "../../validations/patientSchema";
import {
  addPatientAction,
  getGroupTypesAction,
} from "../../Action/admin.action";
import { decryptData } from "./decrypt";

const AddPatientCredentials = () => {
  const navigate = useNavigate();
  const [formData, setForm] = useState({
    userName: "",
    password: "",
    confirmPassword: "",
    MRNno: "",
  });
  const [userGroup, setUserGroup] = useState([]);
  const [isLaoding, setisLaoding] = useState(0);

  useEffect(() => {}, []);

  let getGroupTypesAPI = async (values) => {
    let res = await getGroupTypesAction(values);
    if (res.success) {
      setUserGroup(decryptData(res.data));
    }
  };

  let handleSubmit = async (values) => {
    console.log(values, "values");
    let res = await addPatientAction(values);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseUrl}staff-management`);
      }, 2000);
    } else {
      setisLaoding(0);
      toast.error(res.msg);
    }
  };

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">Add Patient Credentials</h5>
                </div>
                <Link to={`${config.baseUrl}patientCredentials`}>
                  <span style={{ float: "right" }}>
                    Back <BsArrowLeftCircleFill />
                  </span>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="formDiv">
                  <Formik
                    initialValues={formData}
                    validationSchema={addPatientSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      touched,
                      setFieldValue,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit} autocomplete="off">
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>User name</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="userName"
                              onChange={handleChange}
                              value={values.userName}
                              placeholder="Please enter User name"
                            />

                            {errors.userName && touched.userName && (
                              <div className="text-danger">
                                {errors.userName}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>MRN No.</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="MRNno"
                              onChange={handleChange}
                              value={values.MRNno}
                              placeholder="Please enter Unique MRN No"
                              autocomplete="off"
                            />

                            {errors.MRNno && touched.MRNno && (
                              <div className="text-danger">{errors.MRNno}</div>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Password</Form.Label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              onChange={handleChange}
                              value={values.password}
                              placeholder="Please enter password"
                            />

                            {errors.password && touched.password && (
                              <div className="text-danger">
                                {errors.password}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Confirm Password</Form.Label>
                            <input
                              className="form-control"
                              type="password"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              placeholder="Please enter confirm password"
                            />

                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <div className="text-danger">
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            {isLaoding ? (
                              <>
                                <button
                                  disabled
                                  className="btn-sm btn btn-primary"
                                >
                                  Loading{" "}
                                  <img
                                    height="20"
                                    width="20px"
                                    src="assets/images/loading.gif"
                                  />
                                </button>
                              </>
                            ) : (
                              <button
                                type="submit"
                                className="btn-sm btn btn-primary"
                              >
                                Submit
                              </button>
                            )}
                          </Form.Group>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AddPatientCredentials;
