import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../componentCss/webform.css";
import { useNavigate, useParams } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import { capitalizeFirstWord } from "../../config/conversion";
import "../componentCss/sidebar.css";
import {
  getTableContentdetailByIdAction,
  updateTableContentDetailsByIdAction,
} from "../../Action/admin.action";
// import { ToastContainer, toast } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';

import "react-toastify/dist/ReactToastify.css";

const Webform = () => {
  const { id, tableName } = useParams();
  const [validationMessage, setValidationMessage] = useState("");
  const [editFieldList, setEditFieldList] = useState({});

  useEffect(() => {
    getTableData();
  }, []);

  let getTableData = async () => {
    let res = await getTableContentdetailByIdAction({
      id: id,
      tableName: tableName,
    });
    if (res.success) {
      setEditFieldList(res.data);
    }
  };

  let handleChange = (key, value) => {
    console.log(key, value);
    setEditFieldList((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const validateForm = () => {
    for (let key in editFieldList) {
      if (key !== "id" && !editFieldList[key]) {
        console.log(key);
        return `${key} should not be empty.`;
      }
    }
    return "";
  };

  let handleSubmit = async (event) => {
    event.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setValidationMessage(validationError);
    } else {
      setValidationMessage("");
      let data = {
        ...editFieldList,
      };
      data.id = id;
      data.tableName = tableName;
      let res = await updateTableContentDetailsByIdAction(data);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.msg);
      }
    }
  };
  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle"> 
                    Edit Data of {capitalizeFirstWord(tableName)}
                  </h5>
                </div>
              </Col>
            </Row>

            <form onSubmit={handleSubmit} className="mt-4">
              <Row>
                {Object.entries(editFieldList).map(
                  ([key, value], index) =>
                    key !== "id" &&
                    key !== "status" && (
                      <Col lg={6} md={6} sm={6} className="mt-0">
                        <div className="form-inline mt-2" key={index}>
                          <div className="row">
                            <div className="col-md-10">
                              <span>{capitalizeFirstWord(key)}</span>
                              &nbsp;
                              <input
                                type="text"
                                className="form-control"
                                name={key}
                                value={value}
                                onChange={(e) =>
                                  handleChange(key, e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                )}
                <br />
                {validationMessage && (
                  <div className="alert alert-danger">
                    {validationMessage}
                  </div>
                )}
                <div className="button-section mt-2">
                  <Button
                    variant="secondary-btn3"
                    type="submit"
                    className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                  >
                    Update
                  </Button>
                </div>
              </Row>
            </form>
          </Container>
        </div>
      </div >
    </>
  );
};

export default Webform;
