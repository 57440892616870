import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import { fromEvent } from 'file-selector';
import "../componentCss/webform.css";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
import "../componentCss/Sketch.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Sketch = () => {
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [color, setColor] = useState('#000000');
    const [lineWidth, setLineWidth] = useState(3);
    const [image, setImage] = useState(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = 800 * 2;
        canvas.height = 300 * 2;
       
        canvas.style.height = `${300}px`;

        const context = canvas.getContext('2d');
        context.scale(2, 2);
        context.lineCap = 'round';
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        contextRef.current = context;

        if (image) {
            const img = new Image();
            img.src = image;
            img.onload = () => {
                context.drawImage(img, 0, 0, canvas.width, canvas.height);
            };
        }
    }, [color, lineWidth, image]);

    const startDrawing = ({ nativeEvent }) => {
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        setIsDrawing(true);
    };

    const finishDrawing = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
    };

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) return;
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
    };

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        contextRef.current.clearRect(0, 0, canvas.width, canvas.height);
    };

    const handleImageUpload = async (event) => {
        const files = await fromEvent(event);
        if (files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = () => setImage(reader.result);
            reader.readAsDataURL(file);
        }
    };
    const handleSaveImage = () => {
        const canvas = canvasRef.current;
        const dataUrl = canvas.toDataURL();
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'edited_image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <>
            {/* <Dashboardheader />
            <ToastContainer /> */}
            <div className="wrapper">
                {/* <Sidebar /> */}
                <div id="content" className=" sketchdiv">
                    <Container fluid >
                        <Row>
                            <Col  className="">
                                <h5 className="headtitle">Sketch </h5>
                                <div className="controls">
                                    <label htmlFor="uploadImage" className="imageUploadLabel">
                                        Upload Image
                                        <input
                                            type="file"
                                            id="uploadImage"
                                            style={{ display: 'none' }}
                                            onChange={handleImageUpload}
                                        />
                                    </label>
                                    
                                    {/* <button className="imageUploadLabel" onClick={handleSaveImage}>
                                        Save
                                    </button> */}
                                    <button className="imageUploadLabel" onClick={clearCanvas}>
                                        Clear
                                    </button>
                                </div>
                                <div className="SketchBox">
                                    <canvas
                                        ref={canvasRef}
                                        onMouseDown={startDrawing}
                                        onMouseUp={finishDrawing}
                                        onMouseMove={draw}
                                        onMouseLeave={finishDrawing}
                                        className="canvasmain"
                                        width={1000} 
                                        height={400} // Manually set canvas height
                                        style={{ border: '1px solid #000' }} // Optional: Add styling
                                      
                                        // style={{ border: '1px solid #000', cursor: 'crosshair' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Sketch;
