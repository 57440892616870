import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import '../componentCss/webform.css';
import '../componentCss/sidebar.css';
import { getDepartmentListAction } from '../../Action/admin.action';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import DataTable from 'react-data-table-component';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { MdEdit } from 'react-icons/md';
import {decryptData} from './decrypt'

const DepartmentList = () => {
    const [departmentList, setDepartmentList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const allDataRef = useRef();

    const handlePageChange = page => {
        setCurrentPage(page - 1);
    };
    

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  };

    useEffect(() => {
        getDepartmentList();
    }, []);

    let getDepartmentList = async () => {
        let res = await getDepartmentListAction();
        setIsLoading(false)
        if (res.success) {
            setDepartmentList(decryptData(res.data));
        }
    }

    const columns = [
        {
            name: '#',
            cell: (row, index, column, id) => {
                return (index + 1) + (currentPage * rowsPerPage);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                row.status == 1 ?
                    <p className='text-primary mb-0'>
                        Active
                    </p>
                    :
                    <span className='text-danger'>
                        Inactive
                    </span>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <Link to={`${config.baseUrl}edit-department/${row.id}`} >
                    <span ><MdEdit fill='#434544' title='Edit' size={25} /></span>
                    </Link>
                </>
            ),
            sortable: true,
        }
    ];
    const printcolumns = [
        {
            name: '#',
            cell: (row, index, column, id) => {
                return (index + 1) + (currentPage * 10);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                row.status == 1 ?
                    <p className='text-primary'>
                        Active
                    </p>
                    :
                    <span className='text-danger'>
                        Inactive
                    </span>
            ),
            sortable: true,
        },
    ];

    const printAllData = useReactToPrint({
        content: () => allDataRef.current,
    });
    return (
        <>
            {/* <Dashboardheader />
            <div className="wrapper">
                <Sidebar /> */}
                {/* <div id="content"> */}
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Department Management</h5>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-3">
                                <div style={{ float: 'right' }}>
                                    <Link to={`${config.baseUrl}add-department`} >
                                        <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Add+</button>
                                    </Link>
                                </div>
                                <ReactToPrint
                                    trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Print</button>}
                                    content={printAllData}
                                />
                                <div className='maintable table-responsive' >
                                    <h5 className=''>Department List </h5>
                                    {isLoading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: '40px' }} src='assets/images/loading.gif' />
                                        </div>
                                        :
                                        <DataTable
                                            columns={columns}
                                            data={departmentList}
                                            pagination
                                            paginationPerPage={rowsPerPage}
                                            paginationRowsPerPageOptions={[10, 15, 25, 50,100]}
                                            paginationComponentOptions={{
                                              rowsPerPageText: 'Row per page:',
                                              rangeSeparatorText: 'out of',
                                            }}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleRowsPerPageChange}

                                        />
                                    }
                                </div>
                                {/*------------------------------- Section For Showing All Data For Print--------------------------------------------------- */}

                                <div style={{ display: 'none' }}>
                                    <div ref={allDataRef}>
                                        <img src="assets/images/logo.png" alt="logo" className='mainLogo' />
                                        <center>
                                            <h2 className=''> Department List</h2>
                                        </center>
                                        <DataTable
                                            columns={printcolumns}
                                            data={departmentList}
                                            pagination={false}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                {/* </div>
            </div > */}
        </>
    )
}

export default DepartmentList;
