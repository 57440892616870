export const apiConstants = {
    ADMIN_LOGIN: '/adminLogin',
    SMS_VERIFICATION: '/smsVerification',
    GET_STAFF_DETAILS: '/getStaffListById',
    GET_NOTIFICATIONS: '/getNotificaiton',
    UPDATE_NOTIFICATION: '/updateNotification',
    DELETE_NOTIFICATION: '/deleteNotification',
    UPDATE_FORMDATA_STATUS: '/updateAddendumStatus',
    ADD_REPORTS: '/addReport',
    GET_REPORTS: '/getReport',
    ADD_REPORT_REASON: '/addReportReason',
    GETFORMLOGHISTORY: '/getFormLogHistory'

}