import React, { useEffect, useState } from 'react';
import { ListGroup, Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNotifications, getNotifications } from '../../../redux/slices/notificationSlice';
import { FaTrash } from "react-icons/fa";
import DeleteNotificationDialog from '../ModalComponents/DeleteNotificationDialog';
import { decryptData } from '../decrypt';

const NotificationComponent = () => {
  const dispatch = useDispatch()
  const getNotificationData = useSelector((state) => state.notification?.getNotificationData);

  const [notifications, setNotifications] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [notificationId, setNotificationId] = useState(null)

  useEffect(() => {
    if (getNotificationData && getNotificationData.data) {
      setNotifications(decryptData(getNotificationData.data));
    } else {
      getNotificationsAPI();
    }
  }, [getNotificationData]);


  const getNotificationsAPI = async () => {
    const res = await dispatch(getNotifications());
    if (res.payload?.success) {
      setNotifications(decryptData(res.payload.data));
    }
  };

  const handleDelete = (id) => {
    setShowDeleteModal(true)
    setNotificationId(id)
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const handleDeleteNotification = async() => {

    const res = await dispatch(deleteNotifications({id:notificationId}));
    if (res.payload?.success) {
      getNotificationsAPI();
    }
    setShowDeleteModal(!showDeleteModal)
  }
console.log('ppppppp====', notifications);
  return (
    <div className="notification-component">
      <h3 className='m-3'>Notifications</h3>
      <ListGroup>
        {notifications.map((notification, index) => (
          <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
            <div>
              <div>{notification.title}</div>
              <div>{notification.description}</div>
            </div>
            <Button variant="danger" size="sm" onClick={() => handleDelete(notification.id)}>
              <FaTrash />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {showDeleteModal && <DeleteNotificationDialog showDeleteModal={showDeleteModal} handleCloseDelete={handleCloseDelete} handleDeleteNotification={handleDeleteNotification} />}
    </div>
  );
};

export default NotificationComponent;
