import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Card } from "react-bootstrap";
import '../componentCss/webform.css';
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
import '../componentCss/userGroup.css';
import { BsEye, BsCopy } from "react-icons/bs";
import { getpatientDetailsById } from '../../Action/admin.action';
import 'react-toastify/dist/ReactToastify.css';

import { Link, useNavigate, useParams } from 'react-router-dom';
import config from '../../config/config';
import propertise from '../../config/properties';
import { useDispatch, useSelector } from 'react-redux';
// import { getFormLogHistory } from '../../redux/slices/formDataSlice';
import { decryptData } from './decrypt';
import AddendumModal from './ModalComponents/AddendumModal';
// import FormLogHistory from './ModalComponents/formLogHistory';
import { addReportsReason } from '../../redux/slices/reportsSlice';
import toast from 'react-hot-toast';


const PersonalDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let { id, formId, formName } = useParams();
    const [patientData, setPatientData] = useState([]);
    const [formFields, setFieldsData] = useState([]);
    // const [logHistory, setLogHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentRawData, setCurrentRawData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    // const [showModalLogHistory, setShowModalLogHistory] = useState(false)
    // const [formLogHistoryData, setFormLogHistoryData] = useState({});

    // const getLogHistoryData = useSelector((state) => state.user)

    const [status, setStatus] = useState(1)

    useEffect(() => {
        getPatientDataByID();
        // getFormLogHistoryAPI();
    }, []);

    const getPatientDataByID = async () => {
        let data = {
            patientId: id,
            formId: formId
        }
        let res = await getpatientDetailsById(data);

        setIsLoading(false);
        if (res.success) {
            let data = decryptData(res.data)
            console.log('ppppppp',data);
            setFieldsData(data.fields);
            setPatientData(data.records);
        }
    };

    // useEffect(() => {
    //     if (getLogHistoryData && getLogHistoryData.data) {
    //         setLogHistory(decryptData(getLogHistoryData.data));

    //     } else {
    //         getFormLogHistoryAPI();
    //     }
    // }, [getLogHistoryData]);

    // const getFormLogHistoryAPI = async () => {
    //     const res = await dispatch(getFormLogHistory({ 'formId': formId }))

    //     if (res.payload?.success) {
    //         setLogHistory(decryptData(res.payload.data));
    //     }
    // };

    const handleUpdateStatus = async (e, rowData) => {
        if (e.target.value == 4) {
            navigate(`${config.baseUrl}viewTreatment/${rowData.formId}/${rowData.recordId}/${formName}`)
        } else if (e.target.value == 5) {
            navigate(`${config.baseUrl}copyTemplate/${rowData.formId}/${rowData.recordId}/${formName}`)
        } else if (rowData.status == 2) {
            toast.error('Record has been already invalidated.');
        } else if (rowData.status == 3) {
            toast.error('Record has been already addendum.');
        } else {

            if (rowData.remainingHour <= 0 && rowData.isLateEntry == 1) {
                toast.error('Not accessible.')
                return;
            }

            setCurrentRawData(rowData)
            if (e.target.value == 3 || e.target.value == 2) {
                setShowModal(true)
            }
            setStatus(e.target.value)
        }
    }

    const handleClose = () => {
        setShowModal(false)
    }

    // const handleCloseLogHistory = () => {
    //     setShowModalLogHistory(false)
    // }

    const handleSubmit = async (data) => {
        const res = await dispatch(addReportsReason(data))
        setShowModal(!showModal)
        if (res && res.payload && res.payload?.success) {
            toast.success(res.payload?.msg)
            getPatientDataByID();
        }
    }

    // const viewFormLogHistory = (data) => {
    //     setShowModalLogHistory(true)
    //     setFormLogHistoryData(data)
    // }

    return (
        <>
            <Dashboardheader />
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <h4>{formName}</h4>
                                <hr />
                                <br />
                                <Row>
                                    <Col lg={12} md={12} sm={12} className="mt-3">
                                        {isLoading ? (
                                            <div style={{ textAlign: "center" }}>
                                                <img
                                                    style={{ width: "40px" }}
                                                    src="assets/images/loading.gif"
                                                />
                                            </div>
                                        ) : patientData.length > 0 ? (
                                            <div>
                                                <Table hover className='reportTables' style={{ border: '1px solid' }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            {formFields.map((field, i) => (
                                                                i < 4 &&
                                                                    (field.id !== propertise.PatientUniqueId && field.id !== propertise.PatientMrnNumber) ? (
                                                                    <th key={i}>{field.fieldName}</th>
                                                                ) : null
                                                            ))}
                                                            <th>Username</th>
                                                            <th>Datetime</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {patientData.map((rowData, i) => (
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                {formFields.map((field, i) => (
                                                                    i < 4 &&
                                                                    <td>
                                                                        {rowData[field.id]}
                                                                    </td>
                                                                ))}
                                                                <td>{rowData.staff}</td>
                                                                <td>{rowData.datetime}</td>
                                                                <td>
                                                                    {rowData.status === 3 ? <div className='statusAddendum'>ADDENDUM</div> : rowData.status === 2 ? <div className='statusInvalid'>INVALIDATED</div> : <div className='statusActive'>ACTIVE</div>}
                                                                </td>
                                                                <td>
                                                                    <select className='form-control' style={{ width: 'auto' }} defaultValue={rowData.status} onChange={(e) => handleUpdateStatus(e, rowData)}>
                                                                        <option value={1}>Active</option>
                                                                        <option value={4}>View</option>
                                                                        <option value={5}>Copy Paste</option>
                                                                        <option value={2}>Invalid</option>
                                                                        <option value={3}>Addendum</option>
                                                                    </select>
                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </Table>
                                            </div>
                                        ) : (
                                            <p className="text-center">
                                                <img src="assets/images/no-image.png" />
                                            </p>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col className='border p-3 m-2 ' style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', overflowX: 'auto', height: '700px' }}>
                                <Row>
                                    <div className="innerhead mb-4">
                                        <span className="card-headtitle">Form log history</span>
                                    </div>
                                </Row>
                                <Row>
                                    {logHistory.map((event, index) => (
                                        <Card className='mb-1'>
                                            <Card.Body onClick={() => viewFormLogHistory(event)} className='formLogHistoryCard'>
                                                <Card.Title className='custom-card-title'>{event.activityType}</Card.Title>
                                                <Card.Text className='custom-card-text'>{event.dateTime}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Row>
                            </Col> */}
                        </Row>

                    </Container>
                </div>
                {showModal && <AddendumModal showModal={showModal} handleClose={handleClose} handleSubmit={handleSubmit} currentRawData={currentRawData} status={status} formFields={formFields} />}

                {/* {showModalLogHistory && <FormLogHistory showModalLogHistory={showModalLogHistory} handleCloseLogHistory={handleCloseLogHistory} currentRawData={formLogHistoryData} />} */}
            </div>
        </>
    );
}

export default PersonalDetails;