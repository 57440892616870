import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Stage, Layer, Line } from 'react-konva';
import { PiEraserFill } from "react-icons/pi";
import { MdDone } from "react-icons/md";
import { Image } from 'react-konva'; 
// import useImage from 'use-image';
import URLImage from './URLImage';



const MyKonvaComponent = ({ imageUrl, height, width, stageRef, id, name, index, handleSave }) => {
    const [lines, setLines] = useState([]);
    const [loading, setLoading] = useState(true);
    const isDrawing = useRef(false);

    const handleMouseDown = useCallback((e) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setLines((prevLines) => [...prevLines, { points: [pos.x, pos.y] }]);
    }, []);

    const handleMouseMove = useCallback((e) => {
        if (!isDrawing.current) return;

        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        setLines((prevLines) => {
            const lastLine = prevLines[prevLines.length - 1];
            lastLine.points = lastLine.points.concat([point.x, point.y]);
            const newLines = prevLines.slice(0, prevLines.length - 1);
            return [...newLines, lastLine];
        });
    }, []);

    const handleMouseUp = useCallback(() => {
        isDrawing.current = false;
    }, []);

    const handleClear = (e) => {
        e.preventDefault();
        setLines([]);
    };

    const handleImageLoad = () => {
        setLoading(false);
    };

    const handleImageError = () => {
        
        setLoading(false); // You may want to handle errors differently
    };

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <div className='p-2 m-2 border bg-success rounded' onClick={handleClear}>
                    <PiEraserFill size={24} color='white' />
                </div>
                <div className='p-2 m-2 border bg-success rounded' onClick={(e) => handleSave(e, index)}>
                    <MdDone size={24} color='white' />
                </div>
            </div>
            {loading && <p>Loading image...</p>}
            <div>
                <Stage
                    width={width}
                    height={height}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    ref={stageRef}
                    id={id}
                    name={name}
                    index={index}
                >
                    <Layer>
                        <URLImage src={imageUrl} onLoad={handleImageLoad} onError={handleImageError} />
                        {!loading && lines.map((line, i) => (
                            <Line
                                key={i}
                                points={line.points}
                                stroke="red"
                                strokeWidth={2}
                                tension={0.5}
                                lineCap="round"
                                globalCompositeOperation="source-over"
                            />
                        ))}
                    </Layer>
                </Stage>
            </div>
        </div>
    );
};

export default MyKonvaComponent;
