import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie'
import config from "../../config/config";
function ProtectedRoute({ children }) {

    let loginData = (!Cookies.get('loginSuccessNeurulaHealth')) ? [] : JSON.parse(Cookies.get('loginSuccessNeurulaHealth'));
    loginData = loginData? loginData: null;
    if (!loginData || loginData.length == 0) {
        return <Navigate to={`${config.baseUrl}`} replace />
    }
    return children;
}
export default ProtectedRoute;
