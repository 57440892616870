import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { deleteAppointmentsById, getAppointmentList, getAppointmentsByDocId, getDoctorList, updateAppointmentsById } from '../../../Action/admin.action';
import { toast } from 'react-toastify';
import ConfirmDialog from './ConfirmDialog';

const AppointmentDetailModal = ({ show, setShow, eventDetails, setScheduleAppointmentList }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [doctorData, setDoctorData] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [appointmentError, setAppointmentError] = useState('');
  const validationSchema = Yup.object({
    staffId: Yup.number().required('Doctor name is required'),
    date: Yup.date().required('Date is required'),
    time: Yup.string().required('Time is required'),
    description: Yup.string().required('Description is required'),
  });

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const formatTime = (date) => {
    const d = new Date(date);
    let hours = '' + d.getHours();
    let minutes = '' + d.getMinutes();

    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    return [hours, minutes].join(':');
  };

  const handleUpdateAppointment = async (values) => {
    let res = await updateAppointmentsById(values);
    if (res.success) {
      setShow(!show);
      setShowEdit(false)
      toast.success(res.msg);
      getAppointment()
    } else {
      toast.error(res.msg);
    }
  }


  const getAppointment = async () => {
    let res = await getAppointmentList();
    if (res.success) {
      setScheduleAppointmentList(res.data);
    }
  };

  const formik = useFormik({
    initialValues: {
      staffId: eventDetails.extendedProps?.appointmentData?.staffId,
      date: formatDate(eventDetails.start || '2024-06-14'),
      time: formatTime(eventDetails.start || '12:00'),
      description: eventDetails.extendedProps?.appointmentData?.description
    },
    validationSchema,
    onSubmit: (values) => {
      let appointment = eventDetails.extendedProps?.appointmentData
      values.id = appointment.id

      const conflict = doctorData.some(appointment =>
        appointment.date === values.date && appointment.time === values.time
      );

      if (conflict) {
        setAppointmentError('The selected date and time are already booked for this doctor.');
      } else {
        handleUpdateAppointment(values);
        setAppointmentError('');
      }
    },
  });


  useEffect(() => {
    getDoctorListData();
  }, []);

  // Git doctor list
  let getDoctorListData = async () => {
    let res = await getDoctorList();
    // setIsLoading(false);
    if (res.success) {
      setDoctorList(res.data);
    }
  };


  useEffect(() => {
    if (formik.values.staffId) {
      getAppointmentByDocId();
    }
  }, [formik.values.staffId]);

  const getAppointmentByDocId = async () => {
    let obj = {
      doctorId: formik.values.staffId,
    };

    let res = await getAppointmentsByDocId(obj);
    if (res.success) {
      setDoctorData(res.data);
    }
  };

  const handleOpenEdit = () => {
    setShowEdit(true)
  }

  const handleCloseDelete = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const handleDeleteAppointment = async() => {
    let appointment = eventDetails.extendedProps?.appointmentData
    let obj = {
      id: appointment.id,
      staffId: appointment.staffId
    }
    let res = await deleteAppointmentsById(obj);
    if (res.success) {
      setShow(!show);
      setShowEdit(false)
      toast.success(res.msg);
      getAppointment()
    } else {
      toast.error(res.msg);
    }
  }

  const handleOpenDelete = async() => {
  
    setShowDeleteModal(!showDeleteModal)
  }


  return (
    <>
      <Modal show={show} onHide={() => setShow(!show)}>
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between w-100">
            <Modal.Title>{showEdit ? 'Update Appointment' : 'Schedule Details'}</Modal.Title>
            <div className="d-flex modal-header-btn">
              <button className="btn btn-link p-0 mx-2" onClick={handleOpenDelete}>
                <RiDeleteBin6Line size={20} />
              </button>
              <button className="btn btn-link p-0 mx-2" onClick={handleOpenEdit}>
                <FaEdit size={20} />
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {!showEdit && <> <p><strong>Doctor Name:</strong> {eventDetails.extendedProps?.doctorName}</p>
            <p><strong>Date and Time:</strong> {eventDetails.start?.toLocaleString()}</p>
            <p><strong>Description:</strong> {eventDetails.extendedProps?.appointmentData?.description}</p></>}
          {showEdit && <>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group controlId="formDoctorName">
                <Form.Label>Doctor Name</Form.Label>
                <Form.Control
                  as="select"
                  name="staffId"
                  value={formik.values.staffId}
                  onChange={(e) => {
                    formik.setFieldValue('staffId', parseInt(e.target.value, 10));
                  }}
                  isInvalid={!!formik.errors.staffId && formik.touched.staffId}
                >
                  <option value="">Select a doctor</option>
                  {doctorList.map((doctor, index) => (
                    <option key={index} value={parseInt(doctor.id)}>
                      {doctor.firstName + ' ' + doctor.lastName}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.staffId}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.date && formik.touched.date}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.date}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formTime">
                <Form.Label>Time</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="time"
                    name="time"
                    value={formik.values.time}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.time && formik.touched.time}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.time}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.description && formik.touched.description}
                  placeholder="Enter a description"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              {appointmentError && <p className="text-danger">{appointmentError}</p>}
            </Form>
          </>}
        </Modal.Body>
        {showEdit && <Modal.Footer>
          <Button className='btn-sm btn-secondary-btn3' type="submit" onClick={formik.handleSubmit}>
            Update Appointment
          </Button>
        </Modal.Footer>}
      </Modal>
    {
      showDeleteModal && <ConfirmDialog  handleCloseDelete={handleCloseDelete} showDeleteModal={showDeleteModal} handleDeleteAppointment={handleDeleteAppointment}/>
    }
    </>
  );
};

export default AppointmentDetailModal;
