import React, { useState, useEffect } from 'react';
import config from '../../config/config';
import Container from 'react-bootstrap/Container';
import { Dropdown, Navbar, Nav, ListGroup, Badge } from 'react-bootstrap';
import '../componentCss/dashboardheader.css';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { getStaffDetails } from '../../redux/slices/userSlices';
import { MdNotificationsActive } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, updateNotifications } from '../../redux/slices/notificationSlice';
import { decryptData } from './decrypt';
import {
    getStaffDetailsAction
} from "../../Action/admin.action";

const Dashboardheader = () => {
    let loginData = (!Cookies.get('loginSuccessNeurulaHealth')) ? [] : JSON.parse(Cookies.get('loginSuccessNeurulaHealth'));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getStaffData = useSelector((state) => state.user?.getStaffDetailsData);
    const getNotificationData = useSelector((state) => state.notification?.getNotificationData);

    const logout = async () => {
        Cookies.remove("loginSuccessNeurulaHealth");
        window.location.href = `${config.baseUrl}`;
    };

    const [staffDetails, setStaffDetails] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0)

    // useEffect(() => {
    //     if (getStaffData && getStaffData.data) {
    //         setStaffDetails(getStaffData.data);
    //     } else {
    //         getStaffDetailsAPI();
    //     }
    // }, [getStaffData]);

    useEffect(() => {
        getStaffDetailsAPI();
    }, [loginData?.id]);

    useEffect(() => {
        if (getNotificationData && getNotificationData.data) {
            setNotifications(decryptData(getNotificationData.data));
            let readCount = (decryptData(getNotificationData.data)).filter(notification => notification.readStatus)
            setUnreadCount(readCount.length)
        } else {
            getNotificationsAPI();
        }
    }, [getNotificationData]);

    useEffect(() => {
        updateExpireTime();
        window.addEventListener("click", updateExpireTime);
        window.addEventListener("scroll", updateExpireTime);
        window.addEventListener("keypress", updateExpireTime);
        window.addEventListener("mousemove", updateExpireTime);

        return () => {
            window.removeEventListener("click", updateExpireTime);
            window.removeEventListener("scroll", updateExpireTime);
            window.removeEventListener("keypress", updateExpireTime);
            window.removeEventListener("mousemove", updateExpireTime);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const checkForInactivity = () => {
        const expireTime = localStorage.getItem("expireTime");
        if (expireTime < Date.now()) {
            logout();
        }
    };

    const updateExpireTime = () => {
        const expireTime = Date.now() + 15 * 60 * 1000;
        localStorage.setItem("expireTime", expireTime);
    };

    const getStaffDetailsAPI = async () => {
        let res = await getStaffDetailsAction({id : loginData?.id});
        if (res.success) {
            setStaffDetails(decryptData(res.data));
        }

        // const res = await dispatch(getStaffDetails({ id: loginData?.id }));
        // if (res.payload?.success) {
        //     setStaffDetails(res.payload.data);
        // }
    };

    const getNotificationsAPI = async () => {
        const res = await dispatch(getNotifications());
        if (res.payload?.success) {
            let data = decryptData(res.payload.data)
            setNotifications(data);
            let readCount = (data).filter(notification => notification.readStatus)
            setUnreadCount(readCount.length)
        }
    };

    const handleNotificationClick = () => {
        setShowNotifications(!showNotifications);
    };

    const handleReadNotification = async (id) => {
        let obj = {
            id,
            readStatus: 0
        }
        await dispatch(updateNotifications(obj))
        navigate(`${config.baseUrl}calendar`)
        getNotificationsAPI();
    }

    const handleSeeAll = () => {
        navigate(`${config.baseUrl}notifications`)
    }

    return (
        <>
            <header className='dashboardheader'>
                <Navbar expand="lg">
                    <Container fluid className='px-5'>
                        <Navbar.Brand href={`${config.baseUrl}`}>
                            <span><img src='assets/images/logo2.png' className='mainlogo' alt='logo2' /></span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="mx-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                            </Nav>
                            <Dropdown align="end" show={showNotifications} onToggle={setShowNotifications}>
                                <Dropdown.Toggle as="div" onClick={handleNotificationClick}>
                                    <MdNotificationsActive size={35} />
                                    {unreadCount !== 0 && (
                                        <Badge pill bg="danger" className="notification-badge">
                                            {unreadCount}
                                        </Badge>
                                    )}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="notification-dropdown-menu">
                                    <ListGroup.Item className='notification-heading'>Notifications</ListGroup.Item>
                                    <ListGroup>
                                        {notifications.length > 0 ? (
                                            notifications.map((notification, index) => (
                                                <ListGroup.Item key={index} className="notification-item" onClick={() => handleReadNotification(notification.id)}>
                                                    <div className="notification-title">{notification.title}</div>
                                                    <div className="notification-description">{notification.description}</div>
                                                    <div className="notification-time">{new Date(notification.dateTime).toLocaleString()}</div>
                                                </ListGroup.Item>
                                            ))
                                        ) : (
                                            <ListGroup.Item>No notifications</ListGroup.Item>
                                        )}
                                    </ListGroup>
                                    <div className="notification-footer">
                                        <span className="see-all-link" onClick={handleSeeAll}>See All</span>
                                    </div>
                                </Dropdown.Menu>

                            </Dropdown>
                            <div className='admindropdoen'>
                                <Dropdown className='flagdrop'>
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className='adminbtn'>
                                        <span><img src='assets/icons/person-outline.svg' alt='avatarImg' className='avataricon me-2' /></span> <span>{`${staffDetails?.firstName ? staffDetails?.firstName : ''} ${staffDetails?.lastName ? staffDetails?.lastName : ''}`}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item>
                                            <Link to={`${config.baseUrl}profile`} className='header-links'>
                                                <span>My Profile</span>
                                            </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <Link to={`${config.baseUrl}changePassword`} className='header-links'>
                                                <span>Change Password</span>
                                            </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={logout}>
                                            <span>Logout</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );
};

export default Dashboardheader;
