import * as Yup from 'yup';

export const CPTMasterSchema = Yup.object().shape({
  code: Yup.string().required('Code is required.'),
  shortDescription: Yup.string().required('Short description required.'),
  longDescription: Yup.string().required('Long description required.'),
  fullDescription: Yup.string().required('Full description required.')
});

export const HPCSMasterSchema = Yup.object().shape({
  code: Yup.string().required('Code is required.'),
  shortDescription: Yup.string().required('Short description required.'),
  longDescription: Yup.string().required('Long description required.')
});

export const ICDMasterSchema = Yup.object().shape({
  code: Yup.string().required('Code is required.'),
  shortDescription: Yup.string().required('Short description required.'),
  longDescription: Yup.string().required('Long description required.')
});

export const DrugMasterSchema = Yup.object().shape({
  drugCode: Yup.string().required('Code is required.'),
  greenrainCode: Yup.string().required('Greenrain code required.'),
  insurancePlan: Yup.string().required('Insurance plan required.'),
  packageName: Yup.string().required('Package name is required.'),
  genericCode: Yup.string().required('Generic code required.'),
  genericName: Yup.string().required('Generic name required.'),
  strength: Yup.string().required('Strength is required.'),
  dosageForm: Yup.string().required('Dosage form required.'),
  packageSize: Yup.string().required('Package size required.'),
  dispenseMode: Yup.string().required('Dispense mode is required.'),   
  packagePricePublic: Yup.string().required('Package price public is required.'),
  packagePricePharmacy: Yup.string().required('Package price pharmacy is required.'),
  unitPricePublic: Yup.string().required('Unit price public is required.'),
  unitPricePharmacy: Yup.string().required('Unit price pharmacy is required.'),
  agentName: Yup.string().required('Agent name is required.'),
  manufacturerName: Yup.string().required('Manufacturer name is required.')
});