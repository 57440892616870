import React, { useState, useEffect, useRef } from 'react';
import '../componentCss/webform.css';
import '../componentCss/sidebar.css';
import '../componentCss/userGroup.css';
import { getUserGroupListAction } from '../../Action/admin.action';
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";import { Link } from 'react-router-dom';
import config from '../../config/config';
import DataTable from 'react-data-table-component';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { MdEdit } from 'react-icons/md';
import {decryptData} from './decrypt'
import { IoPerson, IoPrintSharp } from "react-icons/io5";
import { Container, Row, Col, Modal, Form } from "react-bootstrap";

const StaffManagement = () => {
    const [userGroupList, setUserGroupList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedRow, setSelectedRow] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [formList, setFormList] = useState([]);

    const allDataRef = useRef();
    const singleRowRef = useRef();
    const [searchTerm, setSearchTerm] = useState('');

    const filteredFormList = formList.filter((data) =>
      data.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handlePageChange = (page) => {
        setCurrentPage(page - 1);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage)
    };

        useEffect(() => {
        getUserGroupListAPI();
    }, []);

    useEffect(() => {
        setSelectedRow(null);
    }, [currentPage]);

    const getUserGroupListAPI = async () => {
        let res = await getUserGroupListAction();
        setIsLoading(false);
        if (res.success) {
            setUserGroupList(decryptData(res.data));
        }
    };

    
    const columns = [
        {
            name: '#',
            cell: (row, index, column, id) => {
                return index + 1 + currentPage * rowsPerPage;
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width:"20%"
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width:"45%"
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable: true,
            width:"10%"
        },
        {
            name: 'Status',
            selector: row => (
                row.status === 1 ?
                    <p className='text-primary mb-0'>
                        Active
                    </p>
                    :
                    <span className='text-danger'>
                        Inactive
                    </span>
            ),
            sortable: true,
            width:"10%"
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <Link to={`${config.baseUrl}edit-user-group/${row.id}`} title='Edit'>
                        <span ><MdEdit fill='#434544' size={25} /></span>
                    </Link>
                    
                    {/* &nbsp;
                    &nbsp;
                    <span title='Print' style={{ cursor: 'pointer' }} onClick={() => handlePrint(row)}> <IoPrintSharp fill='#434544' size={25}/></span> */}
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width:"20%"

        }
    ];

    const printColumns = [
        {
            name: '#',
            cell: (row, index) => index + 1,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status == 1 ? 'Active' : 'Inactive',
            sortable: true,
        }
    ];

    
    const printSingleRow = useReactToPrint({
        content: () => singleRowRef.current,
    });

    const printAllData = useReactToPrint({
        content: () => allDataRef.current,
    });

    useEffect(() => {
        if (selectedRow) {
            printSingleRow();
        }
    }, [selectedRow, printSingleRow]);


    return (
        <>
            {/* <Dashboardheader /> */}
            {/* <ToastContainer /> */}
            {/* <div className="wrapper">
                <Sidebar /> */}
                {/* <div id="content"> */}
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Roles Management</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-3">
                                <div style={{ float: 'right' }}>
                                    <Link to={`${config.baseUrl}add-user-group`}  title='Add Data'>
                                        <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Add</button>
                                    </Link>
                                </div>
                                <ReactToPrint
                                    trigger={() => <button  title='Print' className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Print</button>}
                                    content={printAllData}
                                />
                                <div className='maintable table-responsive'>
                                    <h5 className=''>Roles List</h5>
                                    {isLoading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: '40px' }} src='assets/images/loading.gif' alt='loading' />
                                        </div>
                                        :
                                        <DataTable
                                            columns={columns}
                                            data={userGroupList}
                                            pagination
                                            paginationPerPage={rowsPerPage}
                                            paginationRowsPerPageOptions={[10, 15, 25, 50, 100]}
                                            paginationComponentOptions={{
                                                rowsPerPageText: 'Row per page:',
                                                rangeSeparatorText: 'out of',
                                            }}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                        />
                                    }
                                </div>

                                {/*------------------------------- Section For Showing Single Print--------------------------------------------------- */}
                                <div style={{ display: 'none' }}>
                                    <div className="container mt-5" ref={singleRowRef}>
                                        {selectedRow && (
                                            <div className="card shadow-sm">
                                                <div className="card-body">
                                                    <h2 className="card-title text-center mb-4">Role Details</h2>
                                                    <div className="list-group">
                                                        <div className="list-group-item">
                                                            <strong>ID:</strong> {selectedRow.id}
                                                        </div>
                                                        <div className="list-group-item">
                                                            <strong>Name:</strong> {selectedRow.name}
                                                        </div>
                                                        <div className="list-group-item">
                                                            <strong>Description:</strong> {selectedRow.description}
                                                        </div>
                                                        <div className="list-group-item">
                                                            <strong>Code:</strong> {selectedRow.code}
                                                        </div>
                                                        <div className="list-group-item">
                                                            <strong>Status:</strong> {selectedRow.status == 1 ? 'Active' : 'Inactive'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/*------------------------------- Section For Showing All Data For Print--------------------------------------------------- */}

                                <div style={{ display: 'none' }}>
                                    <div ref={allDataRef}>
                                        <img src="assets/images/logo.png" alt="logo" className='mainLogo' />
                                        <center>
                                            <h2 className=''> Group List</h2>
                                        </center>
                                        <DataTable
                                            columns={printColumns}
                                            data={userGroupList}
                                            pagination={false}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>


                  

                {/* </div> */}
            {/* </div> */}
        </>
    );
}

export default StaffManagement;