import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { commonService } from "../../services/common.services";
import { apiConstants } from "../../constants/api.constant";
import toast from 'react-hot-toast';

// Get Notification Details 
export const getNotifications = createAsyncThunk('user/getNotifications', async (data) => { 
  const response = await commonService.getWithToken(apiConstants.GET_NOTIFICATIONS ,data); 
  if(response && response.data && response.data.success){
    
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
  return response.data; 
});

// update Notification Details 

export const updateNotifications = createAsyncThunk('user/updateNotifications', async (data) => {
 
  const response = await commonService.putWithToken(apiConstants.UPDATE_NOTIFICATION ,data); 
  if(response && response.data && response.data.success){
    
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
 
  return response.data; 
});

// Delete Notification
// update Notification Details 

export const deleteNotifications = createAsyncThunk('user/deleteNotifications', async (data) => {
 
  const response = await commonService.withToken(apiConstants.DELETE_NOTIFICATION ,data); 
  if(response && response.data && response.data.success){
    toast.success(
      response.data.msg
    )
    
  }else{
    toast.error(
      response.data.msg, {
        position: "top-right",
      }
    )
  }
 
  return response.data; 
});

const notificationSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    error: null,
    getNotificationData: null,
    updateNotification: null,
    deleteNotificationData: null
  },
  reducers: {
    changeValue: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.getNotificationData = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.updateNotification = action.payload;
      })
      .addCase(updateNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteNotificationData = action.payload;
      })
      .addCase(deleteNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});


export const { changeValue } = notificationSlice.actions;

export default notificationSlice.reducer 