import PropTypes from 'prop-types';
import Dashboardheader from '../dashboardheader.jsx';
import Sidebar from '../sidebar.jsx';

const AdminLayout = ({ children }) => {
    return (
        <>
            <Dashboardheader />
            <div className="wrapper">
                <Sidebar />
                <div id='content'>
                {children}

                </div>
            </div>
           
        </>
    );
};

AdminLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AdminLayout;
