const config = {
    baseUrl: '/',
    serverPath: (process.env.REACT_APP_API_URL || ''),
    environment: (process.env.REACT_APP_ENV || ''),
    // serverPath: 'http://localhost:9875/neuApi/authadmin',
    apiKey: 'AIzaSyD69p85S19SvvRBVzR2DQPKtc-J8p4HIwk',
    authDomain: 'neurula-test.firebaseapp.com',
    projectId: 'neurula-test',
    storageBucket: 'neurula-test.appspot.com',
    messagingSenderId: '798985631460',
    appId: '1:798985631460:web:a6184c0d31b50a4942def1',
    measurementId: 'G-F1144FN3XZ'

};

export default config;