import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import '../componentCss/webform.css';
import '../componentCss/sidebar.css';
import 'react-toastify/dist/ReactToastify.css';
import { getDashboardStatisticsAction, getFormDataListAction, getUpcomingEventAction } from '../../Action/admin.action';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../config/config';
import { decryptData } from './decrypt';
import propertise, { reportsFileds } from '../../config/properties';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';

const Dashboard = () => {
    const navigate = useNavigate()
    const [statistics, setStatistics] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [userRole, setUserRole] = useState(0);
    const [daysSinceLastUpdate, setDaysSinceLastUpdate] = useState(0);
    const [formFields, setFormFields] = useState([]);
    const [formList, setFormData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterStatus, setFilterStatus] = useState(false);
    const [searchTerms, setSearchTerms] = useState({
        FirstName: '',
        LastName: '',
        MrnNumber: '',
        MobileNumber: '',
        EmiratesIdNumber: '',
        PassportNumber: '',
        dropdownValue: ''
    });

    useEffect(() => {
        getFormDataListAPI();
        getDashboardStatisticsAPI();
    }, []);


    let getFormDataListAPI = async () => {
        let res = await getFormDataListAction({ id: propertise.patientAdmit });

        setIsLoading(false);
        if (res.success) {
            let decryptedData = decryptData(res.data?.fields);
            // Remove the element at index 2
            if (Array.isArray(decryptedData) && decryptedData.length > 2) {
                decryptedData.splice(2, 1);  // Remove the 3rd item (index 2)
            }
            setFormFields(decryptedData);
            setFormData(decryptData(res.data?.records));
            setTotalRows(res.data.totalRows);
            setFilteredData(decryptData(res.data?.records));
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchTerms({
            ...searchTerms,
            [name]: value,
        });
    };

    const handleFilter = () => {
        let tempFormList = [...formList]
        const filteredData = tempFormList.filter(entry => {
            return (!searchTerms.FirstName || entry[propertise.PatientFirstName]?.trim().toLowerCase().includes(searchTerms.FirstName.trim().toLowerCase())) &&
                (!searchTerms.LastName || entry[propertise.PatientLastName]?.trim().toLowerCase().includes(searchTerms.LastName.trim().toLowerCase())) &&
                (!searchTerms.MrnNumber || entry[propertise.PatientMrnNumber]?.trim().toLowerCase().includes(searchTerms.MrnNumber.trim().toLowerCase())) &&
                (!searchTerms.MobileNumber || entry[propertise.PatientMobileNumber]?.trim().toLowerCase().includes(searchTerms.MobileNumber.trim().toLowerCase())) &&
                (!searchTerms.EmiratesIdNumber || entry[propertise.EMRID]?.trim().toLowerCase().includes(searchTerms.EmiratesIdNumber.trim().toLowerCase())) &&
                (!searchTerms.PassportNumber || entry[propertise.PatientPasportNumber]?.trim().toLowerCase().includes(searchTerms.PassportNumber.trim().toLowerCase())) &&
                (!searchTerms.dropdownValue || entry[propertise.PatientSatus]?.trim().toLowerCase().includes(searchTerms.dropdownValue.trim().toLowerCase()));
        });
        if (filteredData.length) {
            setFilterStatus(true)
        } else {
            toast.error('Data not found')
        }
        setFilteredData(filteredData);
    }

    const handleReset = () => {
        setFilterStatus(false)
        setSearchTerms({
            FirstName: '',
            LastName: '',
            MrnNumber: '',
            MobileNumber: '',
            EmiratesIdNumber: '',
            PassportNumber: '',
            dropdownValue: ''
        })
    }

    // function filterFields(array, fields) {
    //     const values = Object.values(fields).map(String);
    //     return array.filter(item => values.includes(String(item.id)));
    // }

    // const filteredHeadinArray = filterFields(formFields, reportsFileds);

    const getDashboardStatisticsAPI = async () => {
        let res = await getDashboardStatisticsAction();
        if (res.success) {
            let data = decryptData(res.data);
            setStatistics(data.statistics);
            setDaysSinceLastUpdate(data.daysSinceLastUpdate);
            setUserRole(data.userRole)
            if (parseInt(data.userRole) > 2) {
                getUpcomingEventAPI()
            }
        }
    };

    const getUpcomingEventAPI = async () => {
        let res = await getUpcomingEventAction();
        if (res.success) {
            setUpcomingEvents(decryptData(res.data));
        }
    };

    const columns = [
        {
            name: '#',
            cell: (row, index, column, id) => {
                return (index + 1) + (currentPage * rowsPerPage);
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "EMR Number",
            selector: (row) => `${row.emrNumber}`,
            sortable: true,
        },
        {
            name: "MRN Number",
            selector: (row) => `${row.mrnNumber}`,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => `${row[16]} ${row[18]}`,
            sortable: true,
        },

        {
            name: "Mobile Number",
            selector: (row) => `${row[30]}`,
            sortable: true,
        },
        {
            name: "DOB",
            selector: (row) => `${row[20]}`,
            sortable: true,
        },
    ];

    const handleRowClick = (e) => {
        navigate(`${config.baseUrl}viewRecord/${propertise.patientAdmit}/${e.recordId}/Patient Record Details`)
    }
    return (
        <div id="content">
            <Container fluid className="p-4">
                <Row>
                    <Col>
                        <div className="innerhead mb-4">
                            <h5 className="headtitle">Dashboard</h5>
                        </div>
                    </Col>
                </Row>

                <Row className="text-center mb-4">
                    {['totalStaffs', 'totalPatients', 'totalForms', 'totalUserGroups'].map((key, index) => (
                        <Col key={index} md={3} sm={6}>
                            <Card className="statistic-card">
                                <Card.Body>
                                    <Card.Title>{key.replace('total', 'Total ')}</Card.Title>
                                    <Card.Text className="count">{statistics[key] || 0}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                {parseInt(daysSinceLastUpdate) >= 83 && (
                    <Row className="mb-4">
                        <Col>
                            <Card className="password-update-message">
                                <Card.Body>
                                    <strong>Notice:</strong> It's been {daysSinceLastUpdate || 0} days since you last updated your password. For your security, we recommend updating your password.
                                    <Link to={`${config.baseUrl}changePassword`}>
                                        <Button className="btn-primary mt-2">Update Password</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className={userRole > 2 ? 'border p-3 m-2 col-md-8' : 'border p-3 m-2 col'} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <Row>
                            <Col>
                                <div className="innerhead mb-4">
                                    <h5 className="headtitle">Search Patient</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {['FirstName', 'LastName', 'MrnNumber', 'MobileNumber', 'EmiratesIdNumber', 'PassportNumber'].map((field, index) => (
                                <Col key={index} md={4} sm={6} className="mb-3">
                                    <Form.Group className="fieldbox h-auto px-2">
                                        <Form.Label>{field.replace(/([A-Z])/g, ' $1')}</Form.Label>
                                        <Form.Control
                                            type={field === 'mobileNumber' ? 'number' : 'text'}
                                            placeholder={`Search By ${field.replace(/([A-Z])/g, ' $1')}`}
                                            className="form-control"
                                            name={field}
                                            value={searchTerms[field]}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            ))}
                            <Col md={12} sm={12} className="mb-3">
                                <Form.Group className="fieldbox h-auto px-2">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        className="form-select"
                                        value={searchTerms.dropdownValue}
                                        onChange={handleChange}
                                        name="dropdownValue"
                                        aria-label="Default select example"
                                    >
                                        <option value="" disabled>Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="2">Discharge</option>
                                        <option value="3">Deceased</option>
                                        <option value="4">Pre-admit</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex">
                                <div className="">
                                    <Button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4" onClick={() => handleFilter()}>Search</Button>
                                </div>
                                &nbsp;
                                <div >
                                    <Button className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6 mt-4" onClick={() => handleReset()}>Reset</Button>
                                </div>
                            </Col>

                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <div className='dashboardTbl'>
                                    <DataTable
                                        columns={columns}
                                        data={filteredData}
                                        pagination
                                        // onRowClicked={handleRowClick}
                                        customStyles={{
                                            headCells: {
                                                style: {
                                                    backgroundColor: '#f5f5f5',
                                                    color: '#333',
                                                    fontWeight: 'bold',
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Dashboard;
