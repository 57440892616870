import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import { getpatientFormDetailsById } from '../../Action/admin.action';
import config from '../../config/config';
import { decryptData } from './decrypt';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tabs, Tab } from 'react-bootstrap';

const PatientData = () => {
    let { id, patientId } = useParams();
    const [patientFormsData, setPatientFormsData] = useState([]);
    const [patientReportsData, setPatientReportsData] = useState([]);

    useEffect(() => {
        getPatientDataByID();
    }, []);

    const getPatientDataByID = async () => {
        let res = await getpatientFormDetailsById({ 'id': patientId });
        if (res.success) {
            let data = decryptData(res.data)
            let patientForms = data.filter(item => item.formCategory == 1)
            let patientReports = data.filter(item => item.formCategory == 2)
            console.log('pppp,,,,,', patientForms, patientReports);
            setPatientFormsData(patientForms);
            setPatientReportsData(patientReports)
        }
    };

    return (
        <>
            <Dashboardheader />
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <h4>Patient Treatment Data</h4>
                                <hr />

                                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                    <Tab eventKey="home" title="Forms">
                                        <div className="p-3">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>S.no</th>
                                                            <th>Form Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {patientFormsData.length > 0 ?
                                                            patientFormsData?.map((data, index) => (
                                                                // data.formId != 1 ?
                                                                    <tr key={data.id}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{data?.formName}</td>
                                                                        <td>
                                                                            <Link to={`${config.baseUrl}viewRecord/${data?.formId}/${id}/${data?.formName}`}>
                                                                                <Button variant="primary">View</Button>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                    // : (patientFormsData.length == 1 && data.formId == 1) &&
                                                                    // <tr>
                                                                    //     <td style={{ textAlign: 'center' }} colSpan={3}>No data found</td>
                                                                    // </tr>
                                                            ))
                                                            : <>
                                                                <tr>
                                                                    <td style={{ textAlign: 'center' }} colSpan={3}>No data found</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="profile" title="Reports">
                                        <div className="p-3">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>S.no</th>
                                                            <th>Form Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {patientReportsData.length > 0 ?
                                                            patientReportsData?.map((data, index) => (
                                                                <tr key={data.id}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{data?.formName}</td>
                                                                    <td>
                                                                        <Link to={`${config.baseUrl}personal-details/${patientId}/${data?.formId}/${data?.formName}`}>
                                                                            <Button variant="primary">View</Button>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            : <>
                                                                <tr>
                                                                    <td style={{ textAlign: 'center' }} colSpan={3}>No data found</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default PatientData;
