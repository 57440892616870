import axios from 'axios';
import React, { useState } from 'react';

const CardReaderComponent = () => {
  const [cardData, setCardData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const handleReadCardData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`http://localhost:9875/authadmin/readCardData`);
      setCardData(response.data);
    } catch (error) {
      console.error('Error reading card data:', error);
      setError('Error reading card data. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div>
      <button onClick={handleReadCardData} disabled={loading}>
        {loading ? 'Reading...' : 'Read Card Data'}
      </button>
      {error && <p>{error}</p>}
      {cardData && (
        <div>
          <h2>Card Data</h2>
          <pre>{JSON.stringify(cardData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default CardReaderComponent;
