import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';


const ConfirmDialog = ({ showDeleteModal, handleCloseDelete, handleDeleteAppointment }) => {
  return (
    <>
      <Modal show={showDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you really want to delete appointment?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDeleteAppointment}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmDialog;
