import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";
import '../componentCss/webform.css';
import { Link, useParams, useNavigate } from "react-router-dom";
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
import { getFormDetailsAction, insertFormDataAction, getFormDataListAction } from '../../Action/admin.action';
// import { ToastContainer, toast } from 'react-toastify';
import toast from 'react-hot-toast';

import 'react-toastify/dist/ReactToastify.css';
import { decryptData } from './decrypt';
const Treatments = () => {
    let { treatmentFormId, formId, referenceRecordId } = useParams();
    const navigate = useNavigate();
    const [formDetails, setFormDetails] = useState([])
    const [formValues, setFormValues] = useState({});
    const [formFields, setFormFields] = useState([])
    const [formList, setFormData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingFormSubmit, setIsLoadingFormSubmit] = useState(false)

    useEffect(() => {
        getFormDetailsAPI();
        getFormDataListAPI()
    }, []);

    let getFormDetailsAPI = async () => {
        let res = await getFormDetailsAction({ 'id': treatmentFormId });
        if (res.success) {
            setFormDetails(res.data);
        }
    }

    let getFormDataListAPI = async () => {
        let res = await getFormDataListAction({ 'id': treatmentFormId, 'referenceRecordId': referenceRecordId });
        setIsLoading(false)
        if (res.success) {
            setFormFields(decryptData(res.data?.fields));
            setFormData(decryptData(res.data?.records));
        }
    }

    const handleChange = (e) => {
        const { name, value, type, checked, id } = e.target;
        // Adjust the value based on the input type
        let newValue = "";
        if (type === 'checkbox') {
            newValue = checked
        } else if (type === 'file') {
            newValue = e.target.files[0];
        } else {
            newValue = value
        }

        setFormValues({ ...formValues, [name]: newValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        formValues.formId = treatmentFormId
        formValues.referenceFormId = formId
        formValues.referenceRecordId = referenceRecordId
        setIsLoadingFormSubmit(true)
        let res = await insertFormDataAction(formValues);
        if (res.success) {
            toast.success(res.msg);
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        } else {
            setIsLoadingFormSubmit(false)
            toast.error(res.msg);
        }
    };

    const clearData = async () => {
        window.location.reload();
    }

    return (
        <>
            <Dashboardheader />
            {/* <ToastContainer /> */}
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">

                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <h5 >{formDetails.length > 0 ? formDetails[0].formName : ''} List</h5>
                                {isLoading ?
                                    <div style={{ textAlign: 'center' }}>
                                        <img style={{ width: '40px' }} src='assets/images/loading.gif' />
                                    </div>
                                    : formList.length > 0 ?
                                        <div className='maintable table-responsive'>
                                            <Table hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        {formFields.map((filed, i) => (
                                                            <th>{filed.fieldName}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {formList.map((data, i) => (
                                                        <>
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                {formFields.map(filed => (
                                                                    <td>{data[filed.id]}</td>
                                                                ))}
                                                            </tr>
                                                        </>
                                                    ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        :
                                        <h6 className='text-center'>No data found!!</h6>
                                }
                            </Col>
                        </Row>

                        <hr />

                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className='formDiv'>
                                    <h5 >Add {formDetails.length > 0 ? formDetails[0].formName : ''}</h5>
                                    <>
                                        <Form>
                                            <Row className="mb-4">
                                                {formDetails.length > 0 ?
                                                    formDetails.map(item => (
                                                        <div className='col-md-6 mt-4'>
                                                            <Form.Group as={Col} controlId="formGridEmail">
                                                                <Form.Label>{item.fieldtype != 'btn' ? item.fieldName : ''}</Form.Label> <br />

                                                                {item.fieldtype == 'select' ?
                                                                    <select className='form-control' id={item.fieldName} name={item.id} onChange={handleChange}>
                                                                        <option value={0}>Select Department</option>
                                                                        {item.dropdownValue != null ?
                                                                            JSON.parse(item.dropdownValue).map(itemDropdown => (
                                                                                <>
                                                                                    <option value={itemDropdown.fieldValue}>{itemDropdown.fieldValue}</option>
                                                                                </>
                                                                            )) : ""}
                                                                    </select>
                                                                    :

                                                                    item.fieldtype == 'radio' || item.fieldtype == 'checkbox' ?
                                                                        JSON.parse(item.radioCheckboxValue).map(radioData => (
                                                                            <>
                                                                                <input onChange={handleChange} value={radioData} type={item.fieldtype} name={item.id} id={item.fieldName} /> {radioData} <br />
                                                                            </>
                                                                        ))
                                                                        :
                                                                        item.fieldtype == 'selectManual' ?
                                                                            <>
                                                                                <select className='form-control' name={item.id} onChange={handleChange}>
                                                                                    <option>-Select-</option>
                                                                                    {JSON.parse(item.radioCheckboxValue).map(radioData => (
                                                                                        <>
                                                                                            <option value={radioData}>
                                                                                                {radioData}
                                                                                            </option>
                                                                                        </>
                                                                                    ))}
                                                                                </select>
                                                                            </>
                                                                            :

                                                                            item.fieldtype == 'btn' ?
                                                                                ""
                                                                                :
                                                                                <Form.Control onChange={handleChange} required={item.isMandatory ? true : false} type={item.fieldtype} name={item.id} placeholder={`Enter ${item.fieldName}`} id={item.fieldName} value={formValues[item.id]} />
                                                                }
                                                            </Form.Group>
                                                        </div>
                                                    ))
                                                    : ""
                                                }
                                            </Row>

                                            {isLoadingFormSubmit ?
                                                <button disabled className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 text-white' type="submit">Loading</button>
                                                :
                                                <button onClick={handleSubmit} className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 text-white' type="submit">Submit</button>
                                            }
                                            &nbsp;
                                            <button onClick={clearData} className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 text-white' type="button">Clear</button>
                                        </Form>
                                    </>

                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div >

        </>
    )
}

export default Treatments;
