import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import { Formik, Field } from "formik";
import { useNavigate } from "react-router-dom";
import Dashboardheader from "./dashboardheader";
import Sidebar from "./sidebar";
import "../componentCss/sidebar.css";
// import { ToastContainer, toast } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import config from "../../config/config";
import { Link } from "react-router-dom";
import { addStaffSchema } from "../../validations/staffSchema";
import { addStaffAction, getGroupTypesAction } from "../../Action/admin.action";
import { decryptData } from "./decrypt";
import Select from 'react-select';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { BsEyeSlashFill,BsEyeFill} from "react-icons/bs";

const AddStaff = () => {
  const navigate = useNavigate();
  const [formData, setForm] = useState({
    namePrefix: "Mr",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    address: "",
    city: "",
    state: "",
    gender: 0,
    userRoleId: [],
    designation: "",
    isTimeSlot: "",
    employeeId: "",
    emiratesIdNumber: "",
    DateOfJoining: "",
    licenceNumber: "",
    stampImage: null,
    photo: null,
    signature: null,
    prefix: ""
  });
  const [userGroup, setUserGroup] = useState([]);
  const [isLaoding, setisLaoding] = useState(0);
  const [image_preview, setimage_preview] = useState("");
  const [imagePhotoPreview, setPhotopreview] = useState("");
  const [imageSignaturepreview, setSignaturepreview] = useState("");
  const [passwordData,setPasswordData] = useState(1)
  const [confirmPasswordData,setConfirmPasswordData] = useState(1)
  useEffect(() => {
    getGroupTypesAPI();
  }, []);

  let getGroupTypesAPI = async (values) => {
    let res = await getGroupTypesAction(values);
    if (res.success) {
      let data = decryptData(res.data)
      const transformedData = data.map(item => ({
        value: item.id,
        label: item.name
      }));
      setUserGroup(transformedData);
    }
  };

  const passWordDisplay = async(e,data)=>{
    e.preventDefault()
    if(data == 1){
      setPasswordData(2)
    }
    else{
      setPasswordData(1)

    }
  }

  const confirmPassWordDisplay = async(e,data)=>{
    e.preventDefault()
    if(data == 1){
      setConfirmPasswordData(2)
    }
    else{
      setConfirmPasswordData(1)

    }
  }

  let handleSubmit = async (values) => {

    values.userRolesIds = JSON.stringify(values.userRoleId)
    let res = await addStaffAction(values);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseUrl}staff-management`);
      }, 2000);
    } else {
      setisLaoding(0);
      toast.error(res.msg);
    }
  };

  const handleChangeForstampImage = async (e, setFieldValue) => {
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setFieldValue("stampImage", image_as_files);
    setimage_preview(image_as_base64);
  };

  const handleChangeForPhoto = async (e, setFieldValue) => {
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setFieldValue("photo", image_as_files);
    setPhotopreview(image_as_base64);
  };

  const handleChangeForSignature = async (e, setFieldValue) => {
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setFieldValue("signature", image_as_files);
    setSignaturepreview(image_as_base64);
  };




  return (
    <>
      <Dashboardheader />
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Container fluid className="p-4">
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle">Add New User</h5>
                </div>
                <Link to={`${config.baseUrl}staff-management`}>
                  <span style={{ float: "right" }}>
                    Back <BsArrowLeftCircleFill />
                  </span>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} className="">
                <div className="formDiv">
                  <Formik
                    initialValues={formData}
                    validationSchema={addStaffSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({
                      errors,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      touched,
                      setFieldValue,
                      values,
                    }) => (
                      <Form onSubmit={handleSubmit}>


                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Prefix</Form.Label>
                            <select name="namePrefix" onChange={handleChange} className="form-control">
                              <option value="Mr"> Mr </option>
                              <option value="Ms"> Ms </option>
                              <option value="Miss"> Miss </option>
                              <option value="Mrs"> Mrs </option>
                              <option value="Master"> Master </option>
                              <option value="Fr"> Father (Fr) </option>
                              <option value="Rev"> Reverend (Rev) </option>
                              <option value="Dr"> Doctor (Dr) </option>
                              <option value="Atty"> Attorney (Atty) </option>
                              <option value="Hon"> Honorable (Hon) </option>
                              <option value="Prof"> Professor (Prof) </option>
                              <option value="Pres"> President (Pres) </option>
                              <option value="VP"> Vice President (VP) </option>
                              <option value="Gov"> Governor (Gov) </option>
                            </select>
                          </Form.Group>

                          <Form.Group as={Col}></Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>First name</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="firstName"
                              onChange={handleChange}
                              placeholder="Please enter first name"
                              onBlur={handleBlur}
                              value={values.firstName}
                            />

                            {errors.firstName && touched.firstName && (
                              <div className="text-danger">
                                {errors.firstName}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Middle name</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="middleName"
                              onChange={handleChange}
                              placeholder="Please enter Middle Name"
                              onBlur={handleBlur}
                              value={values.middleName}
                            />

                            {errors.middleName && touched.middleName && (
                              <div className="text-danger">
                                {errors.middleName}
                              </div>
                            )}
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Last name</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="lastName"
                              onChange={handleChange}
                              value={values.lastName}
                              placeholder="Please enter last name"
                            />

                            {errors.lastName && touched.lastName && (
                              <div className="text-danger">
                                {errors.lastName}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Employee ID</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="employeeId"
                              onChange={handleChange}
                              value={values.employeeId}
                              placeholder="Please enter Employee Id"
                            />

                            {errors.employeeId && touched.employeeId && (
                              <div className="text-danger">
                                {errors.employeeId}
                              </div>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Email</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              placeholder="Please enter email"
                            />

                            {errors.email && touched.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Mobile Number</Form.Label>
                            <input
                              className="form-control"
                              value={values.mobileNumber}
                              type="text"
                              name="mobileNumber"
                              onChange={handleChange}
                              placeholder="Ex. 91584758"
                            />

                            {errors.mobileNumber && touched.mobileNumber && (
                              <div className="text-danger">
                                {errors.mobileNumber}
                              </div>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Password</Form.Label>
                            <input
                              className="form-control"
                              type={passwordData == 1 ?"password":"text"}
                              name="password"
                              onChange={handleChange}
                              value={values.password}
                              placeholder="Please enter password"
                            />
<div style={{position:"absolute",right:"43%",marginTop:"-33px"}}>
                              {passwordData == 1 ? 
                            <BsEyeSlashFill fill='#434544' size={20} onClick={(e)=>passWordDisplay(e,passwordData)}/>
                            :  
                            <BsEyeFill fill='#434544' size={20} onClick={(e)=>passWordDisplay(e,passwordData)} />
                            
                            }
                            </div>
                            {errors.password && touched.password && (
                              <div className="text-danger">
                                {errors.password}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Confirm Password</Form.Label>
                            <input
                              className="form-control"
                              type={confirmPasswordData == 1 ?"password":"text"}

                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              placeholder="Please enter confirm password"
                            />
<div style={{position:"absolute",right:"3%",marginTop:"-33px"}}>
                              {confirmPasswordData == 1 ? 
                            <BsEyeSlashFill fill='#434544' size={20} onClick={(e)=>confirmPassWordDisplay(e,confirmPasswordData)}/>
                            :  
                            <BsEyeFill fill='#434544' size={20} onClick={(e)=>confirmPassWordDisplay(e,confirmPasswordData)} />
                            
                            }
                            </div>
                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <div className="text-danger">
                                  {errors.confirmPassword}
                                </div>
                              )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>City</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="city"
                              onChange={handleChange}
                              value={values.city}
                              placeholder="Please enter city"
                            />

                            {errors.city && touched.city && (
                              <div className="text-danger">{errors.city}</div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Address</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="address"
                              onChange={handleChange}
                              value={values.address}
                              placeholder="Please enter address"
                            />

                            {errors.address && touched.address && (
                              <div className="text-danger">
                                {errors.address}
                              </div>
                            )}
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>State</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="state"
                              onChange={handleChange}
                              value={values.state}
                              placeholder="Please enter state"
                            />

                            {errors.state && touched.state && (
                              <div className="text-danger">{errors.state}</div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Emirates ID number</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="emiratesIdNumber"
                              onChange={handleChange}
                              value={values.emiratesIdNumber}
                              placeholder="Please enter emiratesIdNumber"
                            />

                            {errors.emiratesIdNumber &&
                              touched.emiratesIdNumber && (
                                <div className="text-danger">
                                  {errors.emiratesIdNumber}
                                </div>
                              )}
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Gender</Form.Label>
                            <select
                              name="gender"
                              value={values.gender}
                              className="form-control"
                              onChange={handleChange}
                            >
                              <option value={""}>Select gender</option>
                              <option value={1}>Male</option>
                              <option value={2}>Female</option>
                              <option value={3}>Other</option>
                            </select>

                            {errors.gender && touched.gender && (
                              <div className="text-danger">{errors.gender}</div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Designation</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="designation"
                              onChange={handleChange}
                              value={values.designation}
                              placeholder="Please enter designation"
                            />

                            {errors.designation && touched.designation && (
                              <div className="text-danger">
                                {errors.designation}
                              </div>
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Role</Form.Label>
                            <Field
                              name="userRoleId"
                              component={MultiSelectField}
                              options={userGroup}
                            />
                            {errors.userRoleId && touched.userRoleId && (
                              <div className="text-danger">
                                {errors.userRoleId}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Stamp Image</Form.Label>
                            <input
                              className="form-control"
                              type="file"
                              name="stampImage"
                              accept="image/*"
                              onChange={(e) =>
                                handleChangeForstampImage(e, setFieldValue)
                              }
                            />

                            {errors.stampImage && touched.stampImage && (
                              <div className="text-danger">
                                {errors.stampImage}
                              </div>
                            )}

                            {image_preview ? (
                              <>
                                <img
                                  src={image_preview}
                                  width="100px"
                                  height="100px"
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </Row>
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Photo</Form.Label>
                            <input
                              className="form-control"
                              type="file"
                              name="photo"
                              accept="image/*"
                              onChange={(e) => handleChangeForPhoto(e, setFieldValue)}
                            />

                            {errors.photo && touched.photo && (
                              <div className="text-danger">{errors.photo}</div>
                            )}

                            {imagePhotoPreview ? (
                              <>
                                <img
                                  src={imagePhotoPreview}
                                  width="100px"
                                  height="100px"
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Signature</Form.Label>
                            <input
                              className="form-control"
                              type="file"
                              name="signature"
                              accept="image/*"
                              onChange={(e) => handleChangeForSignature(e, setFieldValue)}
                            />

                            {errors.signature && touched.signature && (
                              <div className="text-danger">
                                {errors.signature}
                              </div>
                            )}

                            {imageSignaturepreview ? (
                              <>
                                <img
                                  src={imageSignaturepreview}
                                  width="100px"
                                  height="100px"
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </Row>

                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            <Form.Label>Date of joining</Form.Label>
                            <input
                              className="form-control"
                              type="date"
                              name="DateOfJoining"
                              onChange={handleChange}
                              value={values.DateOfJoining}
                              placeholder="Please enter Date of Joining"
                            />

                            {errors.DateOfJoining && touched.DateOfJoining && (
                              <div className="text-danger">
                                {errors.DateOfJoining}
                              </div>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>License Number</Form.Label>
                            <input
                              className="form-control"
                              type="text"
                              name="licenceNumber"
                              onChange={handleChange}
                              placeholder="Please enter Licence Number "
                              value={values.licenceNumber}
                            />

                            {errors.licenceNumber && touched.licenceNumber && (
                              <div className="text-danger">
                                {errors.licenceNumber}
                              </div>
                            )}
                          </Form.Group>
                        </Row>

                        <br />
                        <Row className="mb-4">
                          <Form.Group as={Col}>
                            {isLaoding ? (
                              <>
                                <button
                                  disabled
                                  className="btn-sm btn btn-primary"
                                >
                                  Loading{" "}
                                  <img
                                    height="20"
                                    width="20px"
                                    src="assets/images/loading.gif"
                                  />
                                </button>
                              </>
                            ) : (
                              <button
                                type="submit"
                                className="btn-sm btn btn-primary"
                              >
                                Submit
                              </button>
                            )}
                          </Form.Group>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

// Custom Select Component for Formik
const MultiSelectField = ({ options, field, form }) => {
  const onChange = (selectedOptions) => {
    form.setFieldValue(field.name, selectedOptions);
  };

  return (
    <Select
      isMulti
      name={field.name}
      value={field.value}
      options={options}
      onChange={onChange}
      onBlur={() => form.setFieldTouched(field.name, true)}
    />
  );
};

export default AddStaff;
