import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import '../componentCss/webform.css';
import '../componentCss/sidebar.css';
import { getDepartmentFormsListAction } from '../../Action/admin.action';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import DataTable from 'react-data-table-component';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { MdEdit } from 'react-icons/md';
import {decryptData} from './decrypt'

const DepartmentFormsList = () => {
    const [departmentList, setDepartmentList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const allDataRef = useRef();

    const handlePageChange = page => {
        setCurrentPage(page - 1);
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        console.log(newRowsPerPage)

        setRowsPerPage(newRowsPerPage)
      };

    useEffect(() => {
        getDepartmentList();
    }, []);

    let getDepartmentList = async () => {
        let res = await getDepartmentFormsListAction();
        setIsLoading(false)
        if (res.success) {
            setDepartmentList(decryptData(res.data));
        }
    }

    const columns = [
        {
            name: '#',
            cell: (row, index, column, id) => {
                return index + 1 + currentPage * rowsPerPage;
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            
        },
        {
            name: 'Form Name',
            selector: row => `${row.name}`,
            sortable: true,
            width:"45%"
        },
        {
            name: 'Department',
            selector: row => `${row.departmentName}`,
            sortable: true,
            width:"20%"
        },
        {
            name: 'Status',
            selector: row => (
                row.status == 1 ?
                    <p className='text-primary mb-0'>
                        Active
                    </p>
                    :
                    <span className='text-danger'>
                        Inactive
                    </span>
            ),
            sortable: true,
            width:"20%"
        },
        {
            name: 'Action',
            selector: row => (
                <>
                    <Link to={`${config.baseUrl}edit-department-forms/${row.id}`} >
                    <span ><MdEdit fill='#434544' title='Edit' size={25} /></span>
                    </Link>
                </>
            ),  
            sortable: true,
            width:"20%"
        }
    ];
    const printColumns = [
        {
            name: '#',
            cell: (row, index, column, id) => {
                return index + 1    ;
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Form Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            name: 'Department',
            selector: row => `${row.departmentName}`,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                row.status == 1 ?
                    <p className='text-primary'>
                        Active
                    </p>
                    :
                    <span className='text-danger'>
                        Inactive
                    </span>
            ),
            sortable: true,
        },
    ];
    const printAllData = useReactToPrint({
        content: () => allDataRef.current,
    });
    return (
        <>
            {/* <Dashboardheader /> */}
            {/* <ToastContainer /> */}
            {/* <div className="wrapper"> */}
                {/* <Sidebar /> */}
                {/* <div id="content"> */}
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Folder</h5>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-3">
                                <div style={{ float: 'right' }}>
                                    <Link to={`${config.baseUrl}add-department-form`} >
                                        <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Add+</button>
                                    </Link>
                                </div>
                                <ReactToPrint
                                    trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Print</button>}
                                    content={printAllData}
                                />
                                <div className='maintable table-responsive' ref={allDataRef}>
                                    <h5 className=''>Folder </h5>
                                    {isLoading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: '40px' }} src='assets/images/loading.gif' />
                                        </div>
                                        :
                                        <DataTable
                                            columns={columns}
                                            data={departmentList}
                                            pagination
                                            paginationPerPage={rowsPerPage}
                                            paginationRowsPerPageOptions={[10, 15, 25, 50,100]}
                                            paginationComponentOptions={{
                                              rowsPerPageText: 'Row per page:',
                                              rangeSeparatorText: 'out of',
                                            }}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                        />
                                    }
                                </div>
                                {/*------------------------------- Section For Showing All Data For Print--------------------------------------------------- */}

                                <div style={{ display: 'none' }}>
                                    <div ref={allDataRef}>
                                        <img src="assets/images/logo.png" alt="logo" className='mainLogo' />
                                        <center>
                                            <h2 className=''> Folder</h2>
                                        </center>
                                        <DataTable
                                            columns={printColumns}
                                            data={departmentList}
                                            pagination={false}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                {/* </div> */}
            {/* </div > */}
        </>
    )
}

export default DepartmentFormsList;
