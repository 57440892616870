import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';


const firebaseConfig = {
  apiKey: "AIzaSyD69p85S19SvvRBVzR2DQPKtc-J8p4HIwk",
  authDomain: "neurula-test.firebaseapp.com",
  projectId: "neurula-test",
  storageBucket: "neurula-test.appspot.com",
  messagingSenderId: "798985631460",
  appId: "1:798985631460:web:a6184c0d31b50a4942def1",
  measurementId: "G-F1144FN3XZ"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration(`${process.env.PUBLIC_URL}/firebase-push-notification-scope`)
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`, {
          scope: `${process.env.PUBLIC_URL}/firebase-push-notification-scope`,
        });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) =>
      getToken(messaging, { vapidKey: 'BE5jZjjKqNYdZEeXra3_WxqpRdaaBME0E5qdPZ-wixxQk2gsRrsrZqACO-LRaRDIGJCbbtiBYEdJWj-g6cu7Y2Q', serviceWorkerRegistration }));

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));