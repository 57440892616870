import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import "../componentCss/webform.css";
import Dashboardheader from "./dashboardheader";
import { useParams } from "react-router-dom";
import Sidebar from "./sidebar";
import Swal from "sweetalert2";
import { capitalizeFirstWord } from "../../config/conversion"
import "../componentCss/sidebar.css";
import {
  getMasterlistDetailByNameAction,
  addMasterDataDetailsAction,
  importExcelFileAction,
  deleteTableDataAction
} from "../../Action/admin.action";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import config from "../../config/config";
import { Table } from "antd";
// import { ToastContainer, toast } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';

import ReactToPrint, { useReactToPrint } from "react-to-print";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { decryptData } from "./decrypt";
const ViewMasterDataList = () => {
  let { id, name } = useParams();

  const [formList, setFormData] = useState([]);
  const [formList1, setFormData1] = useState();
  const [formFields, setFormFields] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddModel, setIsAddModel] = useState(false);
  const [columnName, setColumnName] = useState([]);
  const [formData, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(0);
  const [file, setFile] = useState(null);
  const [isLoadingImportBtn, setIsLoadingImportBtn] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null);
  const allDataRef = useRef();
  const singleRowRef = useRef();
  useEffect(() => {
    getFormDataListAPI();
  }, []);

  let getFormDataListAPI = async () => {
    setIsLoading(true);
    let res = await getMasterlistDetailByNameAction({ name: name });

    setIsLoading(false);
    if (res?.success) {
      const cols = [];
      let data = decryptData(res?.data)
      const firstObject = data?.getMasterlistDetailByName[0] || {};
      for (const key in firstObject) {
        const col = {
          title: capitalizeFirstWord(key),
          dataIndex: key,
        };
        cols.push(col);
      }
      { capitalizeFirstWord(name) }
      cols.push({
        title: "Action",
        key: "Action",
        render: (text, record) => (
          <>
            {" "}
            <span style={{cursor:'pointer'}} onClick={() => deleteForm({ id: record.id, tableName: name })}><MdDelete fill='#434544' title="Delete" size={20} /></span>
            <Link to={`${config.baseUrl}edit-table/${record.id}/${name}`}><span ><MdEdit fill='#434544' title="Edit" size={20} /></span></Link>
            {/* <span style={{cursor:'pointer'}} onClick={() => handlePrint(record)}> <IoPrintSharp fill='#434544'  title="Print " size={20} /></span> */}
          </>
        ),
      });
      let keys = data?.column?.map((item) => item.column_name);

      setColumnName(keys);
      setFormFields(cols);
      setFormData(data?.getMasterlistDetailByName);
      setTotalRows(data?.getMasterlistDetailByName?.length);
    } else {
      setIsLoading(false);
    }
  };

  const handleCloseAddModel = () => {
    setIsAddModel(false);
    setForm({});
  };
  const handleOpenModel = (type, data = null) => {
    // Type 0-add, 1-edit
    setIsEdit(type);
    if (type == 1) {
      setForm(data);
    }
    setIsAddModel(true);
  };
  let handleSubmit = async (e) => {
    e.preventDefault();
    formList1.tableName = name;
    let res = await addMasterDataDetailsAction(formList1);
    if (res.success) {
      toast.success(res.msg);
      setIsAddModel(false);
      getFormDataListAPI();
      setForm({});
    } else {
      toast.error(res.msg);
      setIsAddModel(false);
      setForm({});
    }
    return;
  };

  let handleChange = async (e) => {
    const { name, value } = e.target;

    setFormData1((old) => {
      return { ...old, [name]: value };
    });
  };

  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select excel file');
      return;
    }
    setIsLoadingImportBtn(true)
    let res = await importExcelFileAction({ 'dbTable': name, 'excelFile': file });
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setIsLoadingImportBtn(false)
      toast.error(res.msg);
    }
  };


  const deleteForm = async (id, tableName) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this data permanently.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes delete it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteTableDataAction(id, tableName);
        if (res.success) {
          getFormDataListAPI()
          toast.success(res.msg);
        } else {
          toast.error(res.msg);
        }
      }
    });
  }
  // --------------------------------print section code ---------------------

  const handlePrint = (row) => {
    setSelectedRow(row);
  };

  const printSingleRow = useReactToPrint({
    content: () => singleRowRef.current,
  });

  const printAllData = useReactToPrint({
    content: () => allDataRef.current,
  });

  useEffect(() => {
    if (selectedRow) {
      printSingleRow();
    }
  }, [selectedRow, printSingleRow]);
  return (
    <>
      {/* <Dashboardheader /> */}
      {/* <Toaster /> */}
      {/* <div className="wrapper"> */}
        {/* <Sidebar /> */}
        {/* <div id="content"> */}
          <Container fluid className="p-4">
            <Row>
              <Col lg={10} md={10} sm={10} className="">
                <div className="innerhead mb-4">
                  <h5 className="headtitle"> {capitalizeFirstWord(name)}</h5>
                </div>
              </Col>
              <Col lg={2} md={2} sm={2} className="pull-right">
                <button
                  className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                  onClick={() => handleOpenModel(0, "")}
                >
                  Add+
                </button>
              </Col>
            </Row>

            <div>
              <label>Import Excel Sheet</label> <br />
              <input type="file" onChange={handleFileChange} />
              {isLoadingImportBtn ?
                <>
                  <button disabled className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Loading <img height="20" width="20px" src="assets/images/loading.gif" /></button>
                </>
                :
                <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6' onClick={handleUpload}>Upload</button>
              }
              <ReactToPrint
                trigger={() => <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6 no-print'>Print</button>}
                content={printAllData}
              />
            </div>

            <Row>
              <Col lg={12} md={12} sm={12} className="mt-3">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      style={{ width: "40px" }}
                      src="assets/images/loading.gif"
                    />
                  </div>
                ) : (
                  <div className="maintable table-responsive" >
                    <>
                      <Table
                        responsive
                        columns={formFields}
                        dataSource={formList}
                        Pagination={true}
                      >
                      </Table>
                    </>
                  </div>
                )}
                {/*------------------------------- Section For Showing all Print--------------------------------------------------- */}
                <div style={{ display: 'none' }}>
                  <div className="maintable table-responsive" ref={allDataRef}>
                    <>
                      <Table
                        responsive
                        columns={formFields}
                        dataSource={formList}
                      // Pagination={false}
                      >
                      </Table>
                    </>
                  </div>
                </div>
                {/*------------------------------- Section For Showing Single Print--------------------------------------------------- */}
                <div style={{ display: 'none' }}>
                  <div className="container mt-5" ref={singleRowRef}>
                    {selectedRow && (
                      <div className="card shadow-sm">
                        <div className="card-body">
                          <h2 className="card-title text-center mb-4">{capitalizeFirstWord(name)} Details</h2>
                          <div className="list-group">
                            <div className="list-group-item">
                              <strong>ID:</strong> {selectedRow.id}
                            </div>
                            <div className="list-group-item">
                              <strong>short Description:</strong> {selectedRow.shortDescription}
                            </div>
                            <div className="list-group-item">
                              <strong>long Description:</strong> {selectedRow.longDescription}
                            </div>
                            <div className="list-group-item">
                              <strong>Code:</strong> {selectedRow.code}
                            </div>
                            <div className="list-group-item">
                              <strong>Status:</strong> {selectedRow.status == 1 ? 'Active' : 'Inactive'}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Modal
              show={isAddModel}
              onHide={handleCloseAddModel}
              size="lg"
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>{isEdit == 1 ? "Edit" : "Add"}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="pb-1">
                <Row className="border-bottom border-grey pb-2 pt-3">
                  <Col lg={1} className=""></Col>
                  <Col lg={10} className="">
                    <div className="formDiv">
                      <Form>
                        <Form.Group as={Col}>
                          <Row className="mb-2">
                            {columnName.slice(1).map((item) => {
                              return (
                                <>
                                  <div className="col-md-6">
                                    {" "}
                                    <Form.Label>{capitalizeFirstWord(item)}</Form.Label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name={item}
                                      onChange={(e) => handleChange(e)}
                                      placeholder={`Please enter ` + item}
                                    // value={item}
                                    />{" "}
                                  </div>
                                </>
                              );
                            })}
                            <Col lg={2} md={2} sm={2}>
                              <br />
                              <br />
                              <button
                                className="px-3 py-2 btn-sm btn-secondary-btn3 fs-6"
                                onClick={(e) => handleSubmit(e)}
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>

                          <Row className="mb-2"></Row>
                        </Form.Group>
                      </Form>
                    </div>
                  </Col>
                  <Col lg={1} className=""></Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Container>
        {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default ViewMasterDataList;