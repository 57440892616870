import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Modal, Button } from "react-bootstrap";
import '../componentCss/webform.css';
import { Formik } from "formik";
import Dashboardheader from './dashboardheader';
import Sidebar from './sidebar';
import '../componentCss/sidebar.css';
import { getDrugMasterListAction, addHPCSMasterAction, deleteHPCSMasterDataAction } from '../../Action/admin.action';
// import { ToastContainer, toast } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import DataTable from 'react-data-table-component';
import { DrugMasterSchema } from "../../validations/masterDataSchema";
import Swal from "sweetalert2";

const DrugMaster = () => {
    const [drugMasterList, setDrugMasterList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [formData, setForm] = useState({
        drugCode: '',
        greenrainCode: '',
        insurancePlan: '',
        packageName : '',
        genericCode : '',
        genericName : '',
        strength : '',
        dosageForm : '',
        packageSize : '',
        dispenseMode : '',
        packagePricePublic : '',
        packagePricePharmacy : '',
        unitPricePublic : '',
        unitPricePharmacy : '',
        agentName : '',
        manufacturerName : ''
    })
    const [isAddModel, setIsAddModel] = useState(false);
    const [isEdit, setIsEdit] = useState(0);

    const handleCloseAddModel = () => {
        setIsAddModel(false);
        setForm({})
    }

    const handleOpenModel = (type, data = null) => { // Type 0-add, 1-edit
        setIsEdit(type)
        if (type == 1) {
            setForm(data)
        }
        setIsAddModel(true);
    }

    useEffect(() => {
        getDrugMasterListAPI();
    }, []);

    let getDrugMasterListAPI = async () => {
        let res = await getDrugMasterListAction();
        setIsLoading(false)
        if (res.success) {
            setDrugMasterList(res.data);
        }
    }

    let handleSubmit = async (values) => {
        setIsLoading(1)
        values.submitType = isEdit // 0-Add, 1-Edit
        let res = await addHPCSMasterAction(values);
        setIsLoading(0)
        if (res.success) {
            handleCloseAddModel();
            toast.success(res.msg);
            getDrugMasterListAPI()
        } else {
            toast.error(res.msg);
        }
    }

    const deleteForm = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this record.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes delete it",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await deleteHPCSMasterDataAction({ 'id': id });
                if (res.success) {
                    getDrugMasterListAPI()
                    toast.success(res.msg);
                } else {
                    toast.error(res.msg);
                }
            }
        });
    }

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1
        },
        {
            name: 'Code',
            selector: row => `${row.drugCode}`,
            sortable: true,
        },
        {
            name: 'Generic Code',
            selector: row => row.genericCode,
            sortable: true,
        },
        {
            name: 'Generic Name',
            selector: row => row.genericName,
            sortable: true,
        },
        {
            name: 'Manufacturer Name',
            selector: row => row.manufacturerName,
            sortable: true,
        },
        {
            name: 'packagePricePublic',
            selector: row => `${row.packagePricePublic}`,
            sortable: true,
        },
        {
            name: 'packagePricePharmacy',
            selector: row => `${row.packagePricePharmacy}`,
            sortable: true,
        },
        {
            name: 'Package Size',
            selector: row => `${row.packageSize}`,
            sortable: true,
        },
        // {
        //     name: 'Action',
        //     selector: row => (
        //         <>
        //             <Button onClick={() => deleteForm(row.id)} variant="secondary-btn3" className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Delete</Button>

        //             <Button onClick={() => handleOpenModel(1, row)} variant="secondary-btn3" className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Edit</Button>
        //         </>
        //     ),
        //     sortable: true,
        // }
    ];

    return (
        <>
            <Dashboardheader />
            {/* <ToastContainer /> */}
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <Container fluid className="p-4">
                        <Row>
                            <Col lg={12} md={12} sm={12} className="">
                                <div className="innerhead mb-4">
                                    <h5 className='headtitle'>Drug Master List</h5>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} className="mt-3">
                                <div style={{ float: 'right' }}>
                                    <button className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6' onClick={() => handleOpenModel(0, '')}>Add+</button>
                                </div>

                                <div className='maintable table-responsive'>
                                    {isLoading ?
                                        <div style={{ textAlign: 'center' }}>
                                            <img style={{ width: '40px' }} src='assets/images/loading.gif' />
                                        </div>
                                        :
                                        <DataTable
                                            columns={columns}
                                            data={drugMasterList}
                                            pagination
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>

                        <Modal show={isAddModel} onHide={handleCloseAddModel} size="lg" backdrop="static">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {isEdit == 1 ? 'Edit' : 'Add'}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pb-1">
                                <Row className="border-bottom border-grey pb-2 pt-3">
                                    <Col lg={1} className=""></Col>
                                    <Col lg={10} className="">
                                        <div className='formDiv'>
                                            <Formik
                                                // enableReinitialize={true}
                                                initialValues={formData}
                                                validationSchema={DrugMasterSchema}
                                                onSubmit={(values) => {
                                                    handleSubmit(values);
                                                }}
                                            >
                                                {({
                                                    errors,
                                                    handleSubmit,
                                                    handleBlur,
                                                    handleChange,
                                                    touched,
                                                    values,
                                                }) => (
                                                    <Form onSubmit={handleSubmit}>
                                                        <Form.Group as={Col}>

                                                            <Row className="mb-2">
                                                                <div className='col-md-6'>
                                                                    <Form.Label>Code</Form.Label>
                                                                    <input className='form-control' type="text" name='drugCode' onChange={handleChange} placeholder="Please enter drug code" onBlur={handleBlur} value={values.drugCode} />

                                                                    {errors.drugCode && touched.drugCode && (
                                                                        <div className="text-danger">
                                                                            {errors.drugCode}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Form.Label>Greenrain Code</Form.Label>
                                                                    <input className='form-control' type="text" name='greenrainCode' onChange={handleChange} placeholder="Please enter greenrain code" onBlur={handleBlur} value={values.greenrainCode} />

                                                                    {errors.greenrainCode && touched.greenrainCode && (
                                                                        <div className="text-danger">
                                                                            {errors.greenrainCode}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>

                                                            <Row className="mb-2">

                                                            </Row>

                                                            <Row className="mb-2">
                                                                <div className='col-md-6'>
                                                                    <Form.Label>Insurance Plan</Form.Label>
                                                                    <input className='form-control' type="text" name='insurancePlan' onChange={handleChange} placeholder="Please enter insurance plan" onBlur={handleBlur} value={values.insurancePlan} />

                                                                    {errors.insurancePlan && touched.insurancePlan && (
                                                                        <div className="text-danger">
                                                                            {errors.insurancePlan}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Form.Label>Package Name</Form.Label>
                                                                    <input className='form-control' type="text" name='packageName' onChange={handleChange} placeholder="Please enter package name" onBlur={handleBlur} value={values.packageName} />

                                                                    {errors.packageName && touched.packageName && (
                                                                        <div className="text-danger">
                                                                            {errors.packageName}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <div className='col-md-6'>
                                                                    <Form.Label>Generic Code</Form.Label>
                                                                    <input className='form-control' type="text" name='genericCode' onChange={handleChange} placeholder="Please enter generic code" onBlur={handleBlur} value={values.genericCode} />

                                                                    {errors.genericCode && touched.genericCode && (
                                                                        <div className="text-danger">
                                                                            {errors.genericCode}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Form.Label>Generic Name</Form.Label>
                                                                    <input className='form-control' type="text" name='genericName' onChange={handleChange} placeholder="Please enter generic name" onBlur={handleBlur} value={values.genericName} />

                                                                    {errors.genericName && touched.genericName && (
                                                                        <div className="text-danger">
                                                                            {errors.genericName}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <div className='col-md-6'>
                                                                    <Form.Label>Strength</Form.Label>
                                                                    <input className='form-control' type="text" name='strength' onChange={handleChange} placeholder="Please enter strength" onBlur={handleBlur} value={values.strength} />

                                                                    {errors.strength && touched.strength && (
                                                                        <div className="text-danger">
                                                                            {errors.strength}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Form.Label>Dosage Form</Form.Label>
                                                                    <input className='form-control' type="text" name='dosageForm' onChange={handleChange} placeholder="Please enter dosage form" onBlur={handleBlur} value={values.dosageForm} />

                                                                    {errors.dosageForm && touched.dosageForm && (
                                                                        <div className="text-danger">
                                                                            {errors.dosageForm}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <div className='col-md-6'>
                                                                    <Form.Label>Package Size</Form.Label>
                                                                    <input className='form-control' type="text" name='packageSize' onChange={handleChange} placeholder="Please enter package size" onBlur={handleBlur} value={values.packageSize} />

                                                                    {errors.packageSize && touched.packageSize && (
                                                                        <div className="text-danger">
                                                                            {errors.packageSize}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Form.Label>Dispense Mode</Form.Label>
                                                                    <input className='form-control' type="text" name='dispenseMode' onChange={handleChange} placeholder="Please enter dispense mode" onBlur={handleBlur} value={values.dispenseMode} />

                                                                    {errors.dispenseMode && touched.dispenseMode && (
                                                                        <div className="text-danger">
                                                                            {errors.dispenseMode}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <div className='col-md-6'>
                                                                    <Form.Label>Package Price Public</Form.Label>
                                                                    <input className='form-control' type="text" name='packagePricePublic' onChange={handleChange} placeholder="Please enter package price public" onBlur={handleBlur} value={values.packagePricePublic} />

                                                                    {errors.packagePricePublic && touched.packagePricePublic && (
                                                                        <div className="text-danger">
                                                                            {errors.packagePricePublic}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Form.Label>Package Price Pharmacy</Form.Label>
                                                                    <input className='form-control' type="text" name='packagePricePharmacy' onChange={handleChange} placeholder="Please enter package price pharmacy" onBlur={handleBlur} value={values.packagePricePharmacy} />

                                                                    {errors.packagePricePharmacy && touched.packagePricePharmacy && (
                                                                        <div className="text-danger">
                                                                            {errors.packagePricePharmacy}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <div className='col-md-6'>
                                                                    <Form.Label>Unit Price Public</Form.Label>
                                                                    <input className='form-control' type="text" name='unitPricePublic' onChange={handleChange} placeholder="Please enter unit price public" onBlur={handleBlur} value={values.unitPricePublic} />

                                                                    {errors.unitPricePublic && touched.unitPricePublic && (
                                                                        <div className="text-danger">
                                                                            {errors.unitPricePublic}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Form.Label>Unit Price Pharmacy</Form.Label>
                                                                    <input className='form-control' type="text" name='unitPricePharmacy' onChange={handleChange} placeholder="Please enter unit price pharmacy" onBlur={handleBlur} value={values.unitPricePharmacy} />

                                                                    {errors.unitPricePharmacy && touched.unitPricePharmacy && (
                                                                        <div className="text-danger">
                                                                            {errors.unitPricePharmacy}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>

                                                            <Row className="mb-2">
                                                                <div className='col-md-6'>
                                                                    <Form.Label>Agent Name</Form.Label>
                                                                    <input className='form-control' type="text" name='agentName' onChange={handleChange} placeholder="Please enter agent name" onBlur={handleBlur} value={values.agentName} />

                                                                    {errors.agentName && touched.agentName && (
                                                                        <div className="text-danger">
                                                                            {errors.agentName}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Form.Label>Manufacturer Name</Form.Label>
                                                                    <input className='form-control' type="text" name='manufacturerName' onChange={handleChange} placeholder="Please enter manufacturer name" onBlur={handleBlur} value={values.manufacturerName} />

                                                                    {errors.manufacturerName && touched.manufacturerName && (
                                                                        <div className="text-danger">
                                                                            {errors.manufacturerName}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Row>

                                                            <Row className="mb-2">
                                                                {isLoading ?
                                                                    <>
                                                                        <button disabled className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>Loading <img height="20" width="20px" src="assets/images/loading.gif" /></button>
                                                                    </>
                                                                    :
                                                                    <button type='submit' className='px-3 py-2 btn-sm btn-secondary-btn3 fs-6'>
                                                                        {isEdit == 1 ? 'Update' : 'Submit'}
                                                                    </button>
                                                                }
                                                            </Row>
                                                        </Form.Group>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Col>
                                    <Col lg={1} className=""></Col>
                                </Row>
                            </Modal.Body>
                        </Modal>

                    </Container>
                </div>
            </div >
        </>
    )
}

export default DrugMaster;
